import React from 'react'

const Card = ({ children }: { children: React.ReactNode }) => (
  <div
    className="w-full px-2.5 pt-4 rounded space-y-4"
    style={{
      background:
        'linear-gradient(180deg, #FFFFFF 13.15%, rgba(255, 255, 255, 0.448493) 42.71%, rgba(255, 255, 255, 0) 100%)',
    }}
  >
    {children}
  </div>
)

export default Card
