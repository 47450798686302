import { Dialog } from '@headlessui/react'
import React from 'react'
import * as Yup from 'yup'
import { XMarkIcon } from '@heroicons/react/24/outline'
import ReviewScore from './Score'
import { Field, Form, Formik } from 'formik'
import { useMutation } from '@apollo/client'
import { CREATE_REVIEW } from '../../../../../App/graphql/queries/review'
import client from '../../../../../common/ApolloClient'
import Walkthrough from '../../../../../App/models/Walkthrough'
import SkillSet from '../../../../../App/models/SkillSet'

type Props = {
  onClose: () => void
  object: Walkthrough | SkillSet
}

const ValidateSchema = Yup.object().shape({
  score: Yup.number().required('This field is required'),
  description: Yup.string().nullable().notRequired(),
})

const initialValues = { score: undefined, description: '' } as {
  score?: number
  description: string
}

const ReviewDialog: React.FC<Props> = ({ onClose, object }) => {
  const [createUserReview, { error }] = useMutation(CREATE_REVIEW, { client })

  const onSubmit = (values: any) => {
    createUserReview({
      variables: {
        ...values,
        source: 'institute_show_page',
        objectType: object.__typename.toLowerCase(),
        objectId: object.id,
      },
    }).then(onClose)
  }

  return (
    <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={onClose} open>
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-lg sm:w-full sm:p-6">
          <Formik
            initialValues={initialValues}
            validationSchema={ValidateSchema}
            validateOnMount={true}
            enableReinitialize={false}
            onSubmit={onSubmit}
          >
            <Form>
              <div>
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900 mb-5"
                  >
                    Help Us Improve
                  </Dialog.Title>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 text-center mb-4 mt-4">
                    How easy was it for you to build your content?
                  </label>
                  <Field required={true} name="score">
                    {({ field, meta }: any) => (
                      <>
                        <ReviewScore {...field} />
                        {meta.touched && meta.error && (
                          <p className="text-red-500 text-xs italic mt-2">{meta.error}</p>
                        )}
                      </>
                    )}
                  </Field>
                </div>

                <div>
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium text-gray-700 text-center mb-4 mt-6"
                  >
                    How can we improve the builder?
                  </label>
                  <Field required={false} name="description">
                    {({ field }: any) => (
                      <textarea
                        {...field}
                        rows={3}
                        className="max-w-lg shadow-sm block w-full focus:ring-flowmo-blue-500 sm:text-sm border border-gray-300 rounded-md"
                        placeholder="Your feedback here"
                      />
                    )}
                  </Field>
                </div>
              </div>

              <div className="mt-5 sm:mt-6 flex flex-row space-x-4">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-100 text-base font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-flowmo-blue-500 sm:text-sm"
                  onClick={onClose}
                >
                  Close
                </button>

                <button
                  type="submit"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-flowmo-blue-600 text-base font-medium text-white hover:bg-flowmo-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-flowmo-blue-500 sm:text-sm"
                >
                  Submit
                </button>
              </div>

              {error && (
                <p className="text-red-500 text-xs italic mt-6 text-center">
                  Oops.. Something went wrong
                </p>
              )}
            </Form>
          </Formik>
        </div>
      </div>
    </Dialog>
  )
}

export default ReviewDialog
