import React, { useState } from 'react'
import useEventListener from '../../../../../../Labs/hooks/useEventListener'
import IconButton, { Sizes } from '../../../../../../Embed/Header/IconButton'
import { ExitFullscreenIcon, FullscreenIcon } from '../../../../../../Embed/Header/Actions/icons'

interface Props {
  enableFullscreen: () => void
}

const FullscreenButton = ({ enableFullscreen }: Props) => {
  const [fullscreen, setFullscreen] = useState(!!document.fullscreenElement)

  useEventListener('fullscreenchange', () => {
    setFullscreen(!!document.fullscreenElement)
  })

  const onClick = () => {
    !document.fullscreenElement ? enableFullscreen() : document.exitFullscreen()
  }

  return (
    <IconButton size={Sizes.MEDIUM} onClick={onClick}>
      {fullscreen ? <ExitFullscreenIcon /> : <FullscreenIcon />}
    </IconButton>
  )
}

export default FullscreenButton
