import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import './Carousel/style.scss'

const AgentCard = ({ agent, index }: { agent: any; index: number }) => {
  return (
    <div className="bg-slate-50/40 shadow rounded-2xl sm:mx-16 md:mx-24 lg:mx-auto max-w-4xl">
      <div className="px-2 sm:px-5 sm:py-8 md:py10 lg:px-10">
        <h2
          className="inline-flex items-center rounded-full px-4 py-1 text-blue-600 ring-1 ring-inset ring-blue-600"
          id="author-title"
        >
          <span className="font-mono text-sm" aria-hidden="true">
            {'0' + index.toString()}
          </span>
          <span className="ml-3 h-3.5 w-px bg-blue-600/20"></span>
          <span className="ml-3 text-base font-medium tracking-tight">For {agent.actor}</span>
        </h2>
        <p className="mt-8 font-display text-3xl font-extrabold tracking-tight text-slate-900 sm:text-4xl">
          <span className="block text-blue-600">{agent.title} –</span> {agent.description}
        </p>
        <p className="mt-4 text-lg tracking-tight text-slate-700">{agent.longDescription}</p>
        <div className="mt-8">
          <div className="flex flex-col text-base font-medium tracking-tight">
            <div className="ml-4 text-blue-600">I can answer questions like:</div>
            <div className="">
              <ul className="mt-4 ml-8 list-disc list-inside text-slate-700">
                {agent.prompts.map((prompt: string, index: number) => (
                  <li key={index} className="mt-2">
                    {prompt}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Hero = () => {
  const agents: any = [
    {
      title: 'LookML Assistant',
      description: "I'm really good at writing LookML and finding bugs in your code.",
      actor: 'Developers',
      longDescription:
        'Pair program with a co-pilot that knows about the LookML for your company as well as all the best practices and patterns. By recording walkthroughs you can teach me how you like to setup your projects, and I can help find bugs in your code.',
      prompts: [
        'what are our guidelines for writing views',
        'how many different measures do we have defined',
        'do we use view_label anywhere',
        'take this table schema and generate a view based on our guidelines',
      ],
    },
    {
      title: 'Administration Expert',
      description: 'I know the best way to setup a Looker instance, let me setup yours.',
      longDescription:
        'Save time by automating a lot of the manual work of setting up a Looker instance. Not only can you institutionalize best practices, you can automate the checks with our workflows.',
      actor: 'Looker Admins',
      prompts: [
        'what beta features should I enable',
        'do i have timezone setup correctly in my instance',
        'give me a review of my database connections',
        'do I have any unused dashboards',
        'are users having poor performance',
      ],
    },
    {
      title: 'Onboarding Specialist',
      description: "I've been trained to onboard new users and help them get started.",
      longDescription:
        'Get new users up to speed quickly by having a co-pilot onboard them. I can help them get setup with their own personal dashboards, and teach them how to use Looker.',
      actor: 'Dashboard Viewers',
      prompts: [
        'what dashboards should I use if in marketing',
        'how do I create a dashboard',
        'where are the boards for my team',
        'what is a measure',
        'how do I do period over period on the attribution dashboard',
      ],
    },
    {
      title: 'Ad Spend Optimizer',
      description: 'I can help you dig into your paid campaigns.',
      longDescription:
        "I don't just answer questions about Looker. Since all your company data is in your instance, I can help reason about your business. I can help you dig into your paid campaigns, and help you optimize your spend.",
      actor: 'Marketing Analysts',
      prompts: [
        'what was our CPA last week',
        'what is our CAC for the last 3 months',
        'which channel is performing the best',
        'what is our contributing the most to our ROAS for the last 3 months',
      ],
    },
    {
      title: 'Board Meeting Assistant',
      description: 'I can answer high level questions about your business.',
      longDescription:
        "I can answer high level questions about your business. No need to actually login to your Looker instance. I'm like your dedicated analyst at your beck and call.",
      actor: 'C-Level Execs',
      prompts: [
        'what is our revenue for the last 3 months',
        'how are my projects doing',
        'have we been profitable for the last 3 months',
        'are we on track to hit our goals for the quarter',
      ],
    },
  ]

  const responsive = {
    largeDesktop: {
      breakpoint: { max: 5000, min: 1024 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 768, min: 640 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
    },
  }

  return (
    <div className="relative mb-20 px-6 pt-14 lg:px-8">
      <div className="pt-12 sm:pt-28 lg:pt-32">
        <div className="mx-auto max-w-2xl text-center mb-20">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            AI teammates <br /> <span className="text-gray-700">powered by your</span> <br />
            <span className="text-gray-900">Looker instance and data</span>
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Our mission is to make your life easier by pairing your with AI teammates that can help
            you do your job. Looker is already the nervous system of your company; off-load some of
            your work onto us.
          </p>
        </div>

        <div className="slider-container max-w-7xl m-auto relative">
          <Carousel
            responsive={responsive}
            showDots={true}
            infinite={true}
            centerMode={false}
            removeArrowOnDeviceType={['mobile']}
            autoPlay={true}
            renderDotsOutside={true}
            autoPlaySpeed={10000}
            dotListClass={'absolute !-top-10 flex justify-center pb-4'}
            pauseOnHover={true}
          >
            {agents.map((agent: any, index: number) => (
              <AgentCard agent={agent} key={index} index={index + 1} />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default Hero
