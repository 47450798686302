import React from 'react'
import Walkthrough from '../../../../../App/models/Walkthrough'
import PublicStepList from './PublicStepList'
import PublicWalkthroughHeader from './PublicWalkthroughHeader'
import TextContent from '../../../../../AI/components/Chat/TextContent'

interface Props {
  walkthrough: Walkthrough
  steps: any[]
}

const PublicViewer: React.FC<Props> = ({ walkthrough, steps }) => (
  <>
    <div className="w-full">
      <PublicWalkthroughHeader walkthrough={walkthrough} steps={steps} />
      <div className="mt-8 flex flex-col space-y-8 max-w-7xl mx-auto px-8">
        <div className="lg:block bg-white border rounded-md relative p-8">
          <h2 className="text-lg font-semibold text-gray-800">Summary</h2>
          {walkthrough.summary ? (
            <p className="text-gray-600 mt-2">
              <TextContent content={{ type: 'text', value: walkthrough.summary }} />
            </p>
          ) : (
            <p className="text-gray-600 mt-2">No summary generated yet. Check back later!</p>
          )}
        </div>

        <div className="lg:block bg-white border rounded-md relative p-8">
          <PublicStepList canEditWalkthrough={false} steps={steps} usePublicEndpoint={true} />
        </div>
      </div>
    </div>
  </>
)

export default PublicViewer
