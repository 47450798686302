import React from 'react'

const SupportIcon = () => (
  <svg width="97" height="30" viewBox="0 0 97 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_1171_3001)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.4165 11.8939C73.199 19.8921 84.1722 28.117 96.7691 30H0.768433C12.9565 28.2525 22.3467 20.5843 29.5944 11.8939C35.1125 5.27741 39.9429 0 48.5054 0C57.068 0 62.3688 4.91193 67.4165 11.8939Z"
        fill="#EDEFF1"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.9593 10.25L54.8129 9.3964C54.9067 9.30266 55.0338 9.25 55.1664 9.25C55.299 9.25 55.4261 9.30266 55.5199 9.3964L56.8534 10.7294C56.8998 10.7759 56.9367 10.831 56.9619 10.8917C56.987 10.9524 57 11.0175 57 11.0832C57 11.1489 56.987 11.2139 56.9619 11.2746C56.9367 11.3353 56.8998 11.3905 56.8534 11.4369L56 12.2902V20.25C56 21.3546 55.1046 22.25 54 22.25H47.9343L46.4161 24.5274C46.3233 24.6665 46.1672 24.7501 46.0001 24.7501C45.8329 24.7501 45.6768 24.6665 45.584 24.5274L44.0658 22.25H43C41.8954 22.25 41 21.3546 41 20.25V12.25C41 11.1454 41.8954 10.25 43 10.25H53.9593ZM52.9594 11.25H43C42.4477 11.25 42 11.6977 42 12.25V20.25C42 20.8023 42.4477 21.25 43 21.25H44.6009L46.0001 23.3487L47.3992 21.25H54C54.5523 21.25 55 20.8023 55 20.25V13.2901L53.1864 15.1035C53.0927 15.1973 52.9656 15.25 52.833 15.25H51.5C51.3674 15.25 51.2402 15.1973 51.1464 15.1036C51.0527 15.0098 51 14.8826 51 14.75V13.4165C51 13.2839 51.0527 13.1567 51.1465 13.063L52.9594 11.25ZM52 14.25H52.626L55.7929 11.0834L55.1664 10.4569L52 13.624V14.25ZM43 16.75C43 16.4739 43.2239 16.25 43.5 16.25H51.5C51.7761 16.25 52 16.4739 52 16.75C52 17.0261 51.7761 17.25 51.5 17.25H43.5C43.2239 17.25 43 17.0261 43 16.75ZM43.5 18.25C43.2239 18.25 43 18.4739 43 18.75C43 19.0261 43.2239 19.25 43.5 19.25H48.5C48.7761 19.25 49 19.0261 49 18.75C49 18.4739 48.7761 18.25 48.5 18.25H43.5ZM43 14.75C43 14.4739 43.2239 14.25 43.5 14.25H48.5C48.7761 14.25 49 14.4739 49 14.75C49 15.0261 48.7761 15.25 48.5 15.25H43.5C43.2239 15.25 43 15.0261 43 14.75Z"
      fill="currentColor"
    />
    <defs>
      <filter
        id="filter0_i_1171_3001"
        x="0.768433"
        y="0"
        width="96.0006"
        height="34"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="5.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.776597 0 0 0 0 0.820556 0 0 0 0 0.879167 0 0 0 0.5 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1171_3001" />
      </filter>
    </defs>
  </svg>
)

export default SupportIcon
