import Circles from '../Circles'
import React, { useEffect } from 'react'
import Beacon from '../Beacon'
import { applyWithoutAnimation } from '../animation/without-description'

interface Props {
  circleRef: any
  beaconRef: any
  styles: any
}

const PureHighlighter = ({ circleRef, beaconRef, styles }: Props) => {
  useEffect(() => {
    applyWithoutAnimation()
  }, [])

  return (
    <>
      <Beacon elementRef={beaconRef} styles={styles} />
      <Circles elementRef={circleRef} className="without-description" />
    </>
  )
}

export default PureHighlighter
