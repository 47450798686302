import React from 'react'
import Content from '../../../../components/Walkthrough/ui/Walkthrough/List/Content'
import { GET_ORGANIZATION_WALKTHROUGHS } from '../../../../components/App/graphql/queries/walkthrough'
import PageHeader from '../../components/PageHeader'

const OrganizationLibrary = () => {
  return (
    <React.Fragment>
      <PageHeader title="Organization Library" breadCrumbs={[{ title: 'Organization Library' }]} />
      <Content
        {...{
          emptyState: { description: 'No content has been shared with your organization' },
          query: GET_ORGANIZATION_WALKTHROUGHS,
        }}
      />
    </React.Fragment>
  )
}

export default OrganizationLibrary
