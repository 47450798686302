import React, { useCallback, useEffect } from 'react'
import useWidgetContext from '../../views/Walkthrough/modules/PlaygroundContext/hooks/useWidgetContext'
import { clsx } from 'clsx'
import useContentContext from '../../views/Walkthrough/modules/PlaygroundContext/hooks/useContentContext'
import CloseIcon from '../icons/CloseIcon'
import CheckMarkIcon from '../icons/CheckMarkIcon'

const CloseModal = () => {
  const { setShowCloseModal } = useWidgetContext()
  const { walkthrough } = useContentContext()

  const escFunction = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setShowCloseModal(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  return (
    <>
      <div
        className="w-screen h-[calc(100vh-56px)] bg-[rgba(0,0,0,0.3)] fixed top-[56px] left-0 z-[100]"
        onClick={() => setShowCloseModal(false)}
      />
      <div
        className={clsx(
          'fixed z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
          'z-[100] bg-[#F8F9FB] rounded py-[35px] px-[28px] max-w-[360px] border border-[#E4E7ED]'
        )}
      >
        <div className="text-[15px] text-[#646464]">
          Leave labs learning and return to the walkthrough?
        </div>

        <div className="w-full flex justify-between items-center mt-[20px]">
          <button
            className={clsx(
              'bg-[#BDC3CF] rounded w-[140px] flex items-center justify-center',
              'py-[7px] text-white text-[16px] hover:bg-[#adb5c5] transition-colors'
            )}
            onClick={() => setShowCloseModal(false)}
          >
            <span className="pl-5">Cancel</span>
            <CloseIcon />
          </button>
          <a href={`/walkthrough/${walkthrough.uuid}`}>
            <button
              className={clsx(
                'bg-[#1B72F2] rounded w-[140px] flex items-center justify-center',
                'py-[7px] text-white text-[16px] hover:bg-[#125dcb] transition-colors space-x-1.5'
              )}
            >
              <span className="pl-5">Yes</span>
              <CheckMarkIcon />
            </button>
          </a>
        </div>
      </div>
    </>
  )
}

export default CloseModal
