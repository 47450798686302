import React from 'react'
import useNavigationContext from '../../../../views/Walkthrough/modules/PlaygroundContext/hooks/useNavigationContext'
import Walkthrough from './Walkthrough'
import Result from './Result'

const Content: React.FC = () => {
  const { completed } = useNavigationContext()
  return completed ? <Result /> : <Walkthrough />
}

export default Content
