import React from 'react'
import Loader from './Loader'
import { useStepImage } from '../../../../../../../../../Walkthrough/ui/Walkthrough/Step/StepImage'
import useNavigationContext from '../../../../../../../../views/Walkthrough/modules/PlaygroundContext/hooks/useNavigationContext'
import ScreenshotCard from './ScreenshotCard'
import VideoCard from './VideoCard'

const Media = () => {
  const { imageData, elementDimensions, coordinates, screenSize, isIFrame } =
    useNavigationContext().step
  const { cloudUrl, exists, loading } = useStepImage(
    imageData?.url ? imageData.url : '',
    elementDimensions,
    coordinates,
    screenSize,
    isIFrame
  )

  return (
    <div className="flex justify-between items-center">
      {loading ? (
        <Loader />
      ) : (
        <>
          {exists && <ScreenshotCard url={cloudUrl} dimensions={elementDimensions} />}
          <VideoCard imageExists={exists} url={cloudUrl} dimensions={elementDimensions} />
        </>
      )}
    </div>
  )
}

export default Media
