import React from 'react'

const RightNavigationArrowIcon = () => (
  <div className="w-[24px] h-[24px] flex justify-center items-center">
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" fill="none" viewBox="0 0 6 10">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.396.146a.5.5 0 000 .708L4.543 5 .396 9.146a.5.5 0 10.708.708l4.5-4.5a.5.5 0 000-.708l-4.5-4.5a.5.5 0 00-.708 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  </div>
)

export default RightNavigationArrowIcon
