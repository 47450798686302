import SharingSection from './SharingSection'

import React, { ReactElement } from 'react'
import Walkthrough from '../../App/models/Walkthrough'
import SkillSet from '../../App/models/SkillSet'
import Button from '../../Walkthrough/ui/Button'

interface Props {
  object: Walkthrough | SkillSet
  objectType: 'walkthrough' | 'skillSet'
  cardMenu: ReactElement
  withEdit?: boolean
  toggleEmbedModal?: () => void
}

const ActionsSection: React.FC<Props> = ({ cardMenu, object, objectType, toggleEmbedModal }) => {
  return (
    <>
      <SharingSection object={object} objectType={objectType} />
      {object.canEdit && objectType === 'walkthrough' && (
        <Button
          onClick={toggleEmbedModal}
          icon={
            <svg
              width="17"
              height="10"
              viewBox="0 0 17 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.35355 0.853553C5.54882 0.658291 5.54882 0.341709 5.35355 0.146447C5.15829 -0.0488155 4.84171 -0.0488155 4.64645 0.146447L0.146447 4.64645C-0.0488155 4.84171 -0.0488155 5.15829 0.146447 5.35355L4.64645 9.85355C4.84171 10.0488 5.15829 10.0488 5.35355 9.85355C5.54882 9.65829 5.54882 9.34171 5.35355 9.14645L1.20711 5L5.35355 0.853553ZM11.1464 0.853553C10.9512 0.658291 10.9512 0.341709 11.1464 0.146447C11.3417 -0.0488155 11.6583 -0.0488155 11.8536 0.146447L16.3536 4.64645C16.5488 4.84171 16.5488 5.15829 16.3536 5.35355L11.8536 9.85355C11.6583 10.0488 11.3417 10.0488 11.1464 9.85355C10.9512 9.65829 10.9512 9.34171 11.1464 9.14645L15.2929 5L11.1464 0.853553Z"
                fill="currentColor"
              />
            </svg>
          }
          color="action"
          className="ml-4 sm:flex hidden space-x-2"
        >
          <span className="whitespace-nowrap">Embed</span>
        </Button>
      )}
      <div className="pl-1 -mr-2 md:ml-4 text-gray-900">{cardMenu}</div>
    </>
  )
}

export default ActionsSection
