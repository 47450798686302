import * as rrweb from 'rrweb'

const defaultOptions = {
  useVirtualDom: false,
  mouseTail: false,
  skipInactive: true,
  pauseAnimation: false,
  UNSAFE_replayCanvas: true,
}

let replayer: any

export const initReplayer = (element: HTMLElement, events: any[]) => {
  element.innerHTML = ''
  replayer = undefined

  replayer = new rrweb.Replayer(events, {
    root: element,
    ...defaultOptions,
  })
  replayer.enableInteract()
}

export const disableLinks = () => {
  if (replayer && replayer.iframe) {
    const elements = replayer.iframe.contentWindow.document.getElementsByTagName('a')

    for (const element of elements) {
      element.removeAttribute('href')
    }
  }
}

export const goto = (timestamp: number) => {
  replayer.pause(timestamp)
}

export const getNode = (id: number) => {
  return replayer.getMirror().getNode(id)
}
