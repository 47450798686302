import React from 'react'
import EditableField from './EditableField'
import ReactHtmlParser from 'react-html-parser'
import { htmlStrip } from '../../../common/utils/htmlStrip'

interface Props {
  name: string
  className: string
  canEdit: boolean
  placeholder?: string
  iconClassName?: string
  onSubmit?: any
  children?: React.ReactNode
}

export const stripField = (preventNextLine: boolean, value: any) => {
  return preventNextLine ? htmlStrip(value) : ReactHtmlParser(value)
}

const ContentField: React.FC<Props> = ({
  canEdit,
  className,
  name,
  placeholder,
  iconClassName,
  children,
}) => {
  const preventNextLine = name === 'name'
  const stripedField = stripField(preventNextLine, children)
  return canEdit ? (
    <EditableField
      placeholder={placeholder}
      iconClassName={iconClassName}
      className={`${className} group`}
      name={name}
      preventNextLine={preventNextLine}
    />
  ) : (
    <div className={className}>{stripedField}</div>
  )
}

export default ContentField
