import { useMutation } from '@apollo/client'
import { DELETE_SKILL_SET_QUERY } from '../../App/graphql/queries/skill_set'

const useDeleteSkillSetMutation = (id: number) => {
  return useMutation(DELETE_SKILL_SET_QUERY, {
    variables: {
      id,
    },
  })
}

export default useDeleteSkillSetMutation
