import { MessageContent } from '../../../../components/graphql'
import React from 'react'

const ImageContent = ({ content }: { content: MessageContent }) => {
  return (
    <div className="max-w-3xl m-5">
      <img src={content.value} alt="" className="rounded-md shadow" />
    </div>
  )
}

export default ImageContent
