import React, { useState } from 'react'
import IconButton, { Sizes } from '../IconButton'
import { ExitFullscreenIcon, FullscreenIcon } from './icons'
import useEventListener from '../../../Labs/hooks/useEventListener'

const FullscreenButton = () => {
  const [fullscreen, setFullscreen] = useState(!!document.fullscreenElement)

  useEventListener('fullscreenchange', () => {
    setFullscreen(!!document.fullscreenElement)
  })

  const onClick = () => {
    !document.fullscreenElement
      ? document.documentElement.requestFullscreen()
      : document.exitFullscreen()
  }

  return (
    <IconButton size={Sizes.MEDIUM} onClick={onClick}>
      {fullscreen ? <ExitFullscreenIcon /> : <FullscreenIcon />}
    </IconButton>
  )
}

export default FullscreenButton
