import React from 'react'
import { clsx } from 'clsx'

import './style.scss'

interface Props {
  elementRef: any
  className?: string
}

const Circles = ({ elementRef, className }: Props) => (
  <div className={clsx('circles circle circle-7', className)} ref={elementRef}>
    <div className="circle circle-6">
      <div className="circle circle-5">
        <div className="circle circle-4">
          <div className="circle circle-3">
            <div className="circle circle-2">
              <div className="circle circle-1" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Circles
