import React, { useState } from 'react'
import { usePopper } from 'react-popper'
import { popoverModifiers } from './modifiers'

import './style.scss'

interface Props {
  element?: HTMLElement | any
  children?: React.ReactNode
}

const ReactPopper: React.FC<Props> = ({ children, element }) => {
  const [popperElement, setPopperElement] = useState(null)
  const [arrowElement, setArrowElement] = useState(null)

  const { styles, attributes } = usePopper(element, popperElement, {
    modifiers: [...popoverModifiers, { name: 'arrow', options: { element: arrowElement } }],
  })

  return (
    <div
      className="popper-tooltip"
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
    >
      <div>{children}</div>
      <div className="popper-arrow" ref={setArrowElement} style={styles.arrow} />
    </div>
  )
}

export default ReactPopper
