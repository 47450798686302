import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Filter as FilterType } from '../../types'
import React, { Fragment } from 'react'
import { ArrayParam, useQueryParam } from 'use-query-params'

interface Props {
  filter: FilterType
}

const Filter: React.FC<Props> = ({ filter }) => {
  const [options = [], setOptions] = useQueryParam(filter.name, ArrayParam)

  return (
    <Popover className="px-4 relative inline-block text-left">
      <Popover.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
        <span>{filter.label}</span>

        {options.length > 0 && (
          <span className="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">
            {options.length}
          </span>
        )}

        <ChevronDownIcon
          className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className="origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none">
          <form className="space-y-4">
            {filter.options.map((filterOption, index) => (
              <div key={index} className="flex items-center">
                <input
                  type="checkbox"
                  id={`filter-${filter.name}-${index}`}
                  defaultChecked={options.some((option) => option === filterOption.value)}
                  className="h-4 w-4 border-gray-300 rounded text-flowmo-blue-600 focus:ring-flowmo-blue-500"
                  onChange={({ target }) =>
                    setOptions(
                      target.checked
                        ? [...options, filterOption.value]
                        : options.filter((option) => option !== filterOption.value)
                    )
                  }
                />
                <label
                  htmlFor={`filter-${filter.name}-${index}`}
                  className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap"
                >
                  {filterOption.label}
                </label>
              </div>
            ))}
          </form>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

export default Filter
