import React, { useState } from 'react'
import {
  DELETE_DATA_SET_MUTATION,
  GET_DATA_SET_QUERY,
  UPDATE_DATA_SET_MUTATION,
} from '../../graphql/queries/data_set'
import {
  DataSetQuery,
  DataSetQueryVariables,
  UpdateDataSetMutation,
  UpdateDataSetMutationVariables,
  DeleteDataSetMutation,
  DeleteDataSetMutationVariables,
} from '../../../../components/graphql'
import Spin from '../../../../components/common/ui/Spin'
import { useMutation, useQuery } from '@apollo/client'
import PageHeader from '../../components/PageHeader'
import DynamicForm from '../../components/DynamicForm'
import { useHistory } from 'react-router-dom'
import HeaderActionButton from '../../components/HeaderActionButton'
import DeleteModal from '../../components/DeleteModal'
import { dataSetFormData } from './CreateDataSet'

const EditAnalysis = ({ uuid }: { uuid: string }) => {
  const formRef = React.useRef<{ submitForm: () => Promise<void> }>(null)
  const [isSaving, setIsSaving] = React.useState(false)
  const history = useHistory()
  const { data, loading } = useQuery<DataSetQuery, DataSetQueryVariables>(GET_DATA_SET_QUERY, {
    variables: { uuid },
  })
  const [open, setOpen] = useState(false)

  const [updateDataSet] = useMutation<UpdateDataSetMutation, UpdateDataSetMutationVariables>(
    UPDATE_DATA_SET_MUTATION
  )

  const [deleteDataSet] = useMutation<DeleteDataSetMutation, DeleteDataSetMutationVariables>(
    DELETE_DATA_SET_MUTATION
  )

  if (loading) {
    return <Spin />
  }

  const data_set = data?.dataSet

  if (!loading && !data_set) {
    window.toastr.error('Data Set not found')
    history.push('/data_sets')
    return null
  }

  const handleDelete = async () => {
    const response = await deleteDataSet({
      variables: {
        uuid,
      },
    })
    if (response.data?.deleteDataSet.errors.length > 0) {
      window.toastr.error('Error deleting data set')
    } else {
      window.toastr.success('Data Set deleted successfully')
      history.push('/data_sets')
    }
    return
  }

  const handleSubmit = async (values: any) => {
    setIsSaving(true)
    const response = await updateDataSet({
      variables: {
        uuid,
        name: values.name,
        description: values.description,
        definition: values.definition,
      },
    })
    if (response.data?.updateDataSet.errors.length > 0) {
      window.toastr.error('Error updating data set')
      history.push('/data_sets')
    } else {
      window.toastr.success('Data set updated successfully')
      history.push(`/data_set/${uuid}`)
    }
    setIsSaving(false)
    return
  }

  const initialData = {
    name: data_set?.name,
    description: data_set?.description,
    definition: JSON.stringify(data_set?.definition),
  }

  return (
    <>
      <PageHeader
        title={data_set.name}
        breadCrumbs={[
          { title: 'Data Sets', to: '/data_sets' },
          { title: data_set.name, to: `/data_set/${data_set.uuid}` },
          { title: 'Edit Analysis' },
        ]}
      >
        <HeaderActionButton onClick={() => setOpen(true)} label="Delete" />
      </PageHeader>
      <DeleteModal
        open={open}
        setOpen={setOpen}
        onDelete={handleDelete}
        title="Delete Data Set"
        buttonLabel="Delete"
        description="Are you sure you want to delete the data set? All of your data will be permanently removed from our servers forever. This action cannot be undone."
      />
      <div className="max-w-2xl">
        <DynamicForm
          ref={formRef}
          formData={dataSetFormData}
          initialData={initialData}
          handleSubmit={handleSubmit}
        />
        <div className="flex flex-row space-x-4">
          <button
            type="submit"
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-flowmo-blue-600 hover:bg-flowmo-blue-700"
            onClick={() => formRef.current?.submitForm()}
          >
            {isSaving ? <Spin /> : 'Save'}
          </button>
          <button
            type="button"
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-300 hover:bg-gray-500"
            onClick={() => history.push(`/data_set/${uuid}`)}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  )
}
export default EditAnalysis
