import React from 'react'
import { PluginTemplateType } from '../PluginCard'

const PluginTemplateDescription = ({ pluginTemplate }: { pluginTemplate: PluginTemplateType }) => {
  return (
    <div className="">
      <dl>
        <dt className="mb-2 font-semibold leading-none text-gray-90">Details</dt>
        <dd className="mb-4 font-light text-gray-500 sm:mb-5">{pluginTemplate.description}</dd>
        <dt className="mb-2 font-semibold leading-none text-gray-900">Type</dt>
        <dd className="mb-4 font-light text-gray-500 sm:mb-5">{pluginTemplate.type}</dd>
      </dl>
    </div>
  )
}

export default PluginTemplateDescription
