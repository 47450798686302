import React from 'react'
import useCirclesPosition from './useCirclesPosition'
import useBeaconPosition from './useBeaconPosition'
import { useStepDescription } from './description'

import DescriptionHighlighter from './strategies/DescriptionHighlighter'
import PureHighlighter from './strategies/PureHighlighter'

interface Props {
  element?: HTMLElement
  position?: DOMRect
}

const Highlighter = ({ element, position }: Props) => {
  const description = useStepDescription(element)

  const circlesRef = useCirclesPosition(position)
  const { beaconRef, styles } = useBeaconPosition(position)

  if (!element || !styles) {
    return <></>
  }

  return description ? (
    <DescriptionHighlighter
      description={description}
      circleRef={circlesRef}
      beaconRef={beaconRef}
      styles={styles}
    />
  ) : (
    <PureHighlighter circleRef={circlesRef} beaconRef={beaconRef} styles={styles} />
  )
}

export default Highlighter
