import React from 'react'
import useNavigationContext from '../../../../../../../views/Walkthrough/modules/PlaygroundContext/hooks/useNavigationContext'
import useContentContext from '../../../../../../../views/Walkthrough/modules/PlaygroundContext/hooks/useContentContext'

const Counter: React.FC = () => {
  const { steps } = useContentContext()
  const { index } = useNavigationContext()
  return (
    <div className="font-inter font-medium text-[13px] leading-[16px] text-[#5E6D85]">
      <span className="text-[#1B72F2]">{index + 1}</span> / {steps.length}
    </div>
  )
}

export default Counter
