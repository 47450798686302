import React from 'react'
import { hot } from 'react-hot-loader'
import { ApolloProvider } from '@apollo/client/react'
import { openClient } from '../common/ApolloClient'
import Walkthrough from './views/Walkthrough'
import * as Sentry from '@sentry/react'
import '../common/sentry'
import { BrowserRouter as Router } from 'react-router-dom'

interface Props {
  uuid: string
  basePath: string
}

const LabsPage: React.FC<Props> = ({ uuid }) => {
  return (
    <Sentry.ErrorBoundary>
      <ApolloProvider client={openClient}>
        <Router>
          <Walkthrough uuid={uuid} usePublicEndpoint={true} />
        </Router>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  )
}

export default hot(module)(LabsPage)
