import React from 'react'

const CheckMarkStepIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 26 26">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13 24c6.075 0 11-4.925 11-11S19.075 2 13 2 2 6.925 2 13s4.925 11 11 11zm0 1c6.627 0 12-5.373 12-12S19.627 1 13 1 1 6.373 1 13s5.373 12 12 12z"
      clipRule="evenodd"
    ></path>
    <circle cx="13" cy="13" r="9" fill="currentColor" />
    <path
      fill="#fff"
      d="M17.69 10.56a.602.602 0 010 .88l-5 5a.602.602 0 01-.88 0l-2.5-2.5a.602.602 0 010-.88.602.602 0 01.88 0l2.05 2.051 4.57-4.55a.602.602 0 01.88 0z"
    ></path>
  </svg>
)

export const CheckMarkLoader = () => (
  <div className="relative">
    <svg
      aria-hidden="true"
      className="text-gray-200 animate-spin"
      width="26"
      height="26"
      viewBox="0 0 100 101"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="#E4E7ED"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="#29BC7E"
      />
    </svg>
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute top-0 left-0"
    >
      <path
        d="M17.6895 10.5605C17.9434 10.7949 17.9434 11.2051 17.6895 11.4395L12.6895 16.4395C12.4551 16.6934 12.0449 16.6934 11.8105 16.4395L9.31055 13.9395C9.05664 13.7051 9.05664 13.2949 9.31055 13.0605C9.54492 12.8066 9.95508 12.8066 10.1895 13.0605L12.2402 15.1113L16.8105 10.5605C17.0449 10.3066 17.4551 10.3066 17.6895 10.5605Z"
        fill="#E4E7ED"
      />
    </svg>
  </div>
)

export default CheckMarkStepIcon
