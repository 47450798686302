import React from 'react'

interface Props {
  option: { title: string; icon: React.ReactNode }
  onClick: () => void
  selected: boolean
}

const ScoreOption: React.FC<Props> = ({ option, onClick, selected }) => (
  <div
    className={`bg-white border rounded-md py-3.5 cursor-pointer flex flex-col items-center justify-center hover:drop-shadow-md ${
      selected ? 'border-flowmo-blue-500' : 'border-gray-300'
    }`}
    onClick={onClick}
    style={{ width: 81 }}
  >
    {option.icon}
    <div className="block text-sm font-medium text-gray-500 text-center mt-3.5">{option.title}</div>
  </div>
)

export default ScoreOption
