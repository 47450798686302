import React from 'react'
import { CheckCircleIcon, ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline'
import { shareLink } from '../../../common/utils/links'
import { useCopyToClipboard } from '../../../common/hooks/useCopyToClipboard'
import Walkthrough from '../../../App/models/Walkthrough'
import SkillSet from '../../../App/models/SkillSet'

interface Props {
  objectType: 'walkthrough' | 'skillSet'
  object: Walkthrough | SkillSet
}
const CONTENT_NAME = {
  walkthrough: 'Walkthrough',
  skillSet: 'Skill Set',
}

const CopyInput: React.FC<Props> = ({ objectType, object }) => {
  const shareUrl = new URL(shareLink(object.uuid, objectType), document.baseURI).href
  const [copyTextStatus, copyText] = useCopyToClipboard(shareUrl)

  return (
    <div className="my-5 py-5">
      <div className="mt-2 mb-2">
        <p className="text-sm text-gray-500">
          Copy the link below to share your {CONTENT_NAME[objectType]}
        </p>
      </div>

      <div className="shadow-sm flex">
        <input
          type="text"
          readOnly={true}
          className="shadow-sm flex-grow read-only:bg-gray-100 read-only:text-gray-600 focus:ring-flowmo-blue-500 focus:border-flowmo-blue-500 block w-full sm:text-sm border-gray-300 rounded-l-md"
          value={shareUrl}
        />
        <button
          className="bg-gray-50 border border-l-0 border-gray-300 rounded-r-md px-3 inline-flex items-center text-gray-500 sm:text-sm hover:bg-gray-100"
          onClick={copyText}
        >
          {copyTextStatus == 'copied' ? (
            <CheckCircleIcon className="h-6 w-6 text-green-600" />
          ) : (
            <ArrowLeftOnRectangleIcon className="h-6 w-6" />
          )}
        </button>
      </div>
    </div>
  )
}

export default CopyInput
