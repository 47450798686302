import React from 'react'
import { Dialog } from '@headlessui/react'

interface Props {
  onClose: () => void
  children?: React.ReactNode
}

const Container: React.FC<Props> = ({ children, onClose }) => (
  <Dialog as="div" className="fixed z-40 inset-0 overflow-y-auto" onClose={onClose} open={true}>
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

      {/* This element is to trick the browser into centering the modal contents. */}
      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
        &#8203;
      </span>
      <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-lg sm:w-full sm:p-6">
        {children}
      </div>
    </div>
  </Dialog>
)

export default Container
