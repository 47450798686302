import React from 'react'
import useWalkthrough from '../../../Walkthrough/hooks/useWalkthrough'
import Replayer from './modules/Replay'
import PlaygroundProvider from './modules/PlaygroundContext'
import ElementLayer from './modules/ElementLayer'
import Widget from '../../components/Widget'
import { usePageTitle } from '../../../common/hooks/usePageTitle'
import Spin from '../../../common/ui/Spin'

interface Props {
  uuid: string
  usePublicEndpoint?: boolean
}

const Loader = () => (
  <Spin
    className="h-20 w-20 text-ddu-blue m-auto mt-6"
    loadMessage={
      <div className="mt-6 text-flowmo-blue-400 text-lg text-center">Starting data download</div>
    }
  />
)

const Playground: React.FC<Props> = ({ uuid, usePublicEndpoint }) => {
  const { content } = useWalkthrough(uuid, usePublicEndpoint)
  usePageTitle(content?.walkthrough && `Labs | ${content.walkthrough.name}`)

  if (content.loading) {
    return <Loader />
  }

  return (
    <PlaygroundProvider walkthrough={content.walkthrough} usePublicEndpoint={usePublicEndpoint}>
      <Replayer />
      <ElementLayer />
      <Widget />
    </PlaygroundProvider>
  )
}

export default Playground
