import React from 'react'
import { PhotoIcon, VideoCameraIcon } from '@heroicons/react/24/solid'
import CardContainer from './CardContainer'

const CardLoaderContainer = ({ children }: { children: React.ReactNode }) => (
  <CardContainer className="bg-[#EDEFF1] flex justify-center items-center animate-pulse text-white">
    {children}
  </CardContainer>
)

const Loader = () => (
  <>
    <CardLoaderContainer>
      <PhotoIcon className="h-16 w-16" />
    </CardLoaderContainer>
    <CardLoaderContainer>
      <VideoCameraIcon className="h-16 w-16" />
    </CardLoaderContainer>
  </>
)

export default Loader
