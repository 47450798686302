import React from 'react'
// @ts-ignore

const BackgroundGraphic = () => {
  return (
    <>
      <div
        className="absolute top-[-1rem] left-1/2 transform -translate-x-1/2 w-full max-w-[90vw] blur-2xl"
        aria-hidden="true"
      >
        <div
          className="relative left-1/2 transform -translate-x-1/2 aspect-[1155/678] w-full h-auto rotate-[30deg] bg-gradient-to-tr from-[#a080ff] to-[#5349e4] opacity-30 sm:max-w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </>
  )
}

export default BackgroundGraphic
