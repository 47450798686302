import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import Rate from './Rate'
import Button from '../../../../Button'
import useContentContext from '../../../../../views/Walkthrough/modules/PlaygroundContext/hooks/useContentContext'
import { CREATE_REVIEW } from '../../../../../../App/graphql/queries/review'
import { clsx } from 'clsx'

interface Props {
  completeReview: () => void
  scoreValue?: number
  rows?: number
}

const ReviewContainer: React.FC<Props> = ({ completeReview, scoreValue, rows = 4 }) => {
  const { walkthrough } = useContentContext()
  const [review, setReview] = useState('')
  const [score, setScore] = useState(scoreValue)

  const [createUserReview] = useMutation(CREATE_REVIEW)

  const handleRate = (score: number, review: string) =>
    createUserReview({
      variables: {
        source: 'institute_labs',
        objectId: walkthrough.id,
        objectType: 'walkthrough',
        score,
        description: review && review.length ? review : null,
      },
    }).then(() => completeReview())

  return (
    <div className="flex flex-col items-center">
      <Rate onChange={setScore} value={score} />
      <>
        <textarea
          className={clsx(
            'bg-white text-black border border-[#E4E7ED] font-inter text-[12px] transition-colors',
            'rounded min-h-[100px] placeholder:text-[rgba(0,0,0,0.4)] resize-none w-full py-2 px-4'
          )}
          placeholder="What could be better?"
          value={review}
          onChange={(event) => setReview(event.target.value)}
          rows={rows}
        />
        <div className="mt-7">
          <Button disabled={!score} color="success" onClick={() => handleRate(score, review)}>
            Submit
          </Button>
        </div>
      </>
    </div>
  )
}

export default ReviewContainer
