import React from 'react'
import PluginModalBase from './PluginModelBase'
import { REMOVE_PLUGIN_MUTATION, UPDATE_PLUGIN_MUTATION } from '../../../graphql/queries/agent'
import { useMutation } from '@apollo/client'
import PluginTemplateDescription from './PluginTemplateDescription'
import DynamicForm from '../../DynamicForm'
import { PluginType } from '../PluginCard'
type PluginEditProps = {
  plugin: PluginType
  setOpen: (open: boolean) => void
  onAction: () => void
  open: boolean
}
const PluginEdit = ({ plugin, onAction, setOpen, open }: PluginEditProps) => {
  const formRef = React.useRef<{ submitForm: () => Promise<void> }>(null)
  const [removePlugin] = useMutation(REMOVE_PLUGIN_MUTATION)
  const [updatePlugin] = useMutation(UPDATE_PLUGIN_MUTATION)
  const name = plugin.name || plugin.pluginTemplate.name
  const handleSave = async (formValues: any) => {
    const response = await updatePlugin({
      variables: { id: plugin.id, config: formValues },
    })
    if (response.data.updatePlugin.errors.length > 0) {
      window.toastr.error('Error updating plugin')
    } else {
      window.toastr.success('Plugin updated successfully')
    }

    setOpen(false)
    onAction()
  }
  const handleDelete = async () => {
    const response = await removePlugin({
      variables: { id: plugin.id },
    })
    if (response.data.removePlugin.errors.length > 0) {
      window.toastr.error('Error deleting plugin')
    } else {
      window.toastr.success('Plugin removed successfully')
    }

    setOpen(false)
    onAction()
  }

  return (
    <PluginModalBase title={name} setOpen={setOpen} open={open}>
      <div className="w-full">
        <PluginTemplateDescription pluginTemplate={plugin.pluginTemplate} />
        {plugin.pluginTemplate?.dataTemplate.length > 0 && (
          <div className="flex items-center space-x-4">
            <div className="flex flex-col w-full">
              <dt className="mb-6 font-semibold leading-none text-gray-900">Settings</dt>
              <div className="pl-4">
                <DynamicForm
                  ref={formRef}
                  formData={plugin.pluginTemplate.dataTemplate}
                  handleSubmit={handleSave}
                  initialData={plugin.config}
                />
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-row w-full justify-between">
          <div className="flex flex-row space-x-4">
            <button
              type="button"
              onClick={() => formRef.current?.submitForm()}
              className="text-white inline-flex items-center bg-flowmo-blue-700 hover:bg-flowmo-blue-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Save
            </button>
            <button
              type="button"
              className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10"
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
          </div>
          <div className="justify-end">
            <button
              className="py-2 px-3 text-sm font-medium text-red-500 bg-white rounded-lg border border-red-200 hover:bg-red-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-red-900 focus:z-10"
              onClick={handleDelete}
              type="button"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </PluginModalBase>
  )
}

export default PluginEdit
