import React from 'react'
import CheckMark from './CheckMark'
import Title from './Title'
import Counter from './Counter'
import Navigation from './Navigation'

const Header: React.FC = () => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-1.5">
        <CheckMark />
        <Title />
      </div>
      <div className="flex items-center space-x-3">
        <Counter />
        <Navigation />
      </div>
    </div>
  )
}

export default Header
