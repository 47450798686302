import { useState } from 'react'
import useEventListener from './useEventListener'

export const useWindowSize = () => {
  const mediumScreenWidth = 1023
  const smallScreenWidth = 767
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    isMedium: window.innerWidth < mediumScreenWidth && window.innerWidth > smallScreenWidth,
    isSmall: window.innerWidth < smallScreenWidth,
  })

  useEventListener('resize', () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
      isMedium: window.innerWidth < mediumScreenWidth && window.innerWidth > smallScreenWidth,
      isSmall: window.innerWidth < smallScreenWidth,
    })
  })

  return windowSize
}

export default useWindowSize
