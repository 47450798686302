import React from 'react'
import ProgressBar from './ProgressBar'
import useWidgetContext from '../../../views/Walkthrough/modules/PlaygroundContext/hooks/useWidgetContext'

const Container = ({ children }: { children: React.ReactNode }) => {
  const { setMinimized } = useWidgetContext()
  return (
    <div
      onClick={() => setMinimized(false)}
      className="rounded-full w-[60px] h-[60px] z-50 bg-[#EA6A29] fixed bottom-5 right-5 cursor-pointer p-0.5 hover:bg-[#DF5C19] transition-colors shadow-lg"
    >
      {children}
    </div>
  )
}

const MinimizedWidget: React.FC = () => (
  <Container>
    <ProgressBar />
  </Container>
)

export default MinimizedWidget
