import React, { useState } from 'react'
import CardContainer from './CardContainer'
import Dialog from '../../../../../../../Dialog'

interface Props {
  url: string
  dimensions?: any
}
const ScreenshotCard: React.FC<Props> = ({ url, dimensions }) => {
  const [opened, setOpened] = useState(false)
  const toggleOpened = () => setOpened(!opened)

  return (
    <>
      {opened && (
        <Dialog onClose={toggleOpened}>
          <img src={url} alt="Step Screenshot" className="max-w-5xl" />
        </Dialog>
      )}

      <CardContainer className="cursor-pointer overflow-hidden" onClick={toggleOpened}>
        <img
          src={url}
          alt="Step Screenshot"
          className={`h-full max-w-fit scale-125 ${
            dimensions && dimensions.left > 900 ? '-translate-x-16' : ''
          }`}
        />
      </CardContainer>
    </>
  )
}

export default ScreenshotCard
