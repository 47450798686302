import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { EnvelopeOpenIcon } from '@heroicons/react/24/outline'
import _ from 'lodash'
import { useMutation } from '@apollo/client'
import { SEND_INVITES_MUTATION } from '../../../graphql/queries/user'
import Spin from '../../../../../components/common/ui/Spin'

const InviteModal = ({ open, setOpen }: { open: boolean; setOpen: (open: boolean) => void }) => {
  const [invitesSending, setInvitesSending] = useState<boolean>(false)
  const cancelButtonRef = useRef(null)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [sendInvites] = useMutation(SEND_INVITES_MUTATION)

  const handleSendInvites = async () => {
    setInvitesSending(true)
    if (textAreaRef.current) {
      const emailString = textAreaRef.current.value
      const splitEmails = _.flatMap(emailString.split(','), (email) => email.split('\n'))
      const processedEmails = _.uniq(_.compact(_.map(splitEmails, (email) => _.trim(email))))

      if (processedEmails.length > 0) {
        await sendInvites({ variables: { emails: processedEmails } })
      }
      window.toastr.success('Invites sent successfully')
      setInvitesSending(false)
      setOpen(false)
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-flowmo-blue-200">
                    <EnvelopeOpenIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Invite your teammates
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Enter the email address of the folks you want to invite to your team.
                        They&apos;ll get an email with instructions for registering.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <textarea
                    ref={textAreaRef}
                    rows={3}
                    name="comment"
                    id="comment"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-flowmo-blue-600 sm:text-sm sm:leading-6"
                    defaultValue={''}
                    placeholder="Enter email addresses, separated by commas or on different lines."
                  />
                </div>

                {invitesSending ? (
                  <div className="mt-5">
                    <Spin />
                  </div>
                ) : (
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-flowmo-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-flowmo-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-flowmo-blue-600 sm:col-start-2"
                      onClick={handleSendInvites}
                    >
                      Send invites
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default InviteModal
