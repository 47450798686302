import React, { useState } from 'react'
import { ArrowRightCircleIcon } from '@heroicons/react/24/outline'
// @ts-ignore
import LabsImage from '../../../../../../assets/images/features/learn/LabsLearning.png'
import Switcher from '../../../../common/ui/Switcher'

interface Props {
  launchUrl: string
  launchType?: string
  isLearnModeEnabled?: boolean
  name?: string
  canEdit: boolean
}

export const launcherOptions: any = {
  labs: {
    title: 'Hands-on Labs',
    description:
      'Hands-on Labs. Practice working in the sandbox environment using our step-by-step procedures!',
    icon: <img className="w-20" src={LabsImage} alt="LabsImage" />,
    button: function startLink(href: string, classes?: string) {
      return (
        <a
          target="_blank"
          href={href}
          className={`flex items-center justify-center p-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-flowmo-orange-600 bg-flowmo-orange-500 ${classes}`}
          rel="noreferrer"
        >
          <div className="whitespace-nowrap">
            {' '}
            <span className="hidden lg:inline">Start</span> Labs Learning
          </div>
          <ArrowRightCircleIcon className="ml-1 h-4 w-4 hidden lg:block" />
        </a>
      )
    },
  },
}

const Launcher: React.FC<Props> = ({ launchUrl, launchType, isLearnModeEnabled, canEdit }) => {
  const [enableState, setEnableState] = useState(isLearnModeEnabled)
  const { description, icon, button, title } =
    launcherOptions[launchType as keyof typeof launcherOptions]

  return (
    launchUrl &&
    (canEdit || isLearnModeEnabled) && (
      <div className="md:flex-1 w-full sm:pb-5">
        <div className="inline lg:flex h-full">
          <div
            className={`rounded-md border ${
              !enableState && 'duration-200 ease-in-out opacity-50'
            } border-[#EFEFEF] bg-white shadow-lg w-full`}
          >
            {canEdit && (
              <div className="w-full items-center flex p-4 bg-white border-b border-[#EFEFEF]">
                <span className="text-sm hidden sm:flex font-small text-gray-600">
                  {enableState
                    ? 'This mode will be visible to all users you share the page'
                    : 'Turn on to make visible to all users you share the page'}
                </span>
                <Switcher name={launchType} setEnableState={setEnableState} />
              </div>
            )}
            <div>
              <div className={`p-4`}>
                <div className="flex flex-col sm:flex-row items-center justify-between md:justify-start lg:justify-start">
                  {icon}
                  <div className="flex flex-col sm:flex-row items-center sm:w-full sm:justify-between">
                    <p className="text-sm font-medium sm:hidden my-2">{title}</p>
                    <p className="mx-4 hidden sm:block text-xs 2xl:text-sm">{description}</p>
                    <div
                      className={`sm:flex sm:pointer-events-auto sm:opacity-100 
                      ${canEdit ? 'opacity-50 pointer-events-none' : ''} 
                      select-none order-3 mt-2 flex-shrink-0 w-full ml-auto sm:order-2 sm:mt-0 sm:w-auto 
                      ${!enableState && 'pointer-events-none '}`}
                    >
                      {button(
                        launchUrl,
                        canEdit
                          ? ''
                          : 'pointer-events-none opacity-50 lg:pointer-events-auto lg:opacity-100'
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default Launcher
