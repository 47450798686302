import Spin from '../../../../common/ui/Spin'
import React, { useState } from 'react'
import {
  TaskRunMarkPassedMutation,
  TaskRunMarkPassedMutationVariables,
  TaskRunsQuery,
} from 'app/javascript/components/graphql'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { WrenchScrewdriverIcon } from '@heroicons/react/24/outline'
import { MUTATION_TASK_RUN_MARK_PASSED } from '../../../graphql/queries/workflow'
import { useMutation } from '@apollo/client'
import TaskVisualization from './TaskVisualization'
import MessageBody from '../../../components/Chat/MessageBody'
import TextContent from '../../../components/Chat/TextContent'
interface TaskRunProps {
  run: TaskRunsQuery['taskRuns'][0]
}

const TaskRun = ({ run }: TaskRunProps) => {
  const [updateStatus, setUpdateStatus] = useState(false)
  const [taskRunMarkSuccessfull] = useMutation<
    TaskRunMarkPassedMutation,
    TaskRunMarkPassedMutationVariables
  >(MUTATION_TASK_RUN_MARK_PASSED)

  const handleMarkSuccessfull = async () => {
    setUpdateStatus(true)
    await taskRunMarkSuccessfull({ variables: { uuid: run.uuid } })
    setUpdateStatus(false)
  }
  return (
    <>
      <div className="flex justify-between gap-x-4">
        <div className="py-0.5 text-xs leading-5 text-gray-500">
          {run.state == 'running' ? (
            <>
              <Spin />
            </>
          ) : (
            <>
              <span className="font-medium text-gray-900">Task Run</span>{' '}
              {run.runtimeSeconds && <>took {run.runtimeSeconds} seconds</>}
            </>
          )}
        </div>
        <time
          dateTime="2023-01-23T15:56"
          className="flex-none py-0.5 text-xs leading-5 text-gray-500"
        >
          {formatDistanceToNow(new Date(run.startedAt), { addSuffix: true })}
        </time>
      </div>
      <div className="px-4 py-6 sm:col-span-2 sm:px-0">
        <dt className="text-sm font-medium leading-6 text-gray-900">Task</dt>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
          <TaskVisualization task={run.requestMessage} />
        </dd>
      </div>

      <div className="border-t border-gray-200 px-4 py-6 sm:col-span-2 sm:px-0">
        <dt className="text-sm font-medium leading-6 text-gray-900">Thread</dt>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
          {run.messageHistory && run.messageHistory.length > 0 ? (
            <ul className="list-disc list-inside">
              {run.messageHistory
                .sort((a, b) => (a.id > b.id ? 1 : -1))
                .map((message, index) => (
                  <div key={index} className="flex flex-col">
                    <div className="font-semibold ml-3 underline">
                      {message.actor == 'WORKFLOW' ? 'You said' : 'Agent replied'}
                    </div>
                    <div className="p-2 ml-6 bg-white rounded-xl my-2">
                      <MessageBody message={message} />
                    </div>
                  </div>
                ))}
            </ul>
          ) : (
            <TextContent content={{ type: 'text', value: run.responseMessage }} />
          )}
        </dd>
      </div>

      {run.evaluationMessage && (
        <div className="border-t border-gray-200 px-4 py-6 sm:col-span-2 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            Reason for {run.evaluationMessage?.status ? 'success' : 'failure'}
          </dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
            {run.evaluationMessage?.reason}
          </dd>
          {!run.evaluationMessage?.status && (
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
              {updateStatus ? (
                <Spin />
              ) : (
                <button
                  className="text-blue-500 hover:underline text-xs flex flex-row space-x-2"
                  onClick={() => handleMarkSuccessfull()}
                >
                  <WrenchScrewdriverIcon className="size-4" />
                  <span>mark successfull</span>
                </button>
              )}
            </dd>
          )}
        </div>
      )}
      <div className="py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{run.responseStatus}</div>
      <div className="flex flex-col">
        <div className="">{run.webhookCompletedAt}</div>
        <div className="">{run.webhookResponseStatus}</div>
        <div className="">{run.webhookStatus}</div>
      </div>
    </>
  )
}

export default TaskRun
