import React from 'react'
import { clsx } from 'clsx'

interface Props {
  color?: 'primary' | 'success'
  className?: string
  onClick?: () => void
  children?: React.ReactNode
  disabled?: boolean
}
const ColorStyles = {
  primary: 'bg-[#EA6A29] hover:bg-[#DF5C19] active:bg-[#D54C06] disabled:bg-[#F6C2A7]',
  success: 'bg-[#29BC7E] hover:bg-[#24AB72] active:bg-[#1D9663] disabled:bg-[#ABE5CD]',
}

const Button: React.FC<Props> = ({ children, className, onClick, color = 'primary', disabled }) => (
  <button
    disabled={disabled}
    className={clsx(
      'px-3 py-1.5 rounded transition-colors flex justify-center items-center space-x-2.5',
      'font-inter text-white text-[13px] leading-[18px]',
      ColorStyles[color],
      className
    )}
    onClick={onClick}
  >
    {children}
  </button>
)

export default Button
