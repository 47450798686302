import React, { useEffect } from 'react'
import ThreadHistory from './ThreadHistory'
import AgentHeader from './AgentHeader'
import AgentWorkForYou from './AgentWorkForYou'
import Chat from './Chat'
import AgentWorkWithYou from './AgentWorkWithYou'
import AgentIntro from './AgentIntro'
import { useChatDispatch, useChatState } from './AgentProvider'
import { useHistory, useLocation } from 'react-router-dom'
import {
  ActorEnum,
  GetMessageHistoriesQuery,
  GetMessageHistoriesQueryVariables,
  GetStepHistoriesQuery,
  GetStepHistoriesQueryVariables,
  SendAgentMessageMutation,
  SendAgentMessageMutationVariables,
} from '../../../../../components/graphql'
import { useMutation, useQuery } from '@apollo/client'
import { SEND_AGENT_MESSAGE } from '../../../graphql/queries/agent'
import { QueueListIcon } from '@heroicons/react/24/solid'
import { GET_MESSAGE_HISTORIES, GET_STEP_HISTORIES } from '../../../graphql/queries/message_thread'

const AgentApp = () => {
  const location = useLocation()
  const history = useHistory()
  const { agent, threadUuid, isQuerying, messageQueue, chatId } = useChatState()
  const {
    setThreadUuid,
    setIsQuerying,
    addMessage,
    openHistory,
    clearMessageQueue,
    setMessages,
    setSteps,
  } = useChatDispatch()

  const searchParams = new URLSearchParams(location.search)
  const threadUuidFromUrl = searchParams.get('thread_uuid')

  useEffect(() => {
    // we have a thread uuid in the url, but not in the state
    if (threadUuidFromUrl) {
      setThreadUuid(threadUuidFromUrl)
      return
    }
  }, [])

  const [sendMessage] = useMutation<SendAgentMessageMutation, SendAgentMessageMutationVariables>(
    SEND_AGENT_MESSAGE
  )

  const { data: messageHistoryData, refetch: refetchMessageHistoryData } = useQuery<
    GetMessageHistoriesQuery,
    GetMessageHistoriesQueryVariables
  >(GET_MESSAGE_HISTORIES, {
    // Only execute the query if the thread_uuid exists.
    skip: !threadUuid,
    variables: {
      uuid: threadUuid,
    },
    fetchPolicy: 'network-only',
  })

  const { data: stepHistoryData, refetch: refetchStepHistoryData } = useQuery<
    GetStepHistoriesQuery,
    GetStepHistoriesQueryVariables
  >(GET_STEP_HISTORIES, {
    // Only execute the query if the thread_uuid exists.
    skip: !threadUuid,
    variables: {
      uuid: threadUuid,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!stepHistoryData?.getStepHistories) {
      return
    }

    setSteps(stepHistoryData.getStepHistories)
  }, [stepHistoryData])

  useEffect(() => {
    const messageHistory = messageHistoryData?.getMessageHistories || []
    if (messageHistory.length === 0) {
      return
    }

    setMessages(messageHistory)
  }, [messageHistoryData])

  // monitor the queue and send messages
  useEffect(() => {
    // Define an asynchronous function within the useEffect
    const processMessageQueue = async () => {
      if (messageQueue.length === 0) {
        return
      }

      setIsQuerying(true)
      const message = messageQueue[0]

      addMessage({
        message: [{ type: 'text', value: message }],
        actor: ActorEnum.User,
        createdAt: new Date(),
        createdAtMilli: new Date().getTime(),
        id: null,
        updatedAt: new Date(),
        uuid: '',
      })

      try {
        const { data } = await sendMessage({
          variables: {
            agentUuid: agent.uuid,
            chatId: chatId,
            enableStreaming: true,
            threadUuid,
            message,
          },
        })

        if (data.sendAgentMessage.errors.length) {
          window.toastr.error('Something went wrong. Please try again later.')
        }

        const newThreadUuid = data.sendAgentMessage.threadUuid
        if (newThreadUuid && newThreadUuid !== threadUuid) {
          setThreadUuid(data.sendAgentMessage.threadUuid)

          // update the url
          const searchParams = new URLSearchParams(location.search)
          searchParams.set('thread_uuid', newThreadUuid)
          history.replace({ search: searchParams.toString() })
        }

        await refetchMessageHistoryData()
        await refetchStepHistoryData()
      } catch (error) {
        console.error('Failed to process message:', error)
        // Handle any other errors here
      } finally {
        clearMessageQueue()
        setIsQuerying(false)
      }
    }

    // Call the async function
    processMessageQueue()
  }, [messageQueue])

  return (
    <div>
      <ThreadHistory />
      <AgentHeader />
      {!(threadUuidFromUrl || threadUuid || isQuerying) ? (
        <div className="mt-10 px-10">
          <AgentIntro />
          <div className="flex flex-col space-y-8">
            <div className="bg-gray-100 p-5 rounded-xl shadow">
              <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Working with you
                </h3>
                <div className="mt-3 flex sm:ml-4 sm:mt-0">
                  <button
                    type="button"
                    onClick={openHistory}
                    className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    <QueueListIcon className="h-4 w-4 mr-2" /> History
                  </button>
                </div>
              </div>

              <AgentWorkWithYou />
            </div>
            <div className="bg-gray-100 p-5 rounded-xl shadow">
              <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Working for you</h3>
                <div className="mt-3 flex sm:ml-4 sm:mt-0"></div>
              </div>

              <AgentWorkForYou />
            </div>
          </div>
        </div>
      ) : (
        <Chat />
      )}
    </div>
  )
}

export default AgentApp
