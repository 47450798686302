import React from 'react'
import StepList from './Viewer/StepList'
import Walkthrough from '../../../App/models/Walkthrough'
import useNormalizedSteps from '../../hooks/useNormalizedSteps'

interface Props {
  walkthrough: Walkthrough
}

const WalkthroughContent: React.FC<Props> = ({ walkthrough }) => {
  const steps = useNormalizedSteps(walkthrough)
  return (
    <div className="rounded-lg relative p-4 md:p-8">
      <StepList canEditWalkthrough={walkthrough.canEdit} steps={steps} />
    </div>
  )
}

export default WalkthroughContent
