import React from 'react'
import { Chunk } from '../../../Walkthrough/hooks/useRRWebEvents'
import Walkthrough from '../../../App/models/Walkthrough'
import Thumbnail from './components/Thumbnail'
import { Player } from '@data-driven/components'

import '@data-driven/components/dist/index.css'
import PlayerEventTracking from '../PlayerEventTracking'

type PlayerProps = {
  chunks: Chunk[]
  walkthrough: Walkthrough
  maxHeight?: number
}

const SinglePlayer: React.FC<PlayerProps> = ({ chunks, walkthrough, maxHeight }) => {
  return (
    <Player
      audio={walkthrough.audioUrl}
      duration={walkthrough.meta.duration}
      thumbnail={(loading) => <Thumbnail loading={loading} walkthrough={walkthrough} />}
      chunks={chunks}
      rrwebScreenSize={{
        width: walkthrough?.steps[0]?.event?.data?.width,
        height: walkthrough?.steps[0]?.event?.data?.height,
      }}
      maxHeight={maxHeight}
      steps={walkthrough.steps}
    >
      <PlayerEventTracking walkthrough={walkthrough} eventPrefix="walkthrough" />
    </Player>
  )
}

export default SinglePlayer
