import CheckMarkStepIcon, { CheckMarkLoader } from '../../../../../../icons/CheckMarkStepIcon'
import React from 'react'
import { clsx } from 'clsx'
import useNavigationContext from '../../../../../../../views/Walkthrough/modules/PlaygroundContext/hooks/useNavigationContext'
import useContentContext from '../../../../../../../views/Walkthrough/modules/PlaygroundContext/hooks/useContentContext'

const CheckMark = () => {
  const { chunks } = useContentContext()
  const { step } = useNavigationContext()

  const color = step.completed ? 'text-[#EA6A29]' : 'text-[#1B72F2]'
  return (
    <div className={clsx('transition-colors', color)}>
      {chunks[step.chunkIndex].loading ? <CheckMarkLoader /> : <CheckMarkStepIcon />}
    </div>
  )
}

export default CheckMark
