import Walkthrough from '../../App/models/Walkthrough'
import useUpdateWalkthroughMutation from './useUpdateWalkthroughMutation'
import { useCallback } from 'react'

const normalizeStep = (step: any, meta: any) => ({
  ...step,
  event: {
    ...step.event,
    meta: {
      ...step.event.meta,
      title: meta.title,
      description: meta.description,
      visible: meta.visible,
      synthesis: meta.synthesis,
    },
  },
})

const useUpdateWalkthrough = ({ id, ...walkthrough }: Walkthrough) => {
  const [updateWalkthrough] = useUpdateWalkthroughMutation()

  return useCallback(
    async (values: any) => {
      const { name, description, liveLearning, labs, audioEnabled, synthesis } = values

      const steps = values.steps.map((step: any, index: number) =>
        normalizeStep(walkthrough.steps[index], step)
      )

      await updateWalkthrough({
        variables: { id, name, description, liveLearning, labs, steps, audioEnabled, synthesis },
      })
    },
    [id]
  )
}

export default useUpdateWalkthrough
