import React from 'react'
import IconButton from '../../../IconButton'
import CloseIcon from '../../../icons/CloseIcon'

interface Props {
  onClick: () => void
}

const CloseButton: React.FC<Props> = ({ onClick }) => (
  <IconButton onClick={onClick}>
    <CloseIcon />
  </IconButton>
)

export default CloseButton
