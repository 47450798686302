import Walkthrough, { StepTypes } from '../../App/models/Walkthrough'
import { cloudinary, getPublicId } from './cloudinary'
import { fill } from '@cloudinary/url-gen/actions/resize'

export const getThumbnailPublicId = (walkthrough: Walkthrough) => {
  if (walkthrough && walkthrough.steps) {
    const { steps } = walkthrough.meta

    if (walkthrough.meta.thumbnailPublicId) {
      return `ddu-${process.env.ENV}/${walkthrough.meta.thumbnailPublicId}.png`
    }

    if (steps) {
      const step = steps.find((step) => step.type == StepTypes.cssSelector && step.imageData)
      return step?.imageData?.url ? getPublicId(step.imageData.url) : undefined
    }
  }

  return undefined
}

export const getThumbnailUrl = (publicId: string) =>
  cloudinary.image(publicId).resize(fill(400)).toURL()
