import React from 'react'
import useWindowSize from '../../../../../Labs/hooks/useWindowSize'
import { useLabsContext } from '../../../../context/LabsContext'
import { BEACON_OFFSET, OFFSET_AREA, TOP_BAR_SIZE } from '../../../../constants'
import Description from '../Description'

import './style.scss'

interface Props {
  step: any
  nextStep?: () => void
  imgWidth?: number
  imgHeight?: number
}

const Highlighter = ({ step, imgHeight, imgWidth }: Props) => {
  const { nextStep, completed } = useLabsContext()

  if (completed || !step.elementDimensions) {
    return <></>
  }

  if (step.coordinates && step.screenSize) {
    return (
      <CoordinatesHighlighter
        step={step}
        nextStep={nextStep}
        imgHeight={imgHeight}
        imgWidth={imgWidth}
      />
    )
  }

  return (
    <DimensionsHighlighter
      step={step}
      nextStep={nextStep}
      imgHeight={imgHeight}
      imgWidth={imgWidth}
    />
  )
}

const DimensionsHighlighter = ({ step, nextStep, imgHeight }: Props) => {
  const { height: windowHeight } = useWindowSize()

  const screen = {
    width: step.screenSize?.width || step.elementDimensions?.innerHeight,
    height: step.screenSize?.height || step.elementDimensions?.innerHeight,
  }

  const imageHeight = imgHeight || windowHeight - TOP_BAR_SIZE

  const scale = imageHeight / screen.height
  const scaledOffset = scale * OFFSET_AREA

  const originalWidth = step.elementDimensions.right - step.elementDimensions.left
  const originalHeight = step.elementDimensions.bottom - step.elementDimensions.top

  const width = scale * originalWidth + 2 * scaledOffset
  const height = scale * originalHeight + 2 * scaledOffset

  const top = scale * step.elementDimensions.top - scaledOffset
  const left = scale * step.elementDimensions.left - scaledOffset

  return (
    <React.Fragment>
      <Beacon style={{ width, height, top, left }} nextStep={nextStep} />
      <Description
        position={{
          top: scale * step.elementDimensions.top - originalWidth / 2,
          left: scale * step.elementDimensions.left - originalWidth / 2,
        }}
        description={step.description}
      />
    </React.Fragment>
  )
}

const CoordinatesHighlighter = ({ step, nextStep, imgHeight }: Props) => {
  const { height: windowHeight } = useWindowSize()

  const imageHeight = imgHeight || windowHeight - TOP_BAR_SIZE

  const scale = imageHeight / step.screenSize.height

  const width = 2 * BEACON_OFFSET
  const height = 2 * BEACON_OFFSET

  const top = scale * step.coordinates.y - BEACON_OFFSET
  const left = scale * step.coordinates.x - BEACON_OFFSET

  return (
    <React.Fragment>
      <Beacon style={{ width, height, top, left }} nextStep={nextStep} />
      <Description
        position={{
          top: scale * step.coordinates.y,
          left: scale * step.coordinates.x,
        }}
        description={step.description}
      />
    </React.Fragment>
  )
}

type BeaconProps = {
  style: {
    width: number
    height: number
    top: number
    left: number
  }
  nextStep: () => void
}

const Beacon: React.FC<BeaconProps> = ({ style, nextStep }) => {
  return (
    <div
      className="absolute flex justify-center items-center cursor-pointer"
      style={style}
      onClick={nextStep}
    >
      <div className="flowmo-beacon" />
    </div>
  )
}

export default Highlighter
