import React from 'react'
import PageHeader from '../../components/PageHeader'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { CREATE_AGENT_MUTATION } from '../../graphql/queries/agent'
import Spin from '../../../../components/common/ui/Spin'
import DynamicForm from '../../components/DynamicForm'
const CreateAgent = () => {
  const formRef = React.useRef<{ submitForm: () => Promise<void> }>(null)
  const history = useHistory()
  const pages = [{ title: 'Agents', to: '/agents' }, { title: 'Create new agent' }]

  const [isSaving, setIsSaving] = React.useState(false)
  const [createAgent] = useMutation(CREATE_AGENT_MUTATION)

  const handleSave = async ({
    firstName,
    jobTitle,
    shortDescription,
    description,
    primer,
    backend,
  }: {
    firstName: string
    jobTitle: string
    shortDescription: string
    description: string
    primer: string
    backend: string
  }) => {
    setIsSaving(true)

    try {
      const { data } = await createAgent({
        variables: {
          firstName: firstName.trim(),
          jobTitle: jobTitle.trim(),
          shortDescription: shortDescription.trim(),
          description: description.trim(),
          primer: primer.trim(),
          backend: backend.trim(),
        },
      })
      window.toastr.success('Agent added successfully')
      history.push('/agent/edit/' + data['createAgent']['agent']['uuid'])
    } catch (error) {
      window.toastr.error('Error adding agent')
      history.push('/agents')
    }
    setIsSaving(false)
  }

  const agentDetailsFormData = [
    {
      name: 'firstName',
      label: 'First Name',
      type: 'string',
      required: true,
      defaultValue: '',
    },
    {
      name: 'jobTitle',
      label: 'Job Title',
      type: 'string',
      required: true,
      defaultValue: '',
    },
    {
      name: 'shortDescription',
      label: 'Short Description',
      type: 'string',
      required: true,
      defaultValue: '',
    },
    {
      name: 'description',
      label: 'Description',
      type: 'textarea',
      required: true,
      defaultValue: '',
    },
    {
      name: 'primer',
      label: 'Primer',
      type: 'textarea',
      required: true,
      defaultValue:
        "You are an expert in the use the Looker Business Intelligence tool. You have read al the documentation for all the Looker versions. You have read all the community posts that answer questions from the community. You are a LookML expert that can read and interpret LookML code. I don't want you to respond to questions that aren't about Looker.",
    },
    {
      name: 'backend',
      label: 'Backend',
      type: 'select',
      required: true,
      options: [
        { label: 'ChatGPT', value: 'chatgpt' },
        { label: 'Gemini', value: 'gemini' },
      ],
      defaultValue: 'chatgpt',
    },
  ]

  return (
    <React.Fragment>
      <PageHeader title="Create Agent" breadCrumbs={pages} />
      <div className="max-w-2xl">
        <DynamicForm ref={formRef} formData={agentDetailsFormData} handleSubmit={handleSave} />
        <button
          type="submit"
          onClick={() => formRef.current?.submitForm()}
          className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-flowmo-blue-700 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-flowmo-blue-800"
          disabled={isSaving}
        >
          {isSaving ? <Spin /> : 'Create'}
        </button>
      </div>
    </React.Fragment>
  )
}

export default CreateAgent
