import React from 'react'
import { useQuery } from '@apollo/client'
import {
  WalkthroughsData,
  CreatedWalkthroughsVars,
  CREATED_WALKTHROUGHS_QUERY,
} from '../../../../App/graphql/queries/walkthrough'
import Spin from '../../../../common/ui/Spin'
import Pager from '../../Pager'
import { DocumentNode } from 'graphql'
import useQueryParams from './useQueryParams'
import EmptyState from '../../EmptyState'
import { useHistory, useLocation } from 'react-router-dom'
import List from './List'
import FilterPanel from '../../../../../components/common/components/FilterPanel'

const defaultSortOptions = [
  { label: 'Newest', sortBy: 'created_at', sortDirection: 'DESC' },
  { label: 'Oldest', sortBy: 'created_at', sortDirection: 'ASC' },
  { label: 'Name: A to Z', sortBy: 'name', sortDirection: 'ASC' },
  { label: 'Name: Z to A', sortBy: 'name', sortDirection: 'DESC' },
]

interface Props {
  query?: DocumentNode
  emptyState: {
    title?: string
    description: any
    actions?: React.ReactElement
  }
  defaultSortBy?: {
    label: string
    sortBy: string
    sortDirection: string
  }
  showHeader?: boolean
}

const Content: React.FC<Props> = ({ query, emptyState, defaultSortBy, showHeader = true }) => {
  const { page, perPage, sortDirection, sortBy, contentType } = useQueryParams(
    defaultSortBy
      ? defaultSortBy
      : {
          label: 'Newest',
          sortBy: 'created_at',
          sortDirection: 'DESC',
        }
  )
  const history = useHistory()
  const location = useLocation()

  const wrongPageHandler = () => {
    const queryParams = new URLSearchParams(location.search)

    queryParams.delete('page')
    queryParams.set('page', String(1))
    history.replace({ search: queryParams.toString() })
  }

  const { data, loading } = useQuery<WalkthroughsData, CreatedWalkthroughsVars>(
    query || CREATED_WALKTHROUGHS_QUERY,
    {
      skip: !query,
      variables: {
        page,
        perPage,
        sortBy,
        sortDirection,
        contentType: contentType || null,
      },
    }
  )

  const { data: skillSets } = useQuery<WalkthroughsData, CreatedWalkthroughsVars>(
    query || CREATED_WALKTHROUGHS_QUERY,
    {
      skip: !query,
      variables: {
        sortDirection: 'DESC',
        sortBy: 'created_at',
        contentType: 'SkillSet',
      },
    }
  )

  const walkthroughs = data && data.items
  const skillSetList = skillSets && skillSets.items
  const totalCount = data && data.total.count
  const hasItems = !!data?.items?.length

  const savedSearch = location.pathname.split('/').pop()
  const filters = [
    savedSearch === 'assigned_to_me'
      ? {
          name: 'contentType',
          label: 'Completion Status',
          options: [
            { value: 'Complete', label: 'Complete' },
            { value: 'Incomplete', label: 'Incomplete' },
          ],
        }
      : {
          name: 'contentType',
          label: 'Content Type',
          options: [
            { value: 'Walkthrough', label: 'Walkthrough' },
            { value: 'SkillSet', label: 'Skill Set' },
          ],
        },
  ]

  const sortOptions = [
    savedSearch === 'assigned_to_me' && {
      label: 'Recently assigned',
      sortBy: 'recent_assigned_content',
      sortDirection: 'DESC',
    },
    savedSearch === 'recently_viewed' && {
      label: 'Last viewed',
      sortBy: 'recent_content',
      sortDirection: 'DESC',
    },
    ...defaultSortOptions,
  ].filter(Boolean)

  return (
    <>
      {showHeader && (
        <div className="pt-10">
          <FilterPanel sortOptions={sortOptions} filters={filters} />
        </div>
      )}
      <div className="m-auto mt-10 px-5 max-w-7xl">
        <>
          {loading && <Spin className="h-20 w-20 text-ddu-blue m-auto" />}

          {!loading && !hasItems && page >= 2 && wrongPageHandler()}

          {!loading && !hasItems && <EmptyState {...emptyState} />}

          {!loading && hasItems && (
            <>
              <List walks={walkthroughs} skillSets={skillSetList} />
              <Pager total={totalCount} perPage={perPage} page={page} />
            </>
          )}
        </>
      </div>
    </>
  )
}

export default Content
