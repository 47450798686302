import React, { useState } from 'react'
import CardContainer from './Container'
import CardThumbnail from './Thumbnail'
import CardContent from './Content'
import CardTitle from './Title'
import CardWrapper from './Wrapper'
import CardMenu from '../../../../common/components/Menu'
import ShareMenuItem from '../../../../common/components/Menu/MenuItem/ShareItem'
import MoveMenuItem from '../../../../common/components/Menu/MenuItem/MoveItem'
import DeleteMenuItem from '../../../../common/components/Menu/MenuItem/DeleteItem'
import client from '../../../../common/ApolloClient'
import SkillSet from '../../../../App/models/SkillSet'
import { getThumbnailPublicId, getThumbnailUrl } from '../../../../Walkthrough/utils/walkthrough'
import useDeleteSkillSetMutation from '../../../hooks/useDeleteSkillSetMutation'
import useMoveSkillSetMutation, { DestinationType } from '../../../hooks/useMoveSkillSetMutation'
import ConfirmDialog from '../../../../Walkthrough/ui/ConfirmDialog'
import ShareDialog from '../../../../Walkthrough/ui/ShareDialog'
import { pluralizeWord } from '../../../../common/utils/pluralize'
import { Link, useHistory } from 'react-router-dom'
import { htmlStrip } from '../../../../common/utils/htmlStrip'
import AddToAgentItem from '../../../../../components/common/components/Menu/MenuItem/AddToAgentItem'

interface Props {
  skillSet: SkillSet
}

const SkillSetCard: React.FC<Props> = ({ skillSet }) => {
  let thumbnailUrl

  const history = useHistory()

  const [showShareModal, setShowShareModal] = useState(false)
  const toggleShareModal = () => setShowShareModal(!showShareModal)

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal)

  const [deleteSkillSet] = useDeleteSkillSetMutation(skillSet.id)
  const [moveSkillSet] = useMoveSkillSetMutation()

  const onDeleteAccept = () => {
    toggleDeleteModal()
    deleteSkillSet().then(() => {
      client.resetStore()
      window.toastr.success('The Skill Set was deleted successfully')
    })
  }

  const onMove = (variables: DestinationType) => {
    moveSkillSet({
      variables: {
        id: skillSet.id,
        ...variables,
      },
    }).then(({ data }) => {
      if (data.skillSet.isInMyLibrary) {
        window.toastr.success('The Skill Set was moved successfully to your library')
      } else {
        window.toastr.success('The Skill Set was moved successfully to organization library')
      }
      client.resetStore()
    })
  }

  if (skillSet.walkthroughs[0]) {
    const publicId = getThumbnailPublicId(skillSet.walkthroughs[0])
    thumbnailUrl = publicId && getThumbnailUrl(publicId)
  }

  const handleAddToAgent = () => {
    history.push(`/skill_set/${skillSet.uuid}/add_to_agent`)
  }

  const href = `/skill_set/${skillSet.uuid}`

  const menuItems = [
    <AddToAgentItem onClick={handleAddToAgent} key="add_to_agent" />,
    <ShareMenuItem onClick={toggleShareModal} key="share" />,
    <DeleteMenuItem onClick={toggleDeleteModal} key="delete" />,
  ]

  if (skillSet.canMove) {
    menuItems.splice(1, 0, <MoveMenuItem onMove={onMove} key="move" subject={skillSet} />)
  }

  const cardTitle = skillSet.canEdit ? (
    <div className="flex w-full">
      <CardTitle>{htmlStrip(skillSet.name)}</CardTitle>
      <CardMenu key={skillSet.id} items={menuItems} />
    </div>
  ) : (
    <CardTitle>{htmlStrip(skillSet.name)}</CardTitle>
  )

  return (
    <>
      {showShareModal && (
        <ShareDialog onClose={toggleShareModal} object={skillSet} objectType="skillSet" />
      )}

      <ConfirmDialog
        title="Delete this Skill Set?"
        description={
          skillSet.walkthroughs.length
            ? `Are you sure you want to delete this Skill Set? The ${
                skillSet.walkthroughs.length > 1 ? skillSet.walkthroughs.length : ''
              } ${pluralizeWord(
                'Walkthrough',
                skillSet.walkthroughs.length
              )} in this Skill Set will also be deleted`
            : 'Are you sure you want to delete this Skill Set?'
        }
        onAccept={onDeleteAccept}
        onCancel={toggleDeleteModal}
        actionLabel="Delete"
        cancelLabel="Cancel"
        open={showDeleteModal}
        close={toggleDeleteModal}
      />

      <CardWrapper>
        <Link to={href}>
          <CardContainer className={'absolute left-5 top-4 bottom-0 pointer-events-none'}>
            <CardThumbnail url={thumbnailUrl} />
          </CardContainer>
          <CardContainer className={'absolute left-3 top-2 bottom-0 pointer-events-none'}>
            <CardThumbnail url={thumbnailUrl} />
          </CardContainer>
          <CardContainer className={'relative'}>
            <CardThumbnail url={thumbnailUrl} />
            <CardContent>{cardTitle}</CardContent>
          </CardContainer>
        </Link>
      </CardWrapper>
    </>
  )
}

export default SkillSetCard
