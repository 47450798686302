import React, { useState } from 'react'
import Walkthrough from '../../../../../App/models/Walkthrough'
import { useMutation } from '@apollo/client'
import { CREATE_LEARNING_ACTIVITY } from '../../../../../App/graphql/queries/learning_activity'
import {
  LearningActivityActionType,
  LearningActivityObjectType,
} from '../../../../../App/models/LearningActivity'
import { useWidget } from './hooks/useWidget'
import { Context, PlaygroundContextType } from './context'
import { useContent } from './hooks/useContent'
import { useNavigation } from './hooks/useNavigation'
import useRRWebEvents from '../../../../../Walkthrough/hooks/useRRWebEvents'
import ProgressLoader from '../../../../../Walkthrough/ui/ProgressLoader'
import { NotFound } from '@data-driven/components'

interface Props {
  walkthrough: Walkthrough
  usePublicEndpoint?: boolean
  children?: React.ReactNode
}

const PlaygroundProvider: React.FC<Props> = ({ children, walkthrough, usePublicEndpoint }) => {
  const chunks = useRRWebEvents(walkthrough)
  const widget = useWidget()

  const [createLearningActivity] = useMutation(CREATE_LEARNING_ACTIVITY)
  const complete = () => {
    !usePublicEndpoint &&
      createLearningActivity({
        variables: {
          objectId: walkthrough.id,
          objectType: LearningActivityObjectType.walkthrough,
          action: LearningActivityActionType.completed,
        },
      })

    widget.setMinimized(false)
  }

  const content = useContent(walkthrough, chunks)
  const navigation = useNavigation(content.steps, complete)
  const [element, setElement] = useState(null)

  const global_context: PlaygroundContextType = {
    widget,
    navigation,
    content,
    element,
    setElement,
  }

  if (chunks.length === 0) {
    return <NotFound message="Data is not found" dotsEnabled className="min-h-[calc(100vh-52px)]" />
  }

  return chunks[0].loading ? (
    <ProgressLoader chunk={chunks[0]} />
  ) : (
    <Context.Provider value={global_context}>{children}</Context.Provider>
  )
}

export default PlaygroundProvider
