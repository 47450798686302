import React, { useState } from 'react'
import StarIcon from '../../../../icons/StarIcon'
import { clsx } from 'clsx'

interface Props {
  onChange: (value: number) => void
  value: number
}

const ratings = Array.from({ length: 5 }, (v, i) => i + 1)

const Rate: React.FC<Props> = ({ onChange, value }) => {
  const [hoverValue, setHoverValue] = useState<number>()

  const handleChange = (value: number) => () => onChange(value)
  const handleMouseEnter = (value: number) => () => setHoverValue(value)
  const handleMouseLeave = () => setHoverValue(null)

  return (
    <div className="w-full m-auto flex justify-center items-center space-x-2 my-4">
      {ratings.map((ratingValue) => (
        <React.Fragment key={ratingValue}>
          <StarIcon
            className={clsx(
              ratingValue <= (hoverValue || value) && 'fill-flowmo-blue-600',
              'cursor-pointer transition-colors '
            )}
            onMouseEnter={handleMouseEnter(ratingValue)}
            onMouseLeave={handleMouseLeave}
            onClick={handleChange(ratingValue)}
          />
        </React.Fragment>
      ))}
    </div>
  )
}

export default Rate
