import React from 'react'
import SkillSet from '../../App/models/SkillSet'
import { htmlStrip } from '../../common/utils/htmlStrip'
import { createdByFormatter } from '../../common/utils/createdByFormatter'
import { createdAtFormatter } from '../../common/utils/createdAtFormatter'

interface Props {
  skillSet: SkillSet
}

const PublicSkillSetHeader: React.FC<Props> = ({ skillSet }) => {
  return (
    <div>
      <div className="bg-white border-b">
        <div className="max-w-7xl mx-auto flex flex-col py-4 px-8">
          <div className="break-all py-2.5 break-words rounded-md xl:text-2xl lg:text-xl text-lg leading-[25px] font-semibold text-[#2C2C2C]">
            {htmlStrip(skillSet.name)}
          </div>

          <div className="flex space-x-3 items-center text-gray-400 text-md">
            <div>
              <span>by {createdByFormatter(true, skillSet.createdBy)}</span>
            </div>

            <div className="h-2 w-2 bg-gray-400 inline-block rounded-full"></div>

            <div>
              <span>{skillSet.walkthroughs.length} walkthroughs</span>
            </div>

            <div className="h-2 w-2 bg-gray-400 inline-block rounded-full"></div>

            <div>
              <span>{createdAtFormatter(new Date(skillSet.createdAt))}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublicSkillSetHeader
