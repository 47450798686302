import { useQuery } from '@apollo/client'
import {
  GET_SKILL_SET_QUERY,
  SkillSetData,
  SkillSetVars,
} from '../../App/graphql/queries/skill_set'

const useSkillSetQuery = (uuid: string) => {
  const props = useQuery<SkillSetData, SkillSetVars>(GET_SKILL_SET_QUERY, {
    variables: { uuid },
  })

  return { ...props, data: props.data?.skillSet }
}

export default useSkillSetQuery
