import React from 'react'
import EventListener from '../EventListener'
import ElementStyle from '../ElementStyle'
import ChevronScroll from '../../components/ChevronScroll'
import { usePlaygroundContext } from '../PlaygroundContext/context'
import useNavigationContext from '../PlaygroundContext/hooks/useNavigationContext'
import Position from '../Position'
import Highlighter from '../../components/Highlighter'
import useWindowSize from '../../../../hooks/useWindowSize'
import BoundedBox from '../../components/BoundedBox'

const generateSelector = (context: any) => {
  let pathSelector

  if (context == 'null') {
    return undefined
  }
  // call getIndex function
  const index = getIndex(context)

  while (context.tagName) {
    // selector path
    pathSelector = context.localName + (pathSelector ? '>' + pathSelector : '')
    context = context.parentNode
  }
  // selector path for nth of type
  pathSelector = pathSelector + `:nth-of-type(${index})`
  return pathSelector
}

const getIndex = (node: any) => {
  let i = 1
  const tagName = node.tagName

  while (node.previousSibling) {
    node = node.previousSibling
    if (node.nodeType === 1 && tagName.toLowerCase() == node.tagName.toLowerCase()) {
      i++
    }
  }
  return i
}

const ElementLayer = () => {
  const { element } = usePlaygroundContext()
  const { completed, index } = useNavigationContext()
  const { width, height } = useWindowSize()

  if (!element || completed) {
    return <></>
  }

  return (
    <>
      <Position element={element} key={`${index} ${width} ${height} ${generateSelector(element)}`}>
        {({ position }) => (
          <>
            <BoundedBox element={element} position={position} />
            <Highlighter element={element} position={position} />
            <ChevronScroll element={element} />
          </>
        )}
      </Position>
      <EventListener element={element} />
      <ElementStyle element={element} />
    </>
  )
}

export default ElementLayer
