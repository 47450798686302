import React from 'react'
import { HandThumbDownIcon, HandThumbUpIcon, UserIcon } from '@heroicons/react/24/solid'
import { clsx } from 'clsx'
import { MESSAGE_FEEDBACK } from '../../graphql/queries/agent'
import { useMutation } from '@apollo/client'
import {
  MessageFeedbackMutation,
  MessageFeedbackMutationVariables,
  MessageHistoryFieldsFragment,
} from 'app/javascript/components/graphql'

import 'highlight.js/styles/github.css'
import './messageStyle.css'
import { getRelativeTimeString } from '../../../../components/common/utils/dateDisplay'
import { useChatState } from '../../pages/app/OneAgent/AgentProvider'
import AgentImage from '../AgentImage'
import MessageBody from './MessageBody'

interface MessageProps {
  message: MessageHistoryFieldsFragment
  showVoting?: boolean
}

const Message = ({ message, showVoting = true }: MessageProps) => {
  const { agent } = useChatState()
  const [isThumbsUp, setIsThumbsUp] = React.useState<boolean>(null)
  const [MessageFeedback] = useMutation<MessageFeedbackMutation, MessageFeedbackMutationVariables>(
    MESSAGE_FEEDBACK
  )

  const agentUuid = agent.uuid

  const handleThumbsUp = () => {
    setIsThumbsUp(true)
    MessageFeedback({
      variables: {
        agentUuid,
        messageUuid: message.uuid,
        feedback: 'thumbs_up',
      },
    })
  }

  const handleThumbsDown = () => {
    setIsThumbsUp(false)
    MessageFeedback({
      variables: {
        agentUuid,
        messageUuid: message.uuid,
        feedback: 'thumbs_down',
      },
    })
  }

  const actor = message.actor.toLowerCase()
  return (
    <li className="relative flex gap-x-4">
      <div className="">
        <div className="rounded-full h-8 w-8 items-center justify-center flex bg-gray-200 mt-3 text-white shadow-sm ">
          {actor === 'system' ? (
            <AgentImage agent={agent} className="h-8 w-8 rounded-full" />
          ) : (
            <UserIcon className="h-5 w-5 text-gray-500" />
          )}
        </div>
      </div>

      <div className="bg-white flex-grow rounded-md p-3 ring-1 ring-inset ring-gray-300/50">
        <div className="flex justify-between gap-x-4">
          <div className="py-0.5 text-xs leading-5 text-gray-500">
            {actor === 'system' ? (
              <div className="flex flex-row space-x-2">
                <span className="font-medium text-gray-900">{agent.firstName}</span>
                <div className="">said</div>
              </div>
            ) : (
              <>
                <span className="font-medium text-gray-900">you</span> asked
              </>
            )}
          </div>

          {message.createdAt && (
            <div className="">
              <span className="text-xs text-gray-500">
                {getRelativeTimeString(message.createdAt)}
              </span>
            </div>
          )}
        </div>
        <MessageBody message={message} />
      </div>

      {actor === 'system' && showVoting && (
        <div className="absolute -right-12 top-1 flex flex-row space-x-2">
          <HandThumbUpIcon
            onClick={handleThumbsUp}
            className={clsx(
              isThumbsUp === true
                ? 'text-green-600'
                : ' text-gray-400 hover:text-gray-500 cursor-pointer',
              'h-4 w-4'
            )}
          />
          <HandThumbDownIcon
            onClick={handleThumbsDown}
            className={clsx(
              isThumbsUp === false
                ? 'text-red-600'
                : ' text-gray-400 hover:text-gray-500 cursor-pointer',
              'h-4 w-4'
            )}
          />
        </div>
      )}
    </li>
  )
}

export default Message
