import React from 'react'
import { clsx } from 'clsx'
import LeftArrowIcon from '../icons/LeftArrowIcon'

interface Props {
  uuid: string
}

const BackButton: React.FC<Props> = ({ uuid }) => (
  <a href={`/walkthrough/${uuid}`}>
    <button
      className={clsx(
        'px-3 py-1.5 rounded font-inter font-medium text-[13px] leading-[18px]  flex items-center space-x-1.5',
        'bg-[#FFFFFF] text-[#EA6A29] hover:bg-[#FBE6DB] active:bg-[#F3AA85] active:text-[#FFFFFF] transition-colors'
      )}
    >
      <LeftArrowIcon />
      <span>Back to Walkthrough</span>
    </button>
  </a>
)

export default BackButton
