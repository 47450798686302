import React from 'react'
import { clsx } from 'clsx'
import Tooltip from './Tooltip'

interface Props {
  onClick?: () => void
  dataFor?: string
  dataTip?: string
  disabled?: boolean
  className?: string
  children?: React.ReactNode
}

const IconButton: React.FC<Props> = ({
  children,
  onClick,
  dataTip,
  dataFor,
  disabled,
  className = '',
}) => (
  <>
    {dataFor && dataTip && <Tooltip id={dataFor} />}
    <button
      className={clsx(
        'border border-transparent text-[#777D87] rounded-full bg-transparent transition-colors',
        'hover:border-[#E4E7ED] hover:text-[#1B72F2] disabled:text-[#E4E7ED] active:text-[#1B72F2] active:border-[#E4E7ED]',
        'active:bg-[#E4E7ED] disabled:border-transparent disabled:bg-transparent',
        className
      )}
      onClick={onClick}
      disabled={disabled}
      data-tip={dataTip}
      data-for={dataFor}
    >
      {children}
    </button>
  </>
)

export default IconButton
