import React from 'react'
interface HeaderActionButtonProps {
  label?: string
  icon?: React.ReactNode
  onClick?: () => void
}
const HeaderActionButton = ({ label, icon, onClick }: HeaderActionButtonProps) => {
  return (
    <div
      onClick={onClick}
      className="cursor-pointer py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 "
    >
      <div className="flex flex-row items-center">
        {icon}
        {label}
      </div>
    </div>
  )
}

export default HeaderActionButton
