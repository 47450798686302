import React from 'react'

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-col w-screen h-screen bg-gray-100 overflow-hidden relative">
      {children}
    </div>
  )
}

export default Layout
