import IconButton from '../../../IconButton'
import MinimizeIcon from '../../../icons/MinimizeIcon'
import React from 'react'
import useWidgetContext from '../../../../views/Walkthrough/modules/PlaygroundContext/hooks/useWidgetContext'

const MinimizeButton = () => {
  const { setMinimized } = useWidgetContext()
  return (
    <IconButton onClick={() => setMinimized(true)}>
      <MinimizeIcon />
    </IconButton>
  )
}

export default MinimizeButton
