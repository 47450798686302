import React from 'react'

interface Props {
  className?: string
}

const CollapseIcon: React.FC<Props> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="4"
    fill="none"
    viewBox="0 0 8 4"
    className={className}
  >
    <path
      fill="#434E5F"
      fillRule="evenodd"
      d="M.646.146a.5.5 0 01.708 0L4 2.793 6.646.146a.5.5 0 11.708.708l-3 3a.5.5 0 01-.708 0l-3-3a.5.5 0 010-.708z"
      clipRule="evenodd"
    />
  </svg>
)

export default CollapseIcon
