import { createContext, useContext, useMemo } from 'react'
import Walkthrough from '../../../../../App/models/Walkthrough'
import { Chunk } from '../../../../../Walkthrough/hooks/useRRWebEvents'

export interface PlaygroundContextType {
  widget?: {
    minimized: boolean
    setMinimized: (minimized: boolean) => void
    showCloseModal: boolean
    setShowCloseModal: (showCloseModal: boolean) => void
  }
  navigation?: {
    nextStep: () => void
    prevStep: () => void
    step: any
    completed: boolean
    index: number
  }
  content?: {
    walkthrough: Walkthrough
    chunks: Chunk[]
    steps: any
  }
  element?: HTMLElement
  setElement?: (element: HTMLElement) => void
}

const DefaultContextValues = {}

export const Context = createContext(DefaultContextValues)

export function usePlaygroundContext() {
  const context: PlaygroundContextType = useContext(Context)
  return useMemo(() => {
    return context
  }, [context])
}
