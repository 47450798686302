import { useQuery } from '@apollo/client'
import clsx from 'clsx'
import React, { useState } from 'react'
import { GET_HOMEPAGE_DEMOS } from '../graphql/queries/homepage_demo'
import { HomepageDemosQuery, HomepageDemosQueryVariables } from '../../graphql'

const ProductDemos = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const { data, loading } = useQuery<HomepageDemosQuery, HomepageDemosQueryVariables>(
    GET_HOMEPAGE_DEMOS
  )

  if (loading || !data || !data.homepageDemos || !data.homepageDemos.length) {
    return <></>
  }

  const tabs = data.homepageDemos
  const url = tabs[selectedTab].url
  const title = tabs[selectedTab].title

  return (
    <>
      <div className="mt-10">
        <div className="mx-auto max-w-xl text-center my-20">
          <h2 className="text-lg font-semibold leading-8 tracking-tight text-flowmo-blue-600">
            How it works
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Setup a new teammate in minutes using data connectors and templates
          </p>
        </div>

        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-flowmo-blue-500 focus:ring-flowmo-blue-500 mb-5"
            defaultValue={tabs.find((tab, index) => selectedTab == index).title}
          >
            {tabs.map((tab) => (
              <option key={tab.title}>{tab.title}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-t border-gray-200 mb-5">
            <nav className="-mb-px flex justify-center" aria-label="Tabs">
              {tabs.map((tab, index) => (
                <div
                  key={index}
                  className={clsx(
                    index == selectedTab
                      ? 'border-flowmo-blue-500 text-flowmo-blue-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer',
                    'w-1/4 border-t-2 py-4 px-2 text-left'
                  )}
                  aria-current={index == selectedTab ? 'page' : undefined}
                  onClick={() => setSelectedTab(index)}
                >
                  <h2 className="text-md font-medium">{tab.title}</h2>

                  <p className="mt-2 hidden text-sm leading-6 text-slate-700 md:block pr-5">
                    {tab.description}
                  </p>
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div
        style={{
          position: 'relative',
          paddingBottom: 'calc(54.22437673130194% + 41px)',
          height: '0',
          width: '100%',
        }}
      >
        <iframe
          src={url}
          title={title}
          loading="lazy"
          allowFullScreen={true}
          allow="clipboard-write"
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            colorScheme: 'dark',
          }}
        ></iframe>
      </div>
    </>
  )
}

export default ProductDemos
