import React from 'react'
import Banner from '../../components/Banner'
import Layout from '../../containers/Layout'
import Playground from './Playground'

interface Props {
  uuid: string
  usePublicEndpoint?: boolean
}

const Walkthrough: React.FC<Props> = ({ uuid, usePublicEndpoint }) => {
  return (
    <Layout>
      <Banner uuid={uuid} />
      <Playground uuid={uuid} usePublicEndpoint={usePublicEndpoint} />
    </Layout>
  )
}

export default Walkthrough
