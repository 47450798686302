import React from 'react'

const CheckMarkIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.6895 4.56055C11.9434 4.79492 11.9434 5.20508 11.6895 5.43945L6.68945 10.4395C6.45508 10.6934 6.04492 10.6934 5.81055 10.4395L3.31055 7.93945C3.05664 7.70508 3.05664 7.29492 3.31055 7.06055C3.54492 6.80664 3.95508 6.80664 4.18945 7.06055L6.24023 9.11133L10.8105 4.56055C11.0449 4.30664 11.4551 4.30664 11.6895 4.56055Z"
      fill="white"
    />
  </svg>
)

export default CheckMarkIcon
