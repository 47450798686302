import React from 'react'
import { clsx } from 'clsx'
import ReactHtmlParser from 'react-html-parser'

interface Props {
  collapsed?: boolean
  children?: string
}

const Title: React.FC<Props> = ({ children, collapsed }) => {
  return (
    <div
      className={clsx(
        'font-inter text-[12px] break-all text-[#434E5F] leading-[140%] font-medium',
        collapsed && 'truncate'
      )}
    >
      {ReactHtmlParser(children)}
    </div>
  )
}

export default Title
