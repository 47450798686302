import IconButton from '../../../IconButton'
import React from 'react'
import CloseIcon from '../../../icons/CloseIcon'
import useWidgetContext from '../../../../views/Walkthrough/modules/PlaygroundContext/hooks/useWidgetContext'

const CloseButton = () => {
  const { setShowCloseModal } = useWidgetContext()
  return (
    <IconButton onClick={() => setShowCloseModal(true)}>
      <CloseIcon />
    </IconButton>
  )
}

export default CloseButton
