import React, { useState } from 'react'
import Title from './Title'
import SuccessImage from './SuccessImage'
import Button from '../../../../Button'
import useContentContext from '../../../../../views/Walkthrough/modules/PlaygroundContext/hooks/useContentContext'
import { useMutation } from '@apollo/client'
import { CREATE_REVIEW } from '../../../../../../App/graphql/queries/review'
import Rate from './Rate'
import ReviewContainer from './ReviewContainer'
import Description from '../../Support/Description'

interface Props {
  showSuccess: () => void
}

const MainView: React.FC<Props> = ({ showSuccess }) => {
  const { walkthrough } = useContentContext()

  const [score, setScore] = useState(null)
  const [createUserReview] = useMutation(CREATE_REVIEW)

  if (score && score < 5) {
    return (
      <div className="px-4 mt-3">
        <ReviewContainer completeReview={showSuccess} scoreValue={score} />
      </div>
    )
  }

  const handleRate = (score: number) => {
    setScore(score)
    if (score === 5) {
      createUserReview({
        variables: {
          source: 'institute_labs',
          objectId: walkthrough.id,
          objectType: 'walkthrough',
          score,
        },
      })
    }
  }

  return (
    <div className="flex flex-col items-center mt-5 ">
      <Title>Yay! You completed the walkthrough successfully.</Title>

      <SuccessImage />
      <Description>How was this walkthrough?</Description>
      <Rate onChange={handleRate} value={score} />

      <a href={`/walkthrough/${walkthrough.uuid}`}>
        <Button color="success" className="mt-6">
          Return to Library
        </Button>
      </a>
    </div>
  )
}

export default MainView
