import React from 'react'
import {
  ADD_DATASET_TO_AGENT_MUTATION,
  GET_DATA_SET_QUERY,
  REMOVE_DATASET_FROM_AGENT_MUTATION,
} from '../../../graphql/queries/data_set'
import { useMutation, useQuery } from '@apollo/client'
import { Link, useHistory } from 'react-router-dom'
import {
  AddDataSetToAgentMutation,
  AddDataSetToAgentMutationVariables,
  DataSetQuery,
  DataSetQueryVariables,
  OrganizationAgentsQuery,
  OrganizationAgentsQueryVariables,
  RemoveDataSetFromAgentMutation,
  RemoveDataSetFromAgentMutationVariables,
} from '../../../../../components/graphql'
import Spin from '../../../../../components/common/ui/Spin'
import PageHeader from '../../../components/PageHeader'
import HeaderActionButton from '../../../components/HeaderActionButton'
import { GET_ORGANIZATION_AGENTS_QUERY } from '../../../graphql/queries/agent'
import { Switch } from '@headlessui/react'
import clsx from 'clsx'
import DataSetSample from './DataSetSample'
import { LinkIcon } from '@heroicons/react/24/solid'

interface OneDataSetProps {
  uuid: string
}
const OneDataSet = ({ uuid }: OneDataSetProps) => {
  const history = useHistory()
  const [loadingSwitches, setLoadingSwitches] = React.useState<string[]>([]) // array of agent UUIDs that are currently loading

  const { data, loading } = useQuery<DataSetQuery, DataSetQueryVariables>(GET_DATA_SET_QUERY, {
    variables: { uuid },
    fetchPolicy: 'network-only',
  })

  const [addDataSetToAgent] = useMutation<
    AddDataSetToAgentMutation,
    AddDataSetToAgentMutationVariables
  >(ADD_DATASET_TO_AGENT_MUTATION)
  const [removeDataSetFromAgent] = useMutation<
    RemoveDataSetFromAgentMutation,
    RemoveDataSetFromAgentMutationVariables
  >(REMOVE_DATASET_FROM_AGENT_MUTATION)

  const {
    data: agentsData,
    loading: agentsLoading,
    refetch: refetchAgents,
  } = useQuery<OrganizationAgentsQuery, OrganizationAgentsQueryVariables>(
    GET_ORGANIZATION_AGENTS_QUERY
  )
  const agents = agentsData?.organizationAgents || []

  if (loading) {
    return <Spin />
  }

  if (!loading && !data?.dataSet) {
    window.toastr.error('Data Set not found')
    history.push('/data_sets')
    return null
  }
  const dataSet = data?.dataSet

  const handleCheckboxChange = async (agentUuid: string, isChecked: boolean) => {
    // Add the agentUuid to the loading state
    setLoadingSwitches((prev) => [...prev, agentUuid])

    try {
      if (isChecked) {
        const response = await addDataSetToAgent({
          variables: {
            agentUuid,
            dataSetUuid: uuid,
          },
        })
        if (response.data.addDataSetToAgent.errors.length > 0) {
          window.toastr.error('Error adding walkthrough to agent', 'Error')
        } else {
          window.toastr.success('Successfully added walkthrough to agent', 'Added')
        }
      } else {
        const response = await removeDataSetFromAgent({
          variables: {
            agentUuid,
            dataSetUuid: uuid,
          },
        })
        if (response.data.removeDataSetFromAgent.errors.length > 0) {
          window.toastr.error('Error removing walkthrough to agent', 'Error')
        } else {
          window.toastr.success('Successfully removed the walkthrough to agent', 'Removed')
        }
      }
    } catch (err) {
      window.toastr.error('An unexpected error occurred', 'Error')
    } finally {
      // Remove the agentUuid from the loading state after the operation completes
      setLoadingSwitches((prev) => prev.filter((id) => id !== agentUuid))
      refetchAgents()
    }
  }

  const exploreUrl = dataSet.definition?.exploreUrl

  return (
    <>
      <PageHeader
        title={dataSet.name}
        breadCrumbs={[{ title: 'Data Sets', to: '/data_sets' }, { title: 'One Data Set' }]}
      >
        <div className="flex flex-row space-x-4">
          <Link to={'/data_set/edit/' + uuid}>
            <HeaderActionButton label="Edit" />
          </Link>
        </div>
      </PageHeader>

      <div className="flex flex-col space-y-8 max-w-4xl">
        <div>
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Name</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {dataSet.name}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Description</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {dataSet.description}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Explore</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {exploreUrl && (
                  <a
                    href={exploreUrl}
                    className="hover:underline "
                    target="_blank"
                    rel="noreferrer"
                  >
                    {exploreUrl}
                    <LinkIcon className="h-4 w-4 inline-block ml-1" />
                  </a>
                )}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Build Status</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {dataSet.status}
              </dd>
            </div>
          </dl>
        </div>

        <div>
          <h3 className="text-xl font-semibold mb-5 pb-5 border-b">Sample</h3>
          <DataSetSample dataSet={dataSet} />
        </div>

        <div className="">
          <h3 className="text-xl font-semibold mb-5 pb-5 border-b">Agents</h3>
          <div className="flex flex-col space-y-2">
            {agentsLoading && <Spin />}

            {agents.map((agent) => {
              const enabled = agent.dataSets?.some((wt) => wt.uuid === uuid)
              const isLoading = loadingSwitches.includes(agent.uuid)

              return (
                <div key={agent.uuid} className="w-full p-5 border rounded">
                  <div className="flex flex-row">
                    <div className="flex flex-col flex-grow">
                      <div className="text-lg font-semibold">
                        <Link to={`/agent/${agent.uuid}`}>{agent.label}</Link>
                      </div>
                      <div className="text-md text-gray-800">{agent.description}</div>
                    </div>
                    <div className="justify-center">
                      <Switch
                        checked={enabled}
                        onChange={(checked) => handleCheckboxChange(agent.uuid, checked)}
                        className={clsx(
                          enabled ? 'bg-flowmo-blue-600' : 'bg-gray-200',
                          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-flowmo-blue-600 focus:ring-offset-2'
                        )}
                        disabled={isLoading}
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className={clsx(
                            enabled ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                          )}
                        />
                      </Switch>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default OneDataSet
