import React, { useState } from 'react'
import MainView from './MainView'
import SupportResult from '../../Support/Result'
import Button from '../../../../Button'
import useContentContext from '../../../../../views/Walkthrough/modules/PlaygroundContext/hooks/useContentContext'

const Result = () => {
  const [showSuccess, setShowSuccess] = useState(false)
  const { walkthrough } = useContentContext()
  const handleShowSuccess = () => {
    setShowSuccess(true)
  }

  return showSuccess ? (
    <div className="mt-5">
      <SupportResult>
        <a href={`/walkthrough/${walkthrough.uuid}`}>
          <Button color="success" className="mx-auto mt-20">
            Return to Library
          </Button>
        </a>
      </SupportResult>
    </div>
  ) : (
    <MainView showSuccess={handleShowSuccess} />
  )
}

export default Result
