import React, { useState } from 'react'
import CardContainer from './CardContainer'
import Dialog from '../../../../../../../Dialog'
import { CursorArrowRaysIcon } from '@heroicons/react/24/solid'
import PlayIcon from '../../../../../../../icons/PlayIcon'
import RRWebStepPlayer from './RRWebStepPlayer'
import useContentContext from '../../../../../../../../views/Walkthrough/modules/PlaygroundContext/hooks/useContentContext'
import useNavigationContext from '../../../../../../../../views/Walkthrough/modules/PlaygroundContext/hooks/useNavigationContext'

const PlayButton = () => (
  <div className="flex inset-0 justify-center items-center bg-gray-500 bg-opacity-50 absolute w-[125px] h-[92px] rounded ">
    <PlayIcon />
  </div>
)

interface Props {
  imageExists: boolean
  url: string
  dimensions?: any
}

const VideoCard: React.FC<Props> = ({ url, dimensions, imageExists }) => {
  const [opened, setOpened] = useState(false)
  const toggleOpened = () => setOpened(!opened)
  const { chunks } = useContentContext()
  const { step } = useNavigationContext()
  const events = chunks[step.chunkIndex].events

  return (
    <>
      {opened && <Dialog onClose={toggleOpened}>{events && <RRWebStepPlayer />}</Dialog>}

      <CardContainer
        className="cursor-pointer overflow-hidden !bg-transparent"
        onClick={toggleOpened}
      >
        {imageExists ? (
          <img
            src={url}
            alt="Step Screenshot"
            className={`h-full max-w-fit scale-125 ${
              dimensions && dimensions.left > 900 ? '-translate-x-16' : ''
            }`}
          />
        ) : (
          <div className="flex items-center justify-center h-full">
            <CursorArrowRaysIcon className="items-center h-20 w-20 text-flowmo-blue-300/30" />
          </div>
        )}

        <PlayButton />
      </CardContainer>
    </>
  )
}

export default VideoCard
