import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Filter as FilterType } from '../../types'
import React from 'react'
import { ArrayParam, useQueryParam } from 'use-query-params'
import { clsx } from 'clsx'

interface Props {
  filter: FilterType
}

const Filter: React.FC<Props> = ({ filter }) => {
  const [options = [], setOptions] = useQueryParam(filter.name, ArrayParam)

  return (
    <Disclosure as="div" className="border-t border-gray-200 px-4 py-6">
      {({ open }) => (
        <>
          <h3 className="-mx-2 -my-3 flow-root">
            <Disclosure.Button className="px-2 py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400">
              <span className="font-medium text-gray-900">{filter.label}</span>
              <span className="ml-6 flex items-center">
                <ChevronDownIcon
                  className={clsx(open ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transform')}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </h3>
          <Disclosure.Panel className="pt-6">
            <div className="space-y-6">
              {filter.options.map((filterOption, index) => (
                <div key={index} className="flex items-center">
                  <input
                    id={`filter-mobile-${filter.name}-${index}`}
                    type="checkbox"
                    defaultChecked={options.some((option) => option === filterOption.value)}
                    className="h-4 w-4 border-gray-300 rounded text-flowmo-blue-600 focus:ring-flowmo-blue-500"
                    onChange={({ target }) =>
                      setOptions(
                        target.checked
                          ? [...options, filterOption.value]
                          : options.filter((option) => option !== filterOption.value)
                      )
                    }
                  />
                  <label
                    htmlFor={`filter-mobile-${filter.name}-${index}`}
                    className="ml-3 text-sm text-gray-500"
                  >
                    {filterOption.label}
                  </label>
                </div>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default Filter
