import React from 'react'
import IconButton from '../../../../../../IconButton'
import LeftNavigationArrowIcon from '../../../../../../icons/LeftNavigationArrowIcon'
import RightNavigationArrowIcon from '../../../../../../icons/RightNavigationArrowIcon'
import useNavigationContext from '../../../../../../../views/Walkthrough/modules/PlaygroundContext/hooks/useNavigationContext'

const Navigation = () => {
  const { step, nextStep, prevStep } = useNavigationContext()
  return (
    <div className="flex items-center">
      <IconButton disabled={step.isFirstStep} onClick={prevStep}>
        <LeftNavigationArrowIcon />
      </IconButton>
      <IconButton onClick={nextStep}>
        <RightNavigationArrowIcon />
      </IconButton>
    </div>
  )
}

export default Navigation
