import React from 'react'
import Walkthrough from '../../../../../../App/models/Walkthrough'
import { launcherOptions } from '../../Launcher'

interface Props {
  walkthrough: Walkthrough
}

const CompletedVideo = ({ walkthrough }: Props) => (
  <div className="relative w-full h-full">
    <div
      className="w-full h-full flex"
      style={{
        background:
          'linear-gradient(90deg, #214A86 0%, #305B9A 25.52%, rgba(46, 46, 46, 0.71) 63.44%, rgba(46, 46, 46, 0.71) 100%)',
      }}
    >
      <div className="flex flex-col my-auto ml-[5%] w-[60%]">
        <div className="font-bold text-[23px] text-white">Yay! You watched the video</div>
        <div className="text-[18px] text-white mt-2">Try other learning modes!</div>
        <div className="flex space-x-[20px] mt-[26px] justify-start">
          {launcherOptions.labs.button(`/labs/walkthrough/${walkthrough.uuid}`)}
        </div>
      </div>
    </div>
    <div
      className="w-full h-full absolute top-0 left-0"
      style={{
        zIndex: -1,
        backdropFilter: 'blur(2px)',
      }}
    />
  </div>
)

export default CompletedVideo
