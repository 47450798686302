import React from 'react'
import { Filter } from '../types'
import MobileFilters from './mobile'
import DesktopFilters from './desktop'

interface Props {
  filters: Filter[]
}

const Filters: React.FC<Props> = ({ filters }) => (
  <>
    <MobileFilters filters={filters} />
    <DesktopFilters filters={filters} />
  </>
)

export default Filters
