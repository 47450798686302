import React from 'react'
import CollapseIcon from '../../../../../icons/CollapseIcon'
import { clsx } from 'clsx'

interface Props {
  toggleCollapse: () => void
  collapsed?: boolean
}

const CollapseButton: React.FC<Props> = ({ toggleCollapse, collapsed }) => (
  <div className="cursor-pointer mt-1.5 transition-transform" onClick={toggleCollapse}>
    <CollapseIcon className={clsx('transition-transform', collapsed || 'rotate-180')} />
  </div>
)

export default CollapseButton
