import { Popover } from '@headlessui/react'
import React from 'react'
import { Filter as FilterType } from '../../types'
import Filter from './filter'

interface Props {
  filters: FilterType[]
}

const DesktopFilters: React.FC<Props> = ({ filters }) => {
  return (
    <div className="hidden sm:block">
      <div className="flow-root">
        <Popover.Group className="-mx-4 flex items-center divide-x divide-gray-200">
          {filters.map((filter, index) => (
            <Filter key={index} filter={filter} />
          ))}
        </Popover.Group>
      </div>
    </div>
  )
}

export default DesktopFilters
