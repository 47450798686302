import React from 'react'
import Title from '../Title'
import ResultImage from './ResultImage'

interface Props {
  children: React.ReactNode
}

const SupportResult: React.FC<Props> = ({ children }) => {
  return (
    <div className="flex flex-col items-center">
      <Title>Thank you for your feedback!</Title>
      <ResultImage />
      {children}
    </div>
  )
}

export default SupportResult
