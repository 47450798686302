import React from 'react'
import BackButton from './BackButton'
import FlowMoLogo from './FlowMoLogo'
import VerticalLine from './VerticalLine'

interface Props {
  uuid: string
}

const Banner: React.FC<Props> = ({ uuid }) => {
  return (
    <div className="bg-[#EA6A29] py-3 z-50 border-b-[2px] border-white">
      <div className="w-[1200px] mx-auto flex items-center">
        <BackButton uuid={uuid} />
        <div className="ml-14 flex items-center space-x-5">
          <FlowMoLogo />
          <VerticalLine />
          <div className="font-inter font-semibold text-[16px] leading-[22px] text-white">
            FloMo Labs - safe and secure sanbox environment. Practice your skills without getting
            stuck
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
