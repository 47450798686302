import { gql } from '@apollo/client'

export const GET_CURRENT_USER = gql`
  query CurrentUser {
    currentUser: CurrentUser {
      id
      email
      firstName
      lastName
      privacyPolicyAccepted
      shortName
      longName
      organization {
        id
        name
        restrictByGroupOrRole
        emailNamespace
        isPaid
      }
      roles {
        id
        name
      }
    }
  }
`

export const USERS_QUERY = gql`
  query organizationUsers($organizationId: ID!, $page: Int, $perPage: Int) {
    items: organizationUsers(organizationId: $organizationId, page: $page, perPage: $perPage) {
      id
      email
      firstName
      lastName
      createdAt
      updatedAt
      roles {
        id
        name
      }
      isInvitedPendingRegistration
    }
    total: organizationUsersMeta(organizationId: $organizationId) {
      count
    }
  }
`
export const SEND_INVITES_MUTATION = gql`
  mutation sendInvites($emails: [String!]!) {
    sendInvites(emails: $emails) {
      success
      errors
    }
  }
`

export const RESEND_EMAIL_INVITE_MUTATION = gql`
  mutation resendEmailInvite($id: String!) {
    resendEmailInvite(id: $id) {
      success
      errors
    }
  }
`

export const UPDATE_CURRENT_USER_MUTATION = gql`
  mutation updateCurrentUser(
    $email: String
    $firstName: String
    $lastName: String
    $privacyPolicyAccepted: Boolean
    $password: String
  ) {
    updateCurrentUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      privacyPolicyAccepted: $privacyPolicyAccepted
      password: $password
    ) {
      success
      errors
    }
  }
`
