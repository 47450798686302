import React from 'react'
import { clsx } from 'clsx'

interface Props {
  onSelect: () => void
  children?: React.ReactNode
}

const Option: React.FC<Props> = ({ children, onSelect }) => (
  <div
    className={clsx(
      'px-3 py-2 bg-white border border-[#E4E7ED] rounded font-inter text-[12px] leading-[18px] text-[#1B72F2]',
      'mb-3 cursor-pointer transition-colors hover:border-[#1B72F2] active:text-white active:bg-[#1B72F2]'
    )}
    onClick={onSelect}
  >
    {children}
  </div>
)

export default Option
