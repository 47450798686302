import React from 'react'
import useNavigationContext from '../PlaygroundContext/hooks/useNavigationContext'

interface Props {
  element: HTMLElement
}

const ElementStyle: React.FC<Props> = ({ element }) => {
  const { step } = useNavigationContext()

  React.useEffect(() => {
    if (element) {
      element.style.zIndex = '9999'
    }
  }, [element, step])

  return <></>
}

export default ElementStyle
