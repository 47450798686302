import React from 'react'
import SupportIcon from '../../../icons/SupportIcon'
import Tooltip from '../../../Tooltip'

interface Props {
  onClick: () => void
}

const Background = ({ children }: { children: React.ReactNode }) => (
  <div className="w-[300px] h-[37px] absolute bottom-0 flex items-end justify-center bg-[rgba(248,249,251,0.85)] rounded-b">
    {children}
  </div>
)

const SupportButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Background>
      <Tooltip id="support-tooltip" />
      <div
        data-for="support-tooltip"
        data-tip="Send Feedback"
        onClick={onClick}
        className="text-[#777D87] hover:text-[#1B72F2] cursor-pointer transition-colors"
      >
        <SupportIcon />
      </div>
    </Background>
  )
}

export default SupportButton
