import React, { useState } from 'react'
import { AmazingIcon, BadIcon, GoodIcon, OkayIcon, TerribleIcon } from './icons'
import ScoreContainer from './Container'
import ScoreOption from './Option'

const options = [
  {
    title: 'Terrible',
    icon: <TerribleIcon />,
  },
  {
    title: 'Bad',
    icon: <BadIcon />,
  },
  {
    title: 'Okay',
    icon: <OkayIcon />,
  },
  {
    title: 'Good',
    icon: <GoodIcon />,
  },
  {
    title: 'Amazing',
    icon: <AmazingIcon />,
  },
]

interface Props {
  name: string
  onChange: (event: any) => void
}

const ReviewScore: React.FC<Props> = ({ name, onChange }) => {
  const [value, setValue] = useState(undefined)

  const onClick = (value: number) => () => {
    setValue(value)
    onChange &&
      onChange({
        target: {
          name,
          value,
        },
      })
  }

  return (
    <ScoreContainer>
      {options.map((option, index) => (
        <ScoreOption
          key={index}
          option={option}
          selected={value === index + 1}
          onClick={onClick(index + 1)}
        />
      ))}
    </ScoreContainer>
  )
}

export default ReviewScore
