import React from 'react'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import FlowMoLabsIcon from '../../icons/FlowMoLabsIcon'
import useContentContext from '../../../views/Walkthrough/modules/PlaygroundContext/hooks/useContentContext'
import useNavigationContext from '../../../views/Walkthrough/modules/PlaygroundContext/hooks/useNavigationContext'

const styles = buildStyles({
  trailColor: '#F6A47B',
  pathColor: '#FFFFFF',
})

const percentage = (stepIndex: number, stepCount: number) => {
  return (stepIndex / stepCount) * 100
}

const ProgressBar: React.FC = () => {
  const { steps } = useContentContext()
  const { index, completed } = useNavigationContext()
  const currentStepIndex = completed ? steps.length : index + 1
  return (
    <CircularProgressbarWithChildren
      value={percentage(currentStepIndex, steps.length)}
      styles={styles}
      strokeWidth={4}
    >
      <div className="flex flex-col items-center justify-center">
        <FlowMoLabsIcon />
        <div className="font-inter font-medium text-[#F6A47B] text-[10px] leading-[18px]">
          <span className="text-white">{currentStepIndex}</span> / {steps.length}
        </div>
      </div>
    </CircularProgressbarWithChildren>
  )
}

export default ProgressBar
