import { gql } from '@apollo/client'
import { MESSAGE_HISTORY_FIELDS, STEP_HISTORY_FIELDS } from './fragments'

export const GET_MESSAGE_HISTORIES = gql`
  query getMessageHistories($uuid: String!) {
    getMessageHistories(uuid: $uuid) {
      ...MessageHistoryFields
    }
  }

  ${MESSAGE_HISTORY_FIELDS}
`

export const GET_STEP_HISTORIES = gql`
  query getStepHistories($uuid: String!) {
    getStepHistories(uuid: $uuid) {
      ...StepHistoryFields
    }
  }

  ${STEP_HISTORY_FIELDS}
`

export const GET_MESSAGE_THREADS = gql`
  query getMessageThreads($agentUuid: String!) {
    getMessageThreads(agentUuid: $agentUuid) {
      createdAt
      updatedAt
      uuid
    }
  }
`
