export const popoverModifiers = [
  {
    name: 'flip',
    enabled: false,
  },
  {
    name: 'hide',
    enabled: false,
  },
  {
    name: 'preventOverflow',
    enabled: true,
    options: {
      altAxis: false,
      boundary: document.body,
      altBoundary: true,
      rootBoundary: 'document',
    },
  },
  {
    name: 'offset',
    options: {
      offset: [0, 66 + 8],
    },
  },
]
