import React from 'react'
import Card from './Card'
import Header from './Header'
import Content from './Content'
import useNavigationContext from '../../../../../../views/Walkthrough/modules/PlaygroundContext/hooks/useNavigationContext'

const StepInformation: React.FC = () => {
  const { index } = useNavigationContext()
  return (
    <Card key={index}>
      <Header />
      <Content />
    </Card>
  )
}

export default StepInformation
