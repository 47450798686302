import React from 'react'
import { Filter as FilterType } from '../types'
import { ArrayParam, useQueryParam } from 'use-query-params'

interface Props {
  filter: FilterType
}

const Filter: React.FC<Props> = ({ filter }) => {
  const [options = [], setOptions] = useQueryParam(filter.name, ArrayParam)

  return (
    <>
      {options.map((option) => {
        const fullOption = filter.options.find((fullOption) => fullOption.value === option)
        return (
          <span
            key={`${filter.name}-${fullOption.value}`}
            className="m-1 inline-flex rounded-full border border-gray-200 items-center py-1.5 pl-3 pr-2 text-sm font-medium bg-white text-gray-900"
          >
            <span>{fullOption.label}</span>
            <button
              type="button"
              className="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-full inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500"
              onClick={() => setOptions(options.filter((option) => option !== fullOption.value))}
            >
              <span className="sr-only">Remove filter for {fullOption.label}</span>
              <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
              </svg>
            </button>
          </span>
        )
      })}
    </>
  )
}

export default Filter
