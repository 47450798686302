import React, { useEffect, useState } from 'react'

interface Props {
  element?: HTMLElement
}

const arrow = {
  common: {
    position: 'fixed',
  },
}

const CHEVRON_WIDTH = 40

const ChevronScroll: React.FC<Props> = ({ element }) => {
  const [style, setStyle] = useState(null)
  const onClick = () => element.scrollIntoView({ behavior: 'smooth', block: 'center' })

  useEffect(() => {
    const interval = setInterval(() => {
      const style = getStyle()
      setStyle(style)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [element])

  const getStyle = () => {
    if (element) {
      const rect = element.getBoundingClientRect()
      const innerWidth = element?.ownerDocument?.defaultView?.innerWidth || window.innerWidth
      const innerHeight = element?.ownerDocument?.defaultView?.innerHeight || window.innerHeight

      if (rect.bottom < 0) {
        return {
          ...arrow.common,
          transform: 'rotate(180deg)',
          top: 45,
          left: innerWidth / 2 - CHEVRON_WIDTH + 15,
        }
      } else if (rect.top > innerHeight) {
        return {
          ...arrow.common,
          bottom: 0,
          left: innerWidth / 2 - CHEVRON_WIDTH + 15,
        }
      }
    }
    return null
  }

  return (
    <div
      className="labs-chevron-scroll"
      style={style ? { ...style } : { display: 'none' }}
      onClick={onClick}
    >
      <div className="new-arrows black" />
    </div>
  )
}

export default ChevronScroll
