import React, { useEffect, useState } from 'react'
import './style.scss'
import { clsx } from 'clsx'

interface Props {
  element?: HTMLElement
  position?: DOMRect
}

const OFFSET = 4

const BoundedBox = ({ element, position }: Props) => {
  const [show, setShow] = useState(false)

  const onMouseOver = () => setShow(true)
  const onMouseLeave = () => setShow(false)

  useEffect(() => {
    element?.addEventListener('mouseover', onMouseOver)
    element?.addEventListener('mouseleave', onMouseLeave)

    return () => {
      element?.removeEventListener('mouseover', onMouseOver)
      element?.removeEventListener('mouseleave', onMouseLeave)
    }
  }, [element])

  if (!element || !position) {
    return <></>
  }

  const width = position.width + OFFSET * 2
  const height = position.height + OFFSET * 2
  const top = position.top - OFFSET + 2
  const left = position.left - OFFSET

  return (
    <div
      className={clsx('bounded-box', show ? 'show' : 'hide')}
      style={{ width, height, top, left }}
    />
  )
}

export default BoundedBox
