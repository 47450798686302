import { getCloudinaryUrl } from '../utils/cloudinary'
import Walkthrough, { StepTypes } from '../../App/models/Walkthrough'
import { useMemo } from 'react'

const convertGoTo = ({ type, event, chunkIndex, eventIndex }: any) => {
  const { title, description, linkText, visible = true } = event.meta
  const { href: url } = event.data

  return { type, url, title, description, linkText, chunkIndex, eventIndex, visible }
}

const convertClick = ({ type, event, chunkIndex, eventIndex }: any) => {
  const { title, description, cloudinary, visible = true, coordinates, screenSize } = event.meta
  const imageData = { url: cloudinary ? getCloudinaryUrl(cloudinary.public_id) : null }
  const isIFrame = !!event.uuid
  const elementDimensions = cloudinary?.dimensions

  return {
    type,
    title,
    description,
    imageData,
    elementDimensions,
    chunkIndex,
    eventIndex,
    visible,
    isIFrame,
    coordinates,
    screenSize,
  }
}

const convertDropdownSelect = ({ type, event, chunkIndex, eventIndex }: any) => {
  const {
    title,
    description,
    cloudinary,
    visible = true,
    coordinates,
    screenSize,
  } = event.meta || { title: '', description: '' }
  const imageData = { url: cloudinary ? getCloudinaryUrl(cloudinary.public_id) : null }
  const isIFrame = !!event.uuid
  const elementDimensions = cloudinary?.dimensions
  const value = event.data.text || null

  return {
    type,
    title,
    description,
    imageData,
    elementDimensions,
    chunkIndex,
    eventIndex,
    value,
    visible,
    isIFrame,
    coordinates,
    screenSize,
  }
}

const convertInput = ({ type, event, chunkIndex, eventIndex }: any) => {
  const { title, description, cloudinary, visible = true, coordinates, screenSize } = event.meta
  const imageData = { url: cloudinary ? getCloudinaryUrl(cloudinary.public_id) : null }
  const isIFrame = !!event.uuid
  const elementDimensions = cloudinary?.dimensions

  return {
    type,
    title,
    description,
    imageData,
    elementDimensions,
    chunkIndex,
    eventIndex,
    visible,
    isIFrame,
    coordinates,
    screenSize,
  }
}

const convertors = {
  [StepTypes.goto]: convertGoTo,
  [StepTypes.click]: convertClick,
  [StepTypes.dropdownSelect]: convertDropdownSelect,
  [StepTypes.input]: convertInput,
}

const convert = (step: any) => {
  const convertor = convertors[step.type]

  if (convertor) {
    return convertor(step)
  }

  return step
}

const isVisible = (step: any) => step.visible

const useNormalizedSteps = (walkthrough: Walkthrough) => {
  const { steps, canEdit } = walkthrough

  return useMemo(() => {
    const normalizedSteps = steps.map(convert)
    return canEdit ? normalizedSteps : normalizedSteps.filter(isVisible)
  }, [steps, canEdit])
}

export default useNormalizedSteps
