import React from 'react'

const RRWebPlayerIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 10H10C9.44772 10 9 10.4477 9 11V19L23 19V11C23 10.4477 22.5523 10 22 10ZM8 11V19.5V21C8 22.1046 8.89543 23 10 23H22C23.1046 23 24 22.1046 24 21V19.5V11C24 9.89543 23.1046 9 22 9H10C8.89543 9 8 9.89543 8 11ZM23 21V20L9 20V21C9 21.5523 9.44772 22 10 22H22C22.5523 22 23 21.5523 23 21ZM15.2774 12.584C15.0476 12.4309 14.7372 12.4929 14.584 12.7227C14.5048 12.8415 14.4832 12.9819 14.5125 13.1109C14.5044 13.1467 14.5001 13.184 14.5001 13.2223V17.0001C14.5001 17.1845 14.6015 17.3539 14.7641 17.4409C14.9267 17.5279 15.124 17.5184 15.2774 17.4161L18.2774 15.4161C18.4165 15.3233 18.5001 15.1672 18.5001 15.0001C18.5001 14.8329 18.4165 14.6768 18.2774 14.584L15.2774 12.584ZM17.0987 15.0001L15.5001 16.0658V13.9343L17.0987 15.0001Z"
      fill="currentColor"
    />
  </svg>
)

export default RRWebPlayerIcon
