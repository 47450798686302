import {
  AgentQuery,
  MessageHistoryFieldsFragment,
  StepHistoryFieldsFragment,
} from 'app/javascript/components/graphql'
import React from 'react'
import { useReducer } from 'react'
import { v4 as uuidv4 } from 'uuid'

const ChatStateContext = React.createContext<ChatState | undefined>(undefined)
const ChatDispatchContext = React.createContext<
  | {
      addMessage: (message: MessageHistoryFieldsFragment) => void
      setIsQuerying: (isQuerying: boolean) => void
      addMessageToQueue: (message: string) => void
      setMessages: (messages: MessageHistoryFieldsFragment[]) => void
      setSteps: (steps: StepHistoryFieldsFragment[]) => void
      clearSteps: () => void
      clearMessageQueue: () => void
      clearMessages: () => void
      setThreadUuid: (threadUuid: string) => void
      resetChat: () => void
      closeHistory: () => void
      openHistory: () => void
    }
  | undefined
>(undefined)

export const useChatState = () => {
  const context = React.useContext(ChatStateContext)
  if (!context) {
    throw new Error('useChatState must be used within a AgentProvider')
  }
  return context
}

export const useChatDispatch = () => {
  const context = React.useContext(ChatDispatchContext)
  if (!context) {
    throw new Error('useChatDispatch must be used within a AgentProvider')
  }
  return context
}

export interface ChatState {
  messageQueue: string[]
  threadUuid: string
  agentUuid: string
  messages: MessageHistoryFieldsFragment[]
  steps: StepHistoryFieldsFragment[]
  isQuerying: boolean
  isEmptyChat: boolean
  openHistory: boolean
  chatId: string
  agent: AgentQuery['agent']
}

const initialState: ChatState = {
  messageQueue: [],
  threadUuid: '',
  agentUuid: '',
  messages: [],
  steps: [],
  isQuerying: false,
  isEmptyChat: true,
  openHistory: false,
  chatId: '',
  agent: null,
}

type Action =
  | { type: 'ADD_MESSAGE'; payload: MessageHistoryFieldsFragment }
  | { type: 'SET_IS_QUERYING'; payload: boolean }
  | { type: 'SET_MESSAGES'; payload: MessageHistoryFieldsFragment[] }
  | { type: 'SET_STEPS'; payload: StepHistoryFieldsFragment[] }
  | { type: 'CLEAR_MESSAGES' }
  | { type: 'CLEAR_STEPS' }
  | { type: 'CLEAR_MESSAGE_QUEUE' }
  | { type: 'SET_THREAD_UUID'; payload: string }
  | { type: 'ADD_MESSAGE_TO_QUEUE'; payload: string }
  | { type: 'RESET_CHAT'; payload: AgentQuery['agent'] }
  | { type: 'CLOSE_HISTORY' }
  | { type: 'OPEN_HISTORY' }

const chatReducer = (state: ChatState, action: Action): ChatState => {
  switch (action.type) {
    case 'ADD_MESSAGE':
      return { ...state, messages: [...state.messages, action.payload] }
    case 'SET_IS_QUERYING':
      return { ...state, isQuerying: action.payload }
    case 'SET_MESSAGES':
      return { ...state, messages: action.payload }
    case 'SET_STEPS':
      return { ...state, steps: action.payload }
    case 'CLEAR_STEPS':
      return { ...state, steps: [] }
    case 'CLEAR_MESSAGES':
      return { ...state, messages: [] }
    case 'CLEAR_MESSAGE_QUEUE':
      return { ...state, messageQueue: [] }
    case 'SET_THREAD_UUID':
      return { ...state, threadUuid: action.payload }
    case 'ADD_MESSAGE_TO_QUEUE':
      return { ...state, messageQueue: [...state.messageQueue, action.payload] }
    case 'CLOSE_HISTORY':
      return { ...state, openHistory: false }
    case 'OPEN_HISTORY':
      return { ...state, openHistory: true }
    case 'RESET_CHAT':
      return { ...initialState, chatId: uuidv4(), agent: action.payload }
    default:
      return state
  }
}
type AgentProviderProps = {
  agent: AgentQuery['agent']
  children: React.ReactNode
}

export const AgentProvider: React.FC<AgentProviderProps> = ({ agent, children }) => {
  const [state, dispatch] = useReducer(chatReducer, { ...initialState, chatId: uuidv4(), agent })

  // Helper functions to abstract the dispatching
  const addMessage = (message: MessageHistoryFieldsFragment) => {
    dispatch({ type: 'ADD_MESSAGE', payload: message })
  }

  const setIsQuerying = (isQuerying: boolean) => {
    dispatch({ type: 'SET_IS_QUERYING', payload: isQuerying })
  }

  const setMessages = (messages: MessageHistoryFieldsFragment[]) => {
    dispatch({ type: 'SET_MESSAGES', payload: messages })
  }

  const setSteps = (steps: StepHistoryFieldsFragment[]) => {
    dispatch({ type: 'SET_STEPS', payload: steps })
  }

  const clearSteps = () => {
    dispatch({ type: 'CLEAR_STEPS' })
  }

  const clearMessages = () => {
    dispatch({ type: 'CLEAR_MESSAGES' })
  }

  const clearMessageQueue = () => {
    dispatch({ type: 'CLEAR_MESSAGE_QUEUE' })
  }

  const setThreadUuid = (threadUuid: string) => {
    dispatch({ type: 'SET_THREAD_UUID', payload: threadUuid })
  }

  const addMessageToQueue = (message: string) => {
    dispatch({ type: 'ADD_MESSAGE_TO_QUEUE', payload: message })
  }

  const resetChat = () => {
    dispatch({ type: 'RESET_CHAT', payload: agent })
  }

  const closeHistory = () => {
    dispatch({ type: 'CLOSE_HISTORY' })
  }

  const openHistory = () => {
    dispatch({ type: 'OPEN_HISTORY' })
  }

  return (
    <ChatStateContext.Provider value={state}>
      <ChatDispatchContext.Provider
        value={{
          addMessage,
          setIsQuerying,
          setMessages,
          clearMessages,
          setSteps,
          clearSteps,
          clearMessageQueue,
          setThreadUuid,
          addMessageToQueue,
          resetChat,
          closeHistory,
          openHistory,
        }}
      >
        {children}
      </ChatDispatchContext.Provider>
    </ChatStateContext.Provider>
  )
}
