import React, { useEffect, useRef, useMemo } from 'react'
import { elementWrapper } from '../../../../utils/helpers/element'
import { usePlaygroundContext } from '../PlaygroundContext/context'
import { initReplayer, disableLinks, getNode, goto } from './replayer'
import { StepTypes } from '../../../../../App/models/Walkthrough'
import useNavigationContext from '../PlaygroundContext/hooks/useNavigationContext'
import useContentContext from '../PlaygroundContext/hooks/useContentContext'
import { RRWEB_EVENT_TYPES } from '../../../../../Walkthrough/constants/RRWebEventTypes'
import './style.scss'
import { ArrowIcon } from '../../../../../Embed/Content/Labs/icons'
import Button from '../../../../../Embed/Content/Button'
import { NotFound } from '@data-driven/components'
import ProgressLoader from '../../../../../Walkthrough/ui/ProgressLoader'

const Replay: React.FC = () => {
  const root = useRef<HTMLInputElement>(null)
  const { chunks } = useContentContext()
  const { setElement } = usePlaygroundContext()
  const { completed, step, nextStep } = useNavigationContext()

  const events = useMemo(
    () => (completed ? chunks[chunks.length - 1] : chunks[step.chunkIndex]).events,
    [step.chunkIndex, completed, chunks]
  )

  const loading = useMemo(() => chunks[step.chunkIndex]?.loading, [step.chunkIndex, chunks])

  useEffect(() => {
    if (events && root.current) {
      if (
        events.some((event: any) => event.type === RRWEB_EVENT_TYPES.META) &&
        events.some((event: any) => event.type === RRWEB_EVENT_TYPES.FULL_SNAPSHOT)
      ) {
        initReplayer(root.current, events)
      }
    }
  }, [root.current, events])

  useEffect(() => {
    const isAction = [StepTypes.click, StepTypes.dropdownSelect, StepTypes.input].includes(
      step.type
    )

    if (isAction && !completed && events) {
      goto(events[step.eventIndex].timestamp - events[0].timestamp)
      getElement()
      disableLinks()
    } else {
      setElement(undefined)
    }
  }, [step, completed, events])

  useEffect(() => {
    if (completed && events) {
      goto(events[events.length - 1].timestamp - events[0].timestamp)
      disableLinks()
    }
  }, [completed, events])

  const getElement = () => {
    if (
      events &&
      events[step.eventIndex] &&
      events[step.eventIndex].data &&
      events[step.eventIndex].data.id
    ) {
      let pseudoElement = getNode(events[step.eventIndex].data.id)
      if (pseudoElement) {
        pseudoElement = elementWrapper(pseudoElement)

        const elementRectangle = pseudoElement.getBoundingClientRect()
        const parentRectangle = pseudoElement.offsetParent?.getBoundingClientRect()

        if (
          parentRectangle &&
          parentRectangle.width < elementRectangle.width &&
          parentRectangle.height < elementRectangle.height
        ) {
          pseudoElement = pseudoElement.offsetParent
        }

        setElement(pseudoElement)
      }
    }
  }

  return events ? (
    <div ref={root} className="lab-rrweb-frame" />
  ) : loading ? (
    <ProgressLoader chunk={chunks[step.chunkIndex]} />
  ) : (
    <NotFound
      className="min-h-[calc(100vh-52px)]"
      message="Data is not found"
      dotsEnabled
      toolbar={
        completed ? (
          <></>
        ) : (
          <Button onClick={nextStep} type="action" className="mt-[30px] lg:mt-[50px] md:mt-[40px]">
            <div>Go to next step</div>
            <ArrowIcon />
          </Button>
        )
      }
    />
  )
}

export default Replay
