import React from 'react'
import DynamicForm from '../../../components/DynamicForm'
import { GET_CREDENTIALS, UPDATE_CREDENTIAL_MUTATION } from '../../../graphql/queries/credential'
import { useMutation, useQuery } from '@apollo/client'
import {
  LookerCredentialsQuery,
  LookerCredentialsQueryVariables,
  UpdateLookerCredentialsMutation,
  UpdateLookerCredentialsMutationVariables,
} from '../../../../graphql'
import Spin from '../../../../../components/common/ui/Spin'

type LoginCredentialsFormProps = {
  handleSuccess: () => void
  handleError: () => void
}

const LoginCredentialsForm = ({ handleSuccess, handleError }: LoginCredentialsFormProps) => {
  const formRef = React.useRef<{ submitForm: () => Promise<void> }>(null)
  const [saving, setSaving] = React.useState(false)

  const { loading, data } = useQuery<LookerCredentialsQuery, LookerCredentialsQueryVariables>(
    GET_CREDENTIALS
  )
  const [updateCredentialsMutation] = useMutation<
    UpdateLookerCredentialsMutation,
    UpdateLookerCredentialsMutationVariables
  >(UPDATE_CREDENTIAL_MUTATION)

  if (loading) {
    return <div>Loading...</div>
  }
  const currentValues = data.lookerCredentials?.credentials || {}

  const formData = [
    {
      name: 'looker_url',
      label: 'Looker URL',
      type: 'url',
      required: true,
      defaultValue: '',
    },
    {
      name: 'looker_api_url',
      label: 'Looker API URL',
      type: 'url',
      required: true,
      defaultValue: '',
    },
    {
      name: 'username',
      label: 'Username',
      type: 'text',
      required: true,
      defaultValue: '',
    },
    {
      name: 'password',
      label: 'Password',
      type: 'password',
      required: true,
      defaultValue: '',
    },
    {
      name: 'client_id',
      label: 'Client ID',
      type: 'text',
      required: true,
      defaultValue: '',
    },
    {
      name: 'client_secret',
      label: 'Client Secret',
      type: 'password',
      required: true,
      defaultValue: '',
    },
  ]

  // populate the default value of the form
  formData.forEach((item) => {
    if (currentValues[item.name]) {
      item.defaultValue = currentValues[item.name]
    }
  })

  const handleSubmit = async (values: any) => {
    setSaving(true)
    const response = await updateCredentialsMutation({
      variables: {
        credentials: values,
      },
    })
    setSaving(false)
    if (response.data.updateLookerCredentials.errors.length) {
      handleError()
      return
    }
    handleSuccess()
  }

  return (
    <>
      <div className="max-w-xl">
        <DynamicForm ref={formRef} formData={formData} handleSubmit={handleSubmit} />
        <button
          type="submit"
          onClick={() => formRef.current?.submitForm()}
          className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-flowmo-blue-700 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-flowmo-blue-800"
          disabled={saving}
        >
          {saving ? <Spin /> : 'Save'}
        </button>
      </div>
    </>
  )
}

export default LoginCredentialsForm
