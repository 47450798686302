import React from 'react'
import DraggableContainer from './DraggableContainer'
import Header from './Header'
import Support from './Support'
import Content from './Content'
import useNavigationContext from '../../../views/Walkthrough/modules/PlaygroundContext/hooks/useNavigationContext'

const Container = ({ children }: { children: React.ReactNode }) => (
  <div className="w-[300px] h-[405px] rounded bg-[#F8F9FB] shadow-lg">{children}</div>
)

const ExpandedWidget: React.FC = () => {
  const { completed } = useNavigationContext()

  return (
    <DraggableContainer>
      <Container>
        <Header />
        <Content />
        {!completed && <Support />}
      </Container>
    </DraggableContainer>
  )
}

export default ExpandedWidget
