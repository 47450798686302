import React from 'react'

const DragIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m11.494 7.723-.97.97a.505.505 0 0 1-.713-.714l1.831-1.831a.506.506 0 0 1 .715 0l1.833 1.833a.504.504 0 1 1-.715.712l-.972-.97v3.773h3.773l-.971-.973a.505.505 0 1 1 .715-.712l1.832 1.832a.506.506 0 0 1 0 .715l-1.834 1.833a.503.503 0 0 1-.83-.16.505.505 0 0 1 .118-.555l.97-.971h-3.773v3.772l.97-.97a.505.505 0 0 1 .713.715l-1.83 1.83a.504.504 0 0 1-.715 0L9.81 16.021a.505.505 0 0 1 .714-.713l.97.968v-3.771H7.722l.971.971a.504.504 0 1 1-.715.713l-1.831-1.832a.506.506 0 0 1 .003-.715l1.832-1.831.003-.004h.001a.505.505 0 1 1 .71.713l-.974.976h3.772V7.723Z"
      fill="currentColor"
    />
  </svg>
)

export default DragIcon
