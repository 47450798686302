import React from 'react'
import { clsx } from 'clsx'
import { BeaconIcon } from '../icons'
import useNavigationContext from '../../../modules/PlaygroundContext/hooks/useNavigationContext'

import './styles.scss'
import CloseIcon from './CloseIcon'

interface Props {
  elementRef: any
  description?: string
  styles: any
  onClose?: () => void
  onMouseOver?: () => void
  onMouseLeave?: () => void
  className?: string
}

const Beacon = ({
  elementRef,
  description,
  styles,
  onClose,
  onMouseLeave,
  onMouseOver,
  className,
}: Props) => {
  const { step } = useNavigationContext()

  return (
    <div
      className={clsx(
        'beacon',
        description ? 'with-description' : 'without-description',
        styles && `beacon-${styles.horizontal}`,
        styles && `beacon-${styles.vertical}`,
        className
      )}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      ref={elementRef}
    >
      <div className="beacon-icon">
        <BeaconIcon type={step.type} />
      </div>
      {description && (
        <div className="beacon-content">
          <div className="beacon-text">{description}</div>
          {onClose && (
            <div className="close-icon" onClick={onClose}>
              <CloseIcon />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Beacon
