import React from 'react'
import { clsx } from 'clsx'

interface Props {
  className?: string
  children?: React.ReactNode
}

const Title: React.FC<Props> = ({ children, className }) => (
  <div
    className={clsx('font-inter font-semibold text-[13px] text-[#434E5F] text-center', className)}
  >
    {children}
  </div>
)

export default Title
