import React, { useState } from 'react'
import Loader from '../Loader'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import Walkthrough from '../../../../App/models/Walkthrough'
import { useMutation } from '@apollo/client'
import { SHARE_WALKTHROUGH_QUERY } from '../../../../App/graphql/queries/walkthrough'
import OptionsInput, { ShareOptions, ShareOptionType } from './OptionsInput'
import { SHARE_SKILL_SET_QUERY } from '../../../../App/graphql/queries/skill_set'
import SkillSet from '../../../../App/models/SkillSet'
import ShareDialog from '../index'
import useCurrentOrganization from '../../../../App/hooks/useCurrentOrganization'

export const getSharedAccess = (object: Walkthrough | SkillSet) =>
  ShareOptions.find((el) => el.key === object.sharedAccess)

const QUERIES = {
  walkthrough: SHARE_WALKTHROUGH_QUERY,
  skillSet: SHARE_SKILL_SET_QUERY,
}

const OBJECT_NAME = {
  walkthrough: 'Walkthrough',
  skillSet: 'Skill Set',
}

interface Props {
  object: Walkthrough | SkillSet
  objectType: 'walkthrough' | 'skillSet'
}

const Options: React.FC<Props> = ({ object, objectType }) => {
  const [loading, setLoading] = useState<boolean>(undefined)
  const [selected, setSelected] = useState(getSharedAccess(object))
  const [showSkillSetShareModal, setShowModal] = useState(false)
  const toggleModal = () => setShowModal(!showSkillSetShareModal)
  const organization = useCurrentOrganization()

  const shareOptionText = (skillSet: SkillSet) => {
    const shareOption = ShareOptions.filter((option) => option.key === skillSet.sharedAccess)
    return (
      <div className="mt-3">
        <span className="text-ddu-gray-900 block text-sm font-medium">{shareOption[0].name}</span>
        <span className="text-ddu-gray-700 block text-sm">
          {shareOption[0].description('walkthrough')}
        </span>
      </div>
    )
  }

  const handleSelect = (shareType: ShareOptionType) => {
    setLoading(true)
    setSelected(shareType)
    shareObject({
      variables: { id: object.id, shareType: shareType.key, organizationId: organization.id },
    }).then(() => {
      setLoading(false)
    })
  }

  const [shareObject] = useMutation(QUERIES[objectType])

  return (
    <>
      {showSkillSetShareModal && (
        <ShareDialog
          object={(object as Walkthrough).skillSet}
          objectType={'skillSet'}
          onClose={toggleModal}
        />
      )}
      {object.canEditSharing &&
        !(objectType === 'walkthrough' && (object as Walkthrough).skillSet) && (
          <>
            <div className="mb-2">
              <div className="mt-5 sm:mt-6 flex d-flex">
                <h2 className="text-lg leading-6 font-medium text-gray-900 mr-2">Settings</h2>
                {loading ? (
                  <Loader />
                ) : loading !== undefined ? (
                  <CheckCircleIcon className="h-6 w-6 text-green-600" />
                ) : (
                  ''
                )}
              </div>
              <p className="mt-1 text-sm text-gray-500">
                Who can take your {OBJECT_NAME[objectType]}
              </p>
            </div>

            <OptionsInput objectType={objectType} onSelect={handleSelect} value={selected} />
          </>
        )}

      {object.isInMyOrganizationLibrary && (
        <div>
          <h3>Who can take this {OBJECT_NAME[objectType]}</h3>
          <p className="mt-1 text-sm text-gray-500">
            This {OBJECT_NAME[objectType]} is in the organization library. It can be seen and taken
            by anyone within your organization.
          </p>
        </div>
      )}
      {(object as Walkthrough).skillSet && !(object as Walkthrough).isInMyOrganizationLibrary && (
        <div>
          <h3>Who can take this {OBJECT_NAME[objectType]}</h3>
          <p className="mt-1 text-sm text-gray-500">
            This Walkthrough is part of a Skill Set which has the following sharing settings. You
            can{' '}
            <a href="#" onClick={() => setShowModal(true)} className="text-ddu-blue">
              edit the sharing settings
            </a>{' '}
            by visiting the Skill Set.
          </p>
          {shareOptionText((object as Walkthrough).skillSet)}
        </div>
      )}
    </>
  )
}

export default Options
