import React from 'react'
import EmptyContent from './EmptyContent'
import { GET_AGENT_WORKFLOWS, GET_WORKFLOW_TEMPLATES } from '../../../graphql/queries/workflow'
import { useQuery } from '@apollo/client'
import Spin from '../../../../common/ui/Spin'
import {
  AgentWorkflowsQuery,
  AgentWorkflowsQueryVariables,
  WorkflowTemplatesQuery,
  WorkflowTemplatesQueryVariables,
} from 'app/javascript/components/graphql'
import WorkflowCard from '../../../components/WorkflowCard'
import WorkflowTemplateCard from '../WorkflowTemplateCard'

interface WorkflowListProps {
  agentUuid: string
  onUpdate: () => void
}

const WorkflowList = ({ agentUuid, onUpdate }: WorkflowListProps) => {
  const { data, loading, refetch } = useQuery<AgentWorkflowsQuery, AgentWorkflowsQueryVariables>(
    GET_AGENT_WORKFLOWS,
    {
      variables: {
        agentUuid,
      },
      fetchPolicy: 'network-only',
    }
  )

  const { data: workflowTemplatesData } = useQuery<
    WorkflowTemplatesQuery,
    WorkflowTemplatesQueryVariables
  >(GET_WORKFLOW_TEMPLATES)

  const workflowTemplates = workflowTemplatesData?.workflowTemplates || []

  if (loading) {
    return (
      <div>
        <Spin />
      </div>
    )
  }

  const workflows = data?.agentWorkflows

  const handleUpdate = () => {
    refetch()
    onUpdate()
  }

  return (
    <div className="flex flex-col space-y-5">
      <h3 className="text-xl font-semibold mb-5 pb-5 border-b">Workflows</h3>
      <div className="flex flex-col ml-12 space-y-4">
        <h4 className="text-lg font-semibold">Active</h4>
        <div className="flex flex-wrap space-x-2">
          {workflows.length == 0 && (
            <EmptyContent title="No workflows are associated with this agent." />
          )}
          {workflows?.map((workflow) => (
            <WorkflowCard key={workflow.uuid} workflow={workflow} size={'small'} />
          ))}
        </div>
      </div>

      <div className="flex flex-col ml-12 space-y-4">
        <h4 className="text-lg font-semibold">Templates</h4>
        <div className="flex flex-wrap space-x-2">
          {workflowTemplates?.map((workflowTemplate) => (
            <WorkflowTemplateCard
              key={workflowTemplate.uuid}
              agentUuid={agentUuid}
              workflowTemplate={workflowTemplate}
              onUpdate={handleUpdate}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
export default WorkflowList
