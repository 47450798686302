import { gql } from '@apollo/client'

export const UPDATE_CREDENTIAL_MUTATION = gql`
  mutation updateLookerCredentials($credentials: JSON!) {
    updateLookerCredentials(credentials: $credentials) {
      errors
      success
    }
  }
`
export const GET_CREDENTIALS = gql`
  query lookerCredentials {
    lookerCredentials {
      credentials
    }
  }
`
