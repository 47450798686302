import React from 'react'
import TextContent from '../../../components/Chat/TextContent'

function containsSections(message: string, sections: string[]): boolean {
  // Build a regular expression pattern that matches any of the specified sections
  const pattern = sections.map((section) => `=== ${section}`).join('|')
  const regex = new RegExp(pattern)

  // Test if the message contains any of the sections
  return regex.test(message)
}

function splitIntoSections(message: string): { type: string; content: string }[] {
  // Regular expression to match sections and their content
  const regex = /===\s*(\w+)\s*\n([\s\S]*?)(?=\n===\s*\w+|$)/g
  let match
  const sections = []

  while ((match = regex.exec(message)) !== null) {
    const [, type, content] = match
    sections.push({ type, content: content.trim() })
  }

  return sections
}

const sections = ['Fetch', 'Reason', 'Interpret']

const TaskSection = ({
  type,
  section,
  stepNumber,
}: {
  type: string
  section: string
  stepNumber: number
}) => {
  return (
    <div className="">
      <div className="text-sm text-slate-500 italic">
        {stepNumber}. {type}
      </div>
      <div className="text-sm">
        <TextContent content={{ type: 'text', value: section }} />
      </div>
    </div>
  )
}

const TaskVisualization = ({ task }: { task: string }) => {
  if (containsSections(task, sections)) {
    const sections = splitIntoSections(task)
    return (
      <div className=" space-y-2">
        {sections.map((section, index) => (
          <TaskSection
            key={index}
            stepNumber={index + 1}
            type={section.type}
            section={section.content}
          />
        ))}
      </div>
    )
  }

  return <div>{task}</div>
}

export default TaskVisualization
