import React from 'react'
import WalkthroughInformation from './WalkthroughInformation'
import StepInformation from './StepInformation'
import Scrollable from './Scrollable'

const Walkthrough = () => (
  <Scrollable>
    <WalkthroughInformation />
    <StepInformation />
  </Scrollable>
)

export default Walkthrough
