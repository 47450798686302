import React from 'react'

const RightArrowIcon = () => (
  <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.547 5.85936C8.45959 5.95062 8.34252 6.00112 8.221 5.99998C8.09948 5.99884 7.98324 5.94615 7.89731 5.85325C7.81138 5.76036 7.76264 5.6347 7.76158 5.50333C7.76053 5.37196 7.80724 5.2454 7.89166 5.15091L9.41784 3.50103H0.463671C0.340753 3.50103 0.22287 3.44824 0.135954 3.35428C0.0490385 3.26032 0.000209684 3.13288 0.000209684 3C0.000209684 2.86712 0.0490385 2.73968 0.135954 2.64572C0.22287 2.55176 0.340753 2.49897 0.463671 2.49897H9.41784L7.89166 0.849095C7.80724 0.7546 7.76053 0.62804 7.76158 0.496673C7.76264 0.365305 7.81138 0.239642 7.89731 0.146747C7.98324 0.053853 8.09948 0.00116049 8.221 1.894e-05C8.34252 -0.00112261 8.45959 0.0493782 8.547 0.140644L10.8643 2.64577C10.9512 2.73973 11 2.86715 11 3C11 3.13285 10.9512 3.26027 10.8643 3.35423L8.547 5.85936Z"
      fill="currentColor"
    />
  </svg>
)

export default RightArrowIcon
