import React from 'react'
import { clsx } from 'clsx'

interface Props {
  className?: string
  onClick?: () => void
  children?: React.ReactNode
}

const CardContainer: React.FC<Props> = ({ children, className, onClick }) => (
  <div
    onClick={onClick}
    className={clsx('w-[125px] h-[92px] relative rounded bg-[#EDEFF1]', className)}
  >
    {children}
  </div>
)

export default CardContainer
