import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { RootState } from '../../../store'
import { AcademicCapIcon, PencilIcon } from '@heroicons/react/24/outline'
import HeaderActionButton from '../../../components/HeaderActionButton'
import AgentImage from '../../../components/AgentImage'
import { useChatDispatch, useChatState } from './AgentProvider'
import { ChevronLeftIcon } from '@heroicons/react/24/solid'

const AgentHeader = () => {
  const history = useHistory()
  const location = useLocation()
  const { agent, threadUuid, isQuerying } = useChatState()
  const { resetChat } = useChatDispatch()
  const { user } = useSelector((state: RootState) => state.app)

  const uuid = agent.uuid

  const resetUrl = useCallback(() => {
    // update the url
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete('thread_uuid')
    history.replace({ search: searchParams.toString() })
  }, [location.search])

  const handleNewThread = () => {
    resetUrl()
    resetChat()
  }

  return (
    <div className="bg-white pt-px rounded-2xl shadow-xl">
      <div className="flex flex-row">
        <div className="min-w-0 flex-1  flex flex-row items-center space-x-5">
          <AgentImage agent={agent} className="w-24 h-24 rounded-l-xl" />

          <div className="flex flex-col font-display items-baseline tracking-tight ">
            <div className="text-3xl sm:text-4xl font-extrabold text-blue-600">
              {agent.firstName}
            </div>
            <div className="font-semibold">{agent.jobTitle}</div>
          </div>
          <div className="flex items-center"></div>
        </div>

        <div className="flex flex-row items-center space-x-2 mr-2">
          {(user?.isOrganizationAdmin || user?.id.toString() == agent.createdBy.id) && (
            <>
              <Link to={'/agent/edit/' + uuid}>
                <HeaderActionButton label={'Edit'} icon={<PencilIcon className="h-4 w-4 mr-2" />} />
              </Link>

              <Link to={'/agent/knowledge/' + uuid}>
                <HeaderActionButton
                  label={'Knowledge'}
                  icon={<AcademicCapIcon className="h-4 w-4 mr-2" />}
                />
              </Link>
            </>
          )}

          {(threadUuid || isQuerying) && (
            <div className="cursor-pointer">
              <HeaderActionButton
                onClick={handleNewThread}
                label={'Back'}
                icon={<ChevronLeftIcon className="h-4 w-4 mr-2" />}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AgentHeader
