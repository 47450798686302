import { useQuery } from '@apollo/client'
import {
  CREATED_WALKTHROUGHS_QUERY,
  CreatedWalkthroughsVars,
  GET_ORGANIZATION_WALKTHROUGHS,
  GET_WALKTHROUGH_QUERY,
  WalkthroughData,
  WalkthroughVars,
} from '../../App/graphql/queries/walkthrough'
import { SkillSetsData } from '../../App/graphql/queries/skill_set'

const useWalkthroughQuery = (uuid: string, usePublicEndpoint: boolean) => {
  const props = useQuery<WalkthroughData, WalkthroughVars>(GET_WALKTHROUGH_QUERY, {
    variables: { uuid },
  })

  const { data } =
    !usePublicEndpoint &&
    useQuery<SkillSetsData, CreatedWalkthroughsVars>(
      props.data?.walkthrough && props.data?.walkthrough.isInMyLibrary
        ? CREATED_WALKTHROUGHS_QUERY
        : GET_ORGANIZATION_WALKTHROUGHS,
      {
        variables: { contentType: 'SkillSet', sortDirection: 'DESC', sortBy: 'created_at' },
      }
    )

  const skillSets = data && data.items

  return { ...props, walkthrough: props.data?.walkthrough, skillSets }
}

export default useWalkthroughQuery
