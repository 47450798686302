import React, { ReactElement, useCallback, useState } from 'react'
// @ts-ignore
import * as getNestedBoundingClientRect from 'get-nested-bounding-client-rect'

interface Props {
  element?: HTMLElement
  children(position: DOMRect | any): ReactElement
}

const isRRWebFrame = (element: HTMLElement) => {
  const html = element.ownerDocument.getElementsByTagName('html')[0]

  return html?.className?.includes('rrweb')
}

const ElementPosition: React.FC<Props> = ({ children, element }) => {
  const [position, setPosition] = useState(null)

  const resize = useCallback(() => {
    if (element) {
      const isIFrameElement = !isRRWebFrame(element)
      const rect = isIFrameElement
        ? getNestedBoundingClientRect(element)
        : element.getBoundingClientRect()
      const delta = isIFrameElement ? 0 : 55

      const newPosition = {
        top: rect.top + window.scrollY + delta,
        left: rect.left + window.scrollX,
        bottom: rect.top + window.scrollY + delta + rect.height,
        right: rect.left + window.scrollX + rect.width,
        width: rect.width,
        height: rect.height,
      }

      setPosition(newPosition)
    }
  }, [position, element])

  React.useEffect(() => {
    const interval = setInterval(() => {
      resize()
    }, 20)

    return () => {
      clearInterval(interval)
    }
  }, [element])

  return children({ position })
}

export default ElementPosition
