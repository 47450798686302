import React, { useEffect, useState } from 'react'
import Draggable from 'react-draggable'
import { useResizeDetector } from 'react-resize-detector'
import useWindowSize from '../../../hooks/useWindowSize'

const DraggableContainer = ({ children }: { children: React.ReactNode }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 })

  //widget is hidden before setting initial position
  const [hidden, setHidden] = React.useState(true)

  const windowDimensions = useWindowSize()
  const { width, height, ref } = useResizeDetector()

  //set initial position
  useEffect(() => {
    if (width && height && hidden) {
      setPosition({
        x: windowDimensions.width - width - 30,
        y: windowDimensions.height - height - 30,
      })
      setHidden(false)
    }
  }, [width, height])

  //resize on window size change and widget height change
  useEffect(() => {
    let x: number
    let y: number

    if (position.x > windowDimensions.width - width) {
      x = windowDimensions.width - width - 20
    }

    if (position.y > windowDimensions.height - height) {
      y = windowDimensions.height - height - 20
    }

    if (x || y) {
      setPosition((state) => ({ x: x || state.x, y: y || state.y }))
    }
  }, [windowDimensions, height])

  const onControlledDrag = (e: any, position: any) => {
    const { x, y } = position
    setPosition({ x, y })
  }

  return (
    <Draggable position={position} onDrag={onControlledDrag} handle=".drag-handler" bounds="body">
      <div style={{ opacity: hidden ? 0 : 1 }} ref={ref} className="w-[300px] h-[405px] fixed z-50">
        {children}
      </div>
    </Draggable>
  )
}

export default DraggableContainer
