import useUpdateSkillSetMutation from './useUpdateSkillSetMutation'
import { useCallback } from 'react'
import SkillSet from '../../App/models/SkillSet'

const useUpdateSkillSet = (skillSet: SkillSet) => {
  const [updateSkillSet] = useUpdateSkillSetMutation()

  return useCallback(
    async (values: any) => {
      await updateSkillSet({
        variables: {
          id: skillSet.id,
          name: values.name,
          description: values.description,
        },
      })
    },
    [skillSet]
  )
}

export default useUpdateSkillSet
