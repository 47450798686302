import React from 'react'
import CloseButton from './CloseButton'

interface Props {
  onClose: () => void
  children?: React.ReactNode
}

const Card: React.FC<Props> = ({ children, onClose }) => (
  <div className="w-[280px] h-[285px] bg-[#F8F9FB] mt-[38px] mx-auto rounded border border-[#E4E7ED]">
    <div className="px-1.5 pt-1.5 pb-0.5 flex justify-end">
      <CloseButton onClick={onClose} />
    </div>
    <div className="px-[15px]">{children}</div>
  </div>
)

export default Card
