import { useMemo, useState } from 'react'
import { StepTypes } from '../../../../../../App/models/Walkthrough'
import { findLastIndex } from '../../../../../../common/utils/findLastIndex'

const findNextStepIndex = (steps: any[], actualStepIndex: number) => {
  return steps.findIndex((step, index) => step.type !== StepTypes.goto && index > actualStepIndex)
}

const findPrevStepIndex = (steps: any[], actualStepIndex: number) => {
  return findLastIndex(
    steps,
    (step, index) => step.type !== StepTypes.goto && index < actualStepIndex
  )
}

export const useNavigation = (steps: any[], onComplete: () => void) => {
  const firstStep = useMemo(() => steps.findIndex((step) => step.type !== StepTypes.goto), [steps])

  const [completed, setCompleted] = useState(firstStep === -1)
  const [actualStepIndex, setActualStepIndex] = useState(firstStep)
  const [currentStepIndex, setCurrentStepIndex] = useState(firstStep)

  const nextStep = () => {
    const nextStepIndex = findNextStepIndex(steps, actualStepIndex)

    if (nextStepIndex !== -1) {
      if (nextStepIndex > currentStepIndex) {
        setCurrentStepIndex(nextStepIndex)
      }

      setActualStepIndex(nextStepIndex)
    } else {
      setCompleted(true)
      onComplete()
    }
  }

  const prevStep = () => {
    const prevStepIndex = findPrevStepIndex(steps, actualStepIndex)
    prevStepIndex !== -1 && setActualStepIndex(prevStepIndex)
  }

  const step = {
    ...steps[actualStepIndex],
    completed: actualStepIndex < currentStepIndex,
    isFirstStep: actualStepIndex === 0,
  }

  return { step, nextStep, prevStep, completed, index: actualStepIndex }
}
