import React from 'react'
import { htmlStrip } from '../../../../../../common/utils/htmlStrip'
import { useField } from 'formik'
import Switcher from '../../../../../../common/ui/Switcher'
import ContentField from '../../../ContentField'

const TitleDescription = ({
  title,
  description,
  defaultTitle,
  index,
  canEditWalkthrough,
}: {
  title: string
  description?: string
  defaultTitle?: string
  index: number
  canEditWalkthrough?: boolean
}) => {
  const [field] = useField({ name: `steps[${index}].description` })
  return (
    <>
      <div className="flex justify-between items-center">
        {title && (
          <h3 className="text-base md:text-lg text-gray-900 font-semibold">{htmlStrip(title)}</h3>
        )}
        {!title && defaultTitle && <div className="text-gray-400">{defaultTitle}</div>}
        {canEditWalkthrough && <Switcher name={`steps[${index}].visible`} />}
      </div>
      {description !== undefined && (
        <ContentField
          placeholder={'Add Description'}
          name={`steps[${index}].description`}
          className="break-all text-sm px-3.5 py-2.5 rounded-md break-words font-small w-full text-gray-600"
          canEdit={canEditWalkthrough}
          iconClassName="mb-1"
        >
          {field.value}
        </ContentField>
      )}
    </>
  )
}
export default TitleDescription
