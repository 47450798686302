import React from 'react'
import { Filter, SortOption } from './types'
import Container from './container'
import Filters from './Filters'
import Sort from './sort'
import ActiveFilters from './ActiveFilters'

interface Props {
  sortOptions: SortOption[]
  filters: Filter[]
}

const FilterPanel: React.FC<Props> = ({ sortOptions, filters }) => (
  <>
    <Container>
      <Sort sortOptions={sortOptions} />
      <Filters filters={filters} />
    </Container>
    <ActiveFilters filters={filters} />
  </>
)

export default FilterPanel
