import React, { useState } from 'react'
import InitialDescriptionHighlighter from './InitialDescriptionHighlighter'
import LoopDescriptionHighlighter from './LoopDescriptionHighlighter'

interface Props {
  circleRef: any
  beaconRef: any
  styles: any
  description?: any
}

const DescriptionHighlighter = ({ circleRef, beaconRef, styles, description }: Props) => {
  const [showDescription, setShowDescription] = useState(true)
  const close = () => setShowDescription(false)

  return showDescription ? (
    <InitialDescriptionHighlighter
      circleRef={circleRef}
      beaconRef={beaconRef}
      styles={styles}
      description={description}
      onClose={close}
    />
  ) : (
    <LoopDescriptionHighlighter
      circleRef={circleRef}
      beaconRef={beaconRef}
      styles={styles}
      description={description}
    />
  )
}

export default DescriptionHighlighter
