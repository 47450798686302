import React, { useEffect } from 'react'
import ExpandedWidget from './Expanded'
import MinimizedWidget from './Minimized'
import useWidgetContext from '../../views/Walkthrough/modules/PlaygroundContext/hooks/useWidgetContext'
import useWindowSize from '../../hooks/useWindowSize'

//import '@data-driven/rrweb-player/dist/style.css'
import CloseModal from './CloseModal'

const Widget: React.FC = () => {
  const { minimized, setMinimized, showCloseModal } = useWidgetContext()
  const { isSmall } = useWindowSize()

  useEffect(() => {
    if (isSmall) {
      setMinimized(true)
    }
  }, [isSmall])

  return (
    <>
      {showCloseModal && <CloseModal />}
      {minimized ? <MinimizedWidget /> : <ExpandedWidget />}
    </>
  )
}

export default Widget
