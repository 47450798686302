import React from 'react'
import Walkthrough from '../../../../App/models/Walkthrough'
import SkillSet from '../../../../App/models/SkillSet'
import { Form, Formik } from 'formik'
import useUpdateWalkthrough from '../../../hooks/useUpdateWalkthrough'
import { WalkthroughValidateSchema } from '../../../utils/validateSchema'
import useNormalizedSteps from '../../../hooks/useNormalizedSteps'
import InternalViewer from './InternalViewer'
import PublicViewer from './PublicViewer'

interface Props {
  walkthrough: Walkthrough
  skillSets?: SkillSet[]
  isPublicView?: boolean
}

const WalkthroughViewer: React.FC<Props> = ({ walkthrough, isPublicView = false, skillSets }) => {
  const steps = useNormalizedSteps(walkthrough)
  const updateWalkthrough = useUpdateWalkthrough(walkthrough)

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...walkthrough, steps }}
      onSubmit={updateWalkthrough}
      validationSchema={WalkthroughValidateSchema}
      validateOnMount
    >
      {() => (
        <Form className="w-full flex flex-col">
          {isPublicView ? (
            <PublicViewer walkthrough={walkthrough} steps={steps} />
          ) : (
            <InternalViewer walkthrough={walkthrough} skillSets={skillSets} steps={steps} />
          )}
        </Form>
      )}
    </Formik>
  )
}

export default WalkthroughViewer
