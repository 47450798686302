import React, { useState } from 'react'
import Background from './Background'
import Card from './Card'
import SupportForm from './Form'
import SupportResult from './Result'
import Button from '../../../Button'

interface Props {
  onClose: () => void
}

const SupportDialog: React.FC<Props> = ({ onClose }) => {
  const [completed, setCompleted] = useState(false)
  const complete = () => setCompleted(true)
  return (
    <Background>
      <Card onClose={onClose}>
        {completed ? (
          <SupportResult>
            <Button color="success" className="mx-auto mt-4" onClick={onClose}>
              Go Back to Walkthrough
            </Button>
          </SupportResult>
        ) : (
          <SupportForm complete={complete} />
        )}
      </Card>
    </Background>
  )
}

export default SupportDialog
