import Walkthrough from '../../App/models/Walkthrough'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { decompress } from '../utils/rrweb'

export interface Chunk {
  loading: boolean
  events?: any
  error?: any
  progress: {
    loaded: number
    total: number
  }
}

const chunk = () => ({ loading: true, progress: { loaded: 0, total: 0 } } as Chunk)

const generateChunks = (length: number) => {
  return Array.from({ length }, chunk)
}

const useChunks = (count = 0) => {
  const [chunks, setChunks] = useState(generateChunks(count))

  const setChunk = (chunkIndex: number, data: Partial<Chunk>) => {
    setChunks((chunks) =>
      chunks.map((chunk, index) => {
        if (index === chunkIndex) {
          return { ...chunks[index], ...data }
        }

        return chunk
      })
    )
  }

  return { chunks, setChunks, setChunk }
}

const useRRWebEvents = (walkthrough: Walkthrough) => {
  const { chunkCount = 0 } = walkthrough.meta
  const { chunks, setChunk } = useChunks(chunkCount)

  const fetchChunk = (index = 0) => {
    const url = `${walkthrough.rrwebEventsUrl}/${index + 1}`

    axios
      .get(url, {
        decompress: false,
        responseType: 'arraybuffer',
        validateStatus: (status) => status === 200,
        onDownloadProgress: ({ loaded, total }) => {
          setChunk(index, { progress: { loaded, total } })
        },
      })
      .then((response) => {
        if (response.headers['content-type'] === 'application/json') {
          return JSON.parse(new TextDecoder().decode(response?.data))
        }
        return decompress(response?.data)
      })
      .then((events) => {
        setChunk(index, { events })
      })
      .catch((error) => {
        setChunk(index, { error })
        console.error(error)
      })
      .finally(() => {
        setChunk(index, { loading: false })

        if (index + 1 < chunkCount) {
          fetchChunk(index + 1)
        }
      })
  }

  useEffect(() => {
    chunkCount !== 0 && fetchChunk()
  }, [chunkCount])

  return chunks
}

export default useRRWebEvents
