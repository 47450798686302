import React, { useRef } from 'react'
import { PaperAirplaneIcon } from '@heroicons/react/24/solid'
import Spin from '../../../../components/common/ui/Spin'
import { useChatDispatch, useChatState } from '../../pages/app/OneAgent/AgentProvider'

const Prompt = () => {
  const { addMessageToQueue } = useChatDispatch()
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [prompt, setPrompt] = React.useState('')
  const { isQuerying } = useChatState()
  const handleMessageSend = () => {
    if (prompt.trim() !== '') {
      addMessageToQueue(prompt)
    }
    if (textareaRef.current) {
      textareaRef.current.value = ''
    }
    setPrompt('')
    handleInput()
  }

  const handleInput = () => {
    const element = textareaRef.current
    if (!element) {
      return
    }

    element.style.height = 'auto'
    const maxHeight = parseInt(window.getComputedStyle(element).getPropertyValue('max-height'), 10)

    if (element.clientHeight === element.scrollHeight) {
      element.style.height = `40px`
    } else if (element.scrollHeight <= maxHeight) {
      element.style.height = `${element.scrollHeight}px`
    } else {
      element.style.height = `${maxHeight}px`
    }

    const strippedMessage = element.value.trim()
    setPrompt(strippedMessage)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault() // to prevent a new line
      handleMessageSend()
    }
  }

  const isDisabled = prompt === '' || isQuerying

  return (
    <div className="w-full flex justify-center items-center">
      <textarea
        ref={textareaRef}
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        className="resize-none rounded-xl w-full px-4 mx-4 text-sm h-10 max-h-24 bg-gray-200 text-slate-800 font-bold placeholder-slate-400"
        placeholder="Send a message"
        style={{ transition: 'height 0.2s', maxHeight: '128px' }}
        disabled={isQuerying}
      />

      <button
        type="button"
        className={
          (isDisabled
            ? 'bg-gray-300'
            : 'bg-flowmo-blue-600  hover:bg-flowmo-blue-500 cursor-pointer') +
          ' rounded-full p-1.5 mr-3 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-flowmo-blue-600'
        }
        disabled={isDisabled}
        onClick={handleMessageSend}
      >
        {isQuerying ? (
          <Spin className="h-5 w-5" aria-hidden="true" />
        ) : (
          <PaperAirplaneIcon className="h-5 w-5" aria-hidden="true" />
        )}
      </button>
    </div>
  )
}
export default Prompt
