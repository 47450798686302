import IconButton from '../../../IconButton'
import DragIcon from '../../../icons/DragIcon'
import React from 'react'

const DragButton = () => (
  <IconButton className="cursor-grab drag-handler">
    <DragIcon />
  </IconButton>
)

export default DragButton
