import React, { Fragment } from 'react'

import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { useSort } from '../../../Walkthrough/ui/Walkthrough/List/useQueryParams'
import { SortOption } from './types'
import { clsx } from 'clsx'

interface Props {
  sortOptions: SortOption[]
}

const Sort: React.FC<Props> = ({ sortOptions }) => {
  const [sort, setSort] = useSort(sortOptions[0])

  const label = sortOptions.find(
    (option) => option.sortBy === sort.sortBy && option.sortDirection === sort.sortDirection
  ).label

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
          Sort by {label}
          <ChevronDownIcon
            className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-left absolute left-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {sortOptions.map(({ label, sortBy, sortDirection }, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <div
                    onClick={() => {
                      setSort({ sortBy, sortDirection })
                    }}
                    className={clsx(
                      sortBy === sort.sortBy && sortDirection === sort.sortDirection
                        ? 'font-medium text-gray-900'
                        : 'text-gray-500',
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm cursor-pointer'
                    )}
                  >
                    {label}
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default Sort
