import React from 'react'
import LeftArrowIcon from '../../../../../Labs/components/icons/LeftArrowIcon'

interface Props {
  uuid: string
}

const BackToSkillSetButton: React.FC<Props> = ({ uuid }) => (
  <a href={`/share/skill_set/${uuid}`}>
    <button
      type="button"
      className="mb-3 rounded font-inter font-medium text-[13px] leading-[18px] flex items-center space-x-1.5 bg-[#FFFFFF] text-[#EA6A29] hover:bg-[#FBE6DB] active:bg-[#F3AA85] active:text-[#FFFFFF] transition-colors"
    >
      <LeftArrowIcon />
      <span>Back to Skill Set</span>
    </button>
  </a>
)

export default BackToSkillSetButton
