import React from 'react'
import { useChatState } from './AgentProvider'

const AgentIntro = () => {
  const { agent } = useChatState()
  return (
    <div className="">
      <div className="flex flex-col">
        <div className="flex flex-col space-y-4">
          <p className="flex flex-row space-x-2 font-display text-2xl  tracking-tight sm:text-4xl">
            <span className="text-slate-700">Hi, I&apos;m an expert</span>
            <span className="block font-semibold text-flowmo-blue-900">{agent.jobTitle} –</span>
          </p>
        </div>
        <div className="flex flex-col">
          <div className="my-4 text-base tracking-tight text-slate-800">{agent.description}</div>
        </div>
      </div>
    </div>
  )
}

export default AgentIntro
