import React from 'react'

const LeftArrowIcon: React.FC = () => (
  <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.453 5.85936C2.54041 5.95062 2.65748 6.00112 2.779 5.99998C2.90052 5.99884 3.01676 5.94615 3.10269 5.85325C3.18862 5.76036 3.23736 5.6347 3.23842 5.50333C3.23947 5.37196 3.19276 5.2454 3.10834 5.15091L1.58216 3.50103H10.5363C10.6592 3.50103 10.7771 3.44824 10.864 3.35428C10.951 3.26032 10.9998 3.13288 10.9998 3C10.9998 2.86712 10.951 2.73968 10.864 2.64572C10.7771 2.55176 10.6592 2.49897 10.5363 2.49897H1.58216L3.10834 0.849095C3.19276 0.7546 3.23947 0.62804 3.23842 0.496673C3.23736 0.365305 3.18862 0.239642 3.10269 0.146747C3.01676 0.053853 2.90052 0.00116049 2.779 1.894e-05C2.65748 -0.00112261 2.54041 0.0493782 2.453 0.140644L0.135695 2.64577C0.0488093 2.73973 -5.61773e-07 2.86715 -5.61773e-07 3C-5.61773e-07 3.13285 0.0488093 3.26027 0.135695 3.35423L2.453 5.85936Z"
      fill="currentcolor"
    />
  </svg>
)

export default LeftArrowIcon
