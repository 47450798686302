import React, { useState } from 'react'
import useNavigationContext from '../../../../../../../views/Walkthrough/modules/PlaygroundContext/hooks/useNavigationContext'
import ReactHtmlParser from 'react-html-parser'
import { textShortener } from '../../../../../../../../common/utils/textShortener'

const SIZE_LIMIT = 70

const Description = () => {
  const { step } = useNavigationContext()
  const isCollapsible = (step?.description ?? []).length > SIZE_LIMIT

  const [collapsed, setCollapsed] = useState(true)
  const toggleCollapsed = () => setCollapsed(!collapsed)

  return (
    <div className="font-inter text-[12px] break-all text-[#777D87] leading-[140%]">
      {isCollapsible && collapsed
        ? ReactHtmlParser(textShortener(step.description, SIZE_LIMIT))
        : ReactHtmlParser(step.description)}{' '}
      {isCollapsible && (
        <span className="cursor-pointer text-[#1B72F2] hover:underline" onClick={toggleCollapsed}>
          {collapsed ? 'read more' : 'hide'}
        </span>
      )}
    </div>
  )
}

export default Description
