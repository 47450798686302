import React from 'react'

const SuccessImage = () => (
  <svg
    width="138"
    height="102"
    viewBox="0 0 211 156"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="mx-auto mb-6 mt-3"
  >
    <g opacity="0.5">
      <path
        d="M191.63 117.885C187.621 106.092 176.881 96.415 164.475 95.2414C152.764 94.131 141.455 100.21 129.688 100.323C110.663 100.498 94.8657 85.4164 76.2274 81.5932C54.5647 77.1516 30.6584 90.603 23.2242 111.413C18.4204 124.85 20.8821 140.262 28.4992 152.259H188.212C193.649 141.717 195.442 129.088 191.63 117.885Z"
        fill="#E4ECF8"
      />
    </g>
    <path
      d="M42.4674 119.769C57.9056 117.365 71.6135 106.352 77.5286 91.9453C78.7735 88.9163 79.6456 85.7818 80.2294 82.5912C80.1098 82.556 79.9973 82.5209 79.8777 82.4928C79.4838 84.6363 78.9704 86.7587 78.2882 88.846C75.8757 96.2464 71.4236 102.895 65.6633 108.116C59.9452 113.303 52.94 117.098 45.4003 118.848C37.5933 120.654 29.3502 120.148 21.7402 117.695C21.6698 117.674 21.6065 117.646 21.5362 117.625C21.5151 117.738 21.494 117.857 21.4729 117.97C28.2108 120.169 35.4341 120.865 42.4674 119.769Z"
      fill="white"
    />
    <path
      d="M67.5483 130.184C79.0689 131.723 90.913 132.545 102.3 129.636C107.462 128.315 112.456 126.213 116.831 123.142C121.22 120.057 124.856 116.03 127.74 111.525C130.046 107.92 131.84 104.089 133.289 100.112C133.162 100.126 133.036 100.14 132.909 100.154C131.924 102.839 130.771 105.467 129.399 108.011C126.741 112.938 123.302 117.485 118.976 121.069C114.714 124.604 109.671 127.092 104.382 128.694C93.2059 132.067 81.3758 131.568 69.9255 130.142C57.3992 128.582 44.8658 125.939 32.1847 126.508C28.3937 126.677 24.5605 127.127 20.8118 127.949C20.8188 128.069 20.8188 128.195 20.8258 128.315C23.8783 127.64 26.9941 127.204 30.0958 126.979C42.6643 126.066 55.1415 128.525 67.5483 130.184Z"
      fill="white"
    />
    <path
      d="M180.321 138.456C175.89 137.486 171.522 136.13 167.478 134.056C163.715 132.117 160.163 129.537 157.652 126.094C155.071 122.552 153.833 118.251 154.22 113.879C154.93 105.846 160.17 99.4229 166.943 95.5998C166.768 95.5646 166.599 95.5365 166.423 95.5084C160.733 98.7904 156.204 103.822 154.466 110.436C153.362 114.652 153.615 119.129 155.465 123.1C157.244 126.909 160.297 129.959 163.75 132.278C167.401 134.731 171.522 136.446 175.735 137.662C180.792 139.123 186.011 140.009 191.258 140.346C191.68 140.374 192.102 140.388 192.524 140.41C192.552 140.29 192.573 140.178 192.601 140.058C188.479 139.868 184.365 139.341 180.321 138.456Z"
      fill="white"
    />
    <path
      d="M88.1771 91.5517C88.9789 89.865 89.8722 88.2205 90.8498 86.6392C90.7443 86.59 90.6318 86.5408 90.5263 86.4916C89.8229 87.6302 89.1618 88.7968 88.5569 89.9915C87.0377 92.9643 85.8139 96.0847 84.9207 99.2964C83.9431 102.81 83.1202 106.669 84.0204 110.281C84.7167 113.071 86.5454 115.678 89.3236 116.683C92.4675 117.822 95.8857 116.641 98.6709 115.102C101.709 113.422 104.487 111.181 107.111 108.932C109.755 106.669 112.252 104.23 114.58 101.644C115.516 100.604 116.423 99.5284 117.309 98.439C117.183 98.4039 117.063 98.3617 116.936 98.3266C112.681 103.576 107.772 108.292 102.307 112.298C99.5078 114.35 96.3499 116.423 92.8051 116.754C89.2814 117.077 86.3274 115.053 84.9348 111.883C83.3874 108.369 84.0134 104.356 84.9066 100.758C85.6733 97.5816 86.7775 94.4964 88.1771 91.5517Z"
      fill="white"
    />
    <path
      d="M182.867 129.277C186.51 129.474 190.168 128.568 193.508 126.979C193.501 126.874 193.494 126.768 193.48 126.663C190.716 127.97 187.72 128.778 184.604 128.933C181.109 129.102 177.543 128.188 174.94 125.756C172.591 123.564 171.417 120.38 172.317 117.232C172.718 115.833 173.506 114.526 174.638 113.605C176.115 112.41 178.021 112.108 179.864 112.052C181.524 112.003 183.197 112.122 184.85 111.912C186.06 111.757 187.235 111.427 188.353 110.949C188.29 110.85 188.233 110.745 188.17 110.646C187.804 110.801 187.432 110.942 187.052 111.061C183.641 112.15 179.99 111.096 176.614 112.172C173.97 113.015 172.211 115.376 171.768 118.068C171.304 120.886 172.359 123.627 174.328 125.644C176.579 127.949 179.695 129.109 182.867 129.277Z"
      fill="white"
    />
    <path
      d="M149.318 140.88C147.735 137.12 146.16 133.361 144.577 129.601C143.473 126.972 142.306 124.098 139.921 122.362C137.403 120.521 133.978 120.521 131.165 121.645C128.176 122.84 125.798 125.159 123.773 127.591C119.736 132.433 116.838 138.196 115.234 144.289C114.545 146.903 114.095 149.574 113.891 152.259H114.25C114.854 144.387 117.576 136.685 122.176 130.233C124.061 127.598 126.255 124.976 128.984 123.184C131.791 121.343 135.554 120.457 138.69 122.018C141.56 123.444 142.924 126.572 144.099 129.369C145.569 132.862 147.032 136.354 148.502 139.847C150.239 143.987 151.976 148.119 153.714 152.259H154.093C152.504 148.463 150.914 144.675 149.318 140.88Z"
      fill="white"
    />
    <g opacity="0.5">
      <path
        d="M134.365 75.1135C132.086 75.1838 129.8 75.0854 127.149 74.1296C121.508 72.0845 114.475 75.4368 114.425 81.9376C114.39 86.9906 119.039 90.3289 123.344 91.868C131.467 94.7705 148.284 93.2525 147.496 81.1926C147.243 77.3343 145.189 75.5422 141.497 75.1135C138.887 74.8184 136.63 75.0503 134.365 75.1135Z"
        fill="#E4ECF8"
      />
    </g>
    <path
      d="M164.749 41.8577C162.533 41.0916 161.021 39.7634 159.319 37.8166L160.044 36.2775C161.837 38.6459 162.421 39.932 164.657 39.7423L164.749 41.8577Z"
      fill="#FDB813"
    />
    <path
      d="M190.16 75.8796C188.514 73.7431 187.776 71.5434 187.171 68.6549L188.578 67.7272C189.063 71.0514 188.971 72.6046 191.103 73.8907L190.16 75.8796Z"
      fill="#1B72F2"
    />
    <path
      d="M135.012 47.6345C132.684 48.2108 130.742 47.8805 128.365 47.1004L128.358 45.2943C130.996 46.4117 132.072 47.2339 134.02 45.7792L135.012 47.6345Z"
      fill="#29BC7E"
    />
    <path
      d="M75.0949 40.2974C73.9906 41.8646 72.7528 42.701 71.0929 43.5092L70.3755 42.4831C72.3167 41.7241 73.2592 41.6187 73.8007 39.7633L75.0949 40.2974Z"
      fill="#F15E3B"
    />
    <path
      d="M36.7631 85.8522C34.1467 85.9857 32.1844 85.2619 29.8845 83.9898L30.3628 82.1415C32.8526 83.8001 33.774 84.8613 36.2145 83.7579L36.7631 85.8522Z"
      fill="#1B72F2"
    />
    <path
      d="M41.5317 41.0143C39.5342 39.5665 38.4863 37.7885 37.4875 35.3428L38.845 34.0707C39.7663 36.9522 39.8648 38.3929 42.2702 38.92L41.5317 41.0143Z"
      fill="#29BC7E"
    />
    <path
      d="M53.4884 57.0168C51.2237 58.598 49.0926 59.0548 46.3566 59.2235L45.7939 57.333C48.9027 57.4033 50.2882 57.8179 51.8778 55.4847L53.4884 57.0168Z"
      fill="#1B72F2"
    />
    <path
      d="M85.898 13.4791C83.6262 14.8144 81.5514 15.0814 78.9139 15.0252L78.4849 13.1628C81.467 13.4791 82.7752 13.9921 84.4421 11.8767L85.898 13.4791Z"
      fill="#FDB813"
    />
    <path
      d="M118.061 18.0753C116.169 17.1406 114.946 15.7912 113.602 13.8726L114.376 12.6006C115.754 14.9057 116.162 16.1075 118.16 16.1989L118.061 18.0753Z"
      fill="#29BC7E"
    />
    <path
      d="M147.517 25.4546C149.585 24.2388 150.738 22.6434 151.885 20.4297L150.619 19.1436C149.522 21.779 149.332 23.1143 146.919 23.4446L147.517 25.4546Z"
      fill="#F15E3B"
    />
    <g opacity="0.837">
      <path
        d="M79.3501 90.9193C77.2612 89.6754 75.9671 88.0238 74.5745 85.7116L75.5451 84.3342C76.9517 87.0891 77.3315 88.4947 79.5963 88.7898L79.3501 90.9193Z"
        fill="#F15E3B"
      />
    </g>
    <path
      d="M148.389 72.2251C147.559 74.8114 146.279 76.5262 144.429 78.4237L143.114 77.4609C145.337 75.4861 146.518 74.8746 146.504 72.197L148.389 72.2251Z"
      fill="#F15E3B"
    />
    <path
      d="M62.6741 30.3179C63.1596 30.3179 63.5533 29.9246 63.5533 29.4394C63.5533 28.9542 63.1596 28.5609 62.6741 28.5609C62.1885 28.5609 61.7949 28.9542 61.7949 29.4394C61.7949 29.9246 62.1885 30.3179 62.6741 30.3179Z"
      fill="#F15E3B"
    />
    <path
      d="M132.508 30.3179C132.993 30.3179 133.387 29.9246 133.387 29.4394C133.387 28.9542 132.993 28.5609 132.508 28.5609C132.022 28.5609 131.629 28.9542 131.629 29.4394C131.629 29.9246 132.022 30.3179 132.508 30.3179Z"
      fill="#FDB813"
    />
    <path
      d="M155.479 57.066C155.964 57.066 156.358 56.6727 156.358 56.1875C156.358 55.7023 155.964 55.309 155.479 55.309C154.993 55.309 154.6 55.7023 154.6 56.1875C154.6 56.6727 154.993 57.066 155.479 57.066Z"
      fill="#F15E3B"
    />
    <path
      d="M64.0806 65.9703C64.5661 65.9703 64.9597 65.577 64.9597 65.0918C64.9597 64.6066 64.5661 64.2133 64.0806 64.2133C63.595 64.2133 63.2014 64.6066 63.2014 65.0918C63.2014 65.577 63.595 65.9703 64.0806 65.9703Z"
      fill="#29BC7E"
    />
    <path
      d="M24.0114 55.6333L22.2559 57.3875L24.0114 59.1417L25.767 57.3875L24.0114 55.6333Z"
      fill="#FDB813"
    />
    <path
      d="M176.636 52.7613L174.881 54.5155L176.636 56.2697L178.392 54.5155L176.636 52.7613Z"
      fill="#29BC7E"
    />
    <path
      d="M116.064 117.077L101.259 117.098C101.252 113.971 103.791 111.434 106.921 111.427L110.388 111.42C113.518 111.42 116.057 113.95 116.064 117.077Z"
      fill="#FDB813"
    />
    <path d="M113.448 96.4502H103.868V113.394H113.448V96.4502Z" fill="#FDB813" />
    <path d="M103.868 103.773V96.4502H113.448L113.441 100.006L103.868 103.773Z" fill="#E89623" />
    <path
      d="M125.13 83.3784L124.518 81.3614C135.736 77.9669 140.491 70.9601 139.056 59.9263C139.042 59.9123 138.922 59.842 138.775 59.8842L137.227 60.2988C136.608 60.4675 136.193 60.9313 136.193 61.4514V63.8409C136.193 65.2886 134.97 66.4482 133.401 66.4834C131.784 66.5115 129.019 66.5677 128.654 66.5677V64.4593C128.907 64.4593 130.665 64.4312 133.366 64.375C133.753 64.368 134.083 64.122 134.083 63.8409V61.4514C134.083 59.9685 135.124 58.6894 136.672 58.2678L138.219 57.8531C138.901 57.6704 139.619 57.7828 140.195 58.1694C140.716 58.5208 141.06 59.0619 141.145 59.6593C141.897 65.4573 141.053 70.3487 138.634 74.207C136.018 78.3745 131.474 81.4598 125.13 83.3784Z"
      fill="#FDB813"
    />
    <path
      d="M92.1647 83.3784C85.8207 81.4598 81.2771 78.3745 78.6537 74.2C76.2342 70.3487 75.3902 65.4503 76.1428 59.6523C76.2202 59.0549 76.5648 58.5067 77.0923 58.1623C77.669 57.7758 78.3864 57.6634 79.0687 57.8461L80.609 58.2607C82.1563 58.6824 83.1972 59.9615 83.1972 61.4444V63.8338C83.1972 64.115 83.5278 64.3609 83.9146 64.368C86.6084 64.4172 88.3738 64.4523 88.627 64.4523V66.5607C88.2612 66.5607 85.4901 66.5115 83.8795 66.4763C82.3181 66.4482 81.0872 65.2886 81.0872 63.8338V61.4514C81.0872 60.9313 80.6723 60.4675 80.0533 60.2988L78.513 59.8842C78.3653 59.842 78.2458 59.9123 78.2247 59.9474C76.7969 70.9601 81.5514 77.9739 92.7696 81.3614L92.1647 83.3784Z"
      fill="#FDB813"
    />
    <path
      d="M129.744 60.1442L127.451 88.7406C127.177 92.1702 125.503 95.1782 123.02 97.2163C120.903 98.9592 118.188 99.9993 115.241 99.9993H102.068C95.6813 99.9993 90.3712 95.1009 89.8648 88.7406L88.6761 73.8978L87.5719 60.1372C87.4031 57.9866 89.0981 56.1383 91.2644 56.1383H126.044C128.211 56.1453 129.913 57.9937 129.744 60.1442Z"
      fill="#FDB813"
    />
    <path
      d="M123.02 97.2163C120.903 98.9592 118.188 99.9993 115.241 99.9993H102.068C95.6815 99.9993 90.3713 95.1009 89.8649 88.7406L88.6763 73.8978C97.0178 84.4326 110.325 93.4704 123.02 97.2163Z"
      fill="#E89623"
    />
    <path d="M115.797 99.8236H102.075V100.175H115.797V99.8236Z" fill="#052140" />
    <path d="M106.013 111.258H103.46V111.609H106.013V111.258Z" fill="#052140" />
    <path d="M113.926 111.258H111.373V111.609H113.926V111.258Z" fill="#052140" />
    <path
      d="M119.51 125.447L97.8054 125.475C97.7984 120.176 102.096 115.875 107.399 115.868H109.889C115.199 115.854 119.503 120.148 119.51 125.447Z"
      fill="#FDB813"
    />
    <path d="M116.05 115.728H101.266V116.079H116.05V115.728Z" fill="#E89623" />
    <path
      opacity="0.6"
      d="M119.939 72.2603C121.008 72.2603 121.873 70.5706 121.873 68.4863C121.873 66.402 121.008 64.7123 119.939 64.7123C118.871 64.7123 118.005 66.402 118.005 68.4863C118.005 70.5706 118.871 72.2603 119.939 72.2603Z"
      fill="white"
    />
    <path d="M161.921 143.27H55.4648V152.315H161.921V143.27Z" fill="white" />
    <path d="M144.331 134.232H73.0552V143.277H144.331V134.232Z" fill="white" />
    <path d="M132.009 125.187H85.3848V134.232H132.009V125.187Z" fill="white" />
    <path d="M134.92 143.27H82.4729V152.308H134.92V143.27Z" fill="#F15E3B" />
    <path d="M127.367 134.225H90.0198V143.27H127.367V134.225Z" fill="#F15E3B" />
    <path d="M119.623 125.187H97.7634V134.225H119.623V125.187Z" fill="#F15E3B" />
    <path opacity="0.1" d="M97.7634 132.117H85.3848V134.225H97.7634V132.117Z" fill="#1E427E" />
    <path opacity="0.1" d="M132.002 132.117H119.623V134.225H132.002V132.117Z" fill="#1E427E" />
    <path opacity="0.3" d="M119.623 132.117H97.7634V134.225H119.623V132.117Z" fill="#4A1B1C" />
    <path opacity="0.1" d="M90.0196 141.148H73.0552V143.256H90.0196V141.148Z" fill="#1E427E" />
    <path opacity="0.1" d="M144.331 141.148H127.367V143.256H144.331V141.148Z" fill="#1E427E" />
    <path opacity="0.3" d="M127.367 141.148H90.0198V143.256H127.367V141.148Z" fill="#4A1B1C" />
    <path opacity="0.1" d="M82.4728 150.206H55.4648V152.315H82.4728V150.206Z" fill="#1E427E" />
    <path opacity="0.1" d="M161.921 150.206H134.913V152.315H161.921V150.206Z" fill="#1E427E" />
    <path opacity="0.3" d="M134.92 150.206H82.4729V152.315H134.92V150.206Z" fill="#4A1B1C" />
    <path
      d="M55.6407 152.315H55.2891V143.094L72.8794 143.08V134.056L85.2089 134.049V125.012H97.679V125.363H85.5605V134.401H73.2311V143.432L55.6407 143.446V152.315Z"
      fill="#9CBBE2"
    />
    <path
      d="M162.097 152.315H161.746V143.446L144.155 143.432V134.401H131.826V125.363H119.503V125.012H132.177V134.049L144.507 134.056V143.08L162.097 143.094V152.315Z"
      fill="#9CBBE2"
    />
    <path
      d="M106.464 64.0095L105.156 68.3528L110.269 69.9621L108.56 67.1088L109.059 64.5999L106.464 64.0095Z"
      fill="#052140"
    />
    <path
      d="M108.479 66.9491L107.444 67.4577L107.599 67.773L108.634 67.2644L108.479 66.9491Z"
      fill="white"
    />
    <path
      d="M105.556 50.4528L109.157 50.7971C109.896 50.8674 110.62 51.1134 111.211 51.5632C112.358 52.4487 112.808 53.8261 112.562 55.2317C112.498 55.5972 112.421 55.9345 112.365 56.2226L109.418 66.6591L104.951 64.2766L106.942 56.5038L102.405 56.3632L99.9297 51.1837L100.823 47.0513L105.556 50.4528Z"
      fill="#1B72F2"
    />
    <path
      d="M104.937 68.6831L103.826 73.441L109.439 74.8255L107.406 71.8738L107.772 69.1399L104.937 68.6831Z"
      fill="#1B72F2"
    />
    <path
      d="M107.32 71.7182L106.241 72.3376L106.416 72.6424L107.495 72.0229L107.32 71.7182Z"
      fill="#1B72F2"
    />
    <path
      d="M100.141 56.4335L103.995 70.4822L108.321 69.9903L105.789 55.0982C105.542 53.798 105.486 53.0039 103.503 52.013L101.372 50.8323L100.823 47.0443L97.5947 49.1456L97.0742 54.0792C96.9476 55.2739 97.8409 56.3281 99.0154 56.3773L100.141 56.4335Z"
      fill="#1B72F2"
    />
    <path
      d="M105.746 50.3825L99.3247 51.1134C98.6073 51.1977 97.9603 50.6847 97.8759 49.9678L96.7576 40.4661L102.11 39.1589L107.793 40.0093L106.752 49.3845C106.689 49.9046 106.274 50.3192 105.746 50.3825Z"
      fill="white"
    />
    <path
      d="M92.3899 29.1021C92.3899 29.1021 92.8893 26.6494 92.5025 26.7407C92.1156 26.8321 91.7147 27.7738 91.7147 27.7738C91.7147 27.7738 90.8637 25.7357 90.5402 25.7568C90.2166 25.7779 90.3151 26.2909 90.3151 26.2909C90.3151 26.2909 89.7102 26.0028 89.5485 26.6002C89.5485 26.6002 88.9436 26.312 88.9436 27.4224C88.9436 27.823 89.2671 29.2286 89.6821 30.852L92.5869 29.7487L92.3899 29.1021Z"
      fill="#FAB99A"
    />
    <path
      d="M94.6337 35.378L92.6011 29.7487L89.6963 30.852C90.2027 32.855 90.8498 35.1953 91.1803 36.397C91.328 36.9241 91.5953 37.4161 91.961 37.8237L97.7987 44.7672L100.317 41.1971L94.6337 35.378Z"
      fill="#29BC7E"
    />
    <path
      d="M91.328 28.8912C91.0397 28.5539 91.0678 28.2236 91.3984 27.9214L91.4546 27.9846C91.1592 28.2587 91.1381 28.5328 91.3913 28.835L91.328 28.8912Z"
      fill="#974346"
    />
    <path
      d="M90.2824 26.2675L90.196 26.2975L90.6132 27.4993L90.6996 27.4694L90.2824 26.2675Z"
      fill="#974346"
    />
    <path
      d="M89.9917 27.6754L89.6189 26.5791L89.7033 26.551L90.069 27.6473L89.9917 27.6754Z"
      fill="#974346"
    />
    <path
      d="M89.5977 27.9847L89.1335 26.7196L89.2179 26.6915L89.6751 27.9495L89.5977 27.9847Z"
      fill="#974346"
    />
    <path
      d="M114.974 25.6514C114.911 24.541 114.158 24.7659 114.158 24.7659C114.011 24.2809 113.539 24.6042 113.539 24.6042C113.539 24.6042 113.56 24.2809 113.237 24.2809C112.913 24.2809 112.231 26.1714 112.231 26.1714C112.231 26.1714 111.774 25.2578 111.38 25.1946C110.986 25.1313 111.64 27.5419 111.64 27.5419L111.45 28.3009L114.404 29.341C114.742 27.6051 114.995 26.0731 114.974 25.6514Z"
      fill="#FAB99A"
    />
    <path
      d="M111.457 28.3009L109.748 34.0638L104.825 40.6208L107.638 43.5444L112.625 36.2213C112.963 35.7926 113.202 35.2866 113.314 34.7525C113.56 33.5718 114.032 31.3229 114.404 29.341L111.457 28.3009Z"
      fill="#29BC7E"
    />
    <path
      d="M112.716 27.2748L112.646 27.2256C112.864 26.8953 112.815 26.6212 112.491 26.3823L112.54 26.312C112.906 26.5861 112.962 26.9094 112.716 27.2748Z"
      fill="#974346"
    />
    <path
      d="M113.48 24.5506L113.192 25.7897L113.281 25.8103L113.569 24.5712L113.48 24.5506Z"
      fill="#974346"
    />
    <path
      d="M114.079 24.7763L113.831 25.9019L113.913 25.92L114.161 24.7944L114.079 24.7763Z"
      fill="#974346"
    />
    <path
      d="M114.348 26.1855L114.257 26.1644L114.58 24.8572L114.665 24.8783L114.348 26.1855Z"
      fill="#974346"
    />
    <path
      d="M102.033 40.7332C101.421 40.7332 100.921 40.2342 100.921 39.6228V37.6479H103.151V39.6228C103.144 40.2342 102.652 40.7332 102.033 40.7332Z"
      fill="#FAB99A"
    />
    <path
      d="M103.123 37.9291C102.771 39.173 101.35 39.2222 100.907 37.9291H103.123Z"
      fill="#052140"
    />
    <path
      d="M102.033 38.414C102.941 38.414 103.678 37.5581 103.678 36.5024C103.678 35.4467 102.941 34.5908 102.033 34.5908C101.124 34.5908 100.387 35.4467 100.387 36.5024C100.387 37.5581 101.124 38.414 102.033 38.414Z"
      fill="#FAB99A"
    />
    <path
      d="M100.527 36.5375C100.626 35.2163 101.498 34.9071 101.498 34.9071L102.637 34.886C103.101 35.2374 103.502 35.891 103.523 36.5867L103.91 36.5656C104.149 35.2725 103.938 34.0145 103.193 33.7264L103.559 33.5437C100.478 32.862 99.7114 34.0989 100.084 36.2634L100.527 36.5375Z"
      fill="#052140"
    />
    <path
      d="M100.731 36.418C100.851 36.6781 100.774 36.9733 100.541 37.0787C100.316 37.1841 100.035 37.0646 99.9085 36.8046C99.7889 36.5446 99.8663 36.2494 100.098 36.144C100.33 36.0315 100.612 36.158 100.731 36.418Z"
      fill="#FAB99A"
    />
    <path
      d="M100.408 36.7413C100.359 36.5937 100.26 36.4953 100.105 36.4391L100.134 36.3688C100.309 36.4321 100.422 36.5445 100.478 36.7132L100.408 36.7413Z"
      fill="#052140"
    />
    <path
      d="M103.383 36.418C103.263 36.6781 103.341 36.9733 103.573 37.0787C103.798 37.1841 104.079 37.0646 104.206 36.8046C104.325 36.5446 104.248 36.2494 104.016 36.144C103.791 36.0315 103.509 36.158 103.383 36.418Z"
      fill="#FAB99A"
    />
    <path
      d="M103.713 36.7413L103.643 36.7132C103.706 36.5375 103.819 36.4251 103.988 36.3688L104.016 36.4391C103.868 36.4883 103.77 36.5867 103.713 36.7413Z"
      fill="#052140"
    />
    <path
      d="M100.893 39.4471C102.04 43.2913 101.653 49.1737 100.345 53.5731L95.7097 54.29L96.7577 40.4591L100.893 39.4471Z"
      fill="#29BC7E"
    />
    <path
      d="M103.137 39.3136C103.489 43.7693 104.319 50.2068 105.957 53.292L108.764 51.8584L107.793 39.9953L103.137 39.3136Z"
      fill="#29BC7E"
    />
    <path
      d="M108.236 70.0114C108.215 69.8638 105.796 55.056 105.402 53.8051C105.022 52.5892 102.574 51.5843 102.553 51.5772L102.617 51.4156C102.722 51.4578 105.163 52.4557 105.571 53.7559C105.964 55.0209 108.306 69.3788 108.412 69.9903L108.236 70.0114Z"
      fill="#052140"
    />
    <path
      d="M99.8939 55.3741L99.7249 55.4224L101.483 61.5715L101.652 61.5232L99.8939 55.3741Z"
      fill="#052140"
    />
    <path
      d="M108.222 56.7146L106.935 56.5951L106.949 56.4194L108.243 56.5389L108.222 56.7146Z"
      fill="#052140"
    />
    <path
      d="M48.7339 148.232L48.0798 152.378H53.6713L51.4769 150.185L51.6457 148.133L48.7339 148.232Z"
      fill="#052140"
    />
    <path
      d="M51.3852 150.046L50.2759 150.698L50.4542 151.001L51.5636 150.349L51.3852 150.046Z"
      fill="white"
    />
    <path
      d="M60.2757 118.04C60.1913 118.532 60.1139 119.024 60.0225 119.509C59.8677 120.429 59.706 121.336 59.5442 122.235C58.9534 125.468 58.3134 128.589 57.6171 131.618C57.6171 131.625 57.6171 131.625 57.6171 131.625C56.2245 137.732 54.635 143.446 52.8766 148.843H47.9041C49.9015 139.299 51.8427 129.861 52.4687 122.552C52.5601 121.476 52.6234 120.443 52.6586 119.474L60.2757 118.04Z"
      fill="#29BC7E"
    />
    <path
      d="M60.0789 148.035L59.8328 152.301L65.797 152.378L63.4338 150.185L63.6097 148.133L60.0789 148.035Z"
      fill="#052140"
    />
    <path
      d="M63.3503 150.041L62.1519 150.693L62.32 151.001L63.5184 150.349L63.3503 150.041Z"
      fill="white"
    />
    <path
      d="M64.2567 148.738H59.059C58.6933 142.841 58.2502 137.071 57.6172 131.625C57.6172 131.618 57.6172 131.618 57.6172 131.618C57.2444 128.42 56.8084 125.328 56.2809 122.383C56.1121 121.434 55.9292 120.5 55.7463 119.586L60.0296 119.509L62.1607 119.474C62.3014 120.345 62.435 121.216 62.5546 122.095C63.7573 130.69 64.1019 139.672 64.2567 148.738Z"
      fill="#29BC7E"
    />
    <path
      opacity="0.3"
      d="M62.5475 122.102L59.5442 122.243L56.2808 122.39L52.4617 122.566C52.5531 121.491 52.6164 120.457 52.6516 119.488L60.2757 118.054C60.1913 118.546 60.1139 119.038 60.0225 119.523L62.1536 119.488C62.3013 120.345 62.4349 121.223 62.5475 122.102Z"
      fill="#4A1B1C"
    />
    <path
      d="M57.2865 133.874L57.1177 133.839L58.883 125.082L59.0518 125.117L57.2865 133.874Z"
      fill="#052140"
    />
    <path
      d="M58.9042 107.66C56.2737 107.941 54.1215 107.723 52.0326 106.465L50 109.916L52.3702 112.031L51.892 119.502C51.8638 119.944 52.103 120.352 52.4968 120.556C52.6516 120.633 52.8204 120.668 52.9892 120.668H61.9637C62.456 120.668 62.8921 120.366 63.0609 119.902C63.1031 119.79 63.1242 119.677 63.1312 119.558L63.5181 111.637L67.5411 110.689L66.6127 107.526C63.5392 107.885 60.7751 108.074 58.9042 107.66Z"
      fill="#1B72F2"
    />
    <path
      d="M52.0256 106.465L49.993 109.916L47.1726 107.393C47.1093 107.336 47.0531 107.28 46.9898 107.217C46.4412 106.662 46.0614 105.973 45.8926 105.207C45.8715 105.109 45.8433 105.003 45.8293 104.898C45.2103 102.15 44.0358 96.8016 44.092 96.3307C44.1764 95.6982 44.8235 95.9723 44.8235 95.9723C44.8727 95.3679 45.3791 95.6912 45.3791 95.6912C45.3791 95.3468 45.4917 95.2625 45.8363 95.2765C46.1809 95.2976 46.6381 97.3708 46.6381 97.3708C46.6381 97.3708 47.1445 96.2956 47.5243 96.3729C47.9041 96.4502 47.2008 98.5304 47.2008 98.5304L49.6554 104.539C50.4502 105.362 51.2379 105.987 52.0256 106.465Z"
      fill="#FAB99A"
    />
    <path
      opacity="0.2"
      d="M47.3767 106.268C47.3767 106.641 47.229 106.978 46.9898 107.224C46.4412 106.669 46.0614 105.98 45.8926 105.214C45.8715 105.116 45.8434 105.01 45.8293 104.905C45.8856 104.898 45.9489 104.891 46.0052 104.891C46.7648 104.891 47.3767 105.509 47.3767 106.268Z"
      fill="#F15E3B"
    />
    <path
      d="M46.2019 98.5866C45.8924 98.2633 45.8995 97.933 46.216 97.6097L46.2793 97.673C45.9979 97.9611 45.9909 98.2423 46.2652 98.5234L46.2019 98.5866Z"
      fill="#974346"
    />
    <path
      d="M45.5198 96.5767L45.3369 95.7122L45.4283 95.6912L45.6042 96.5556L45.5198 96.5767Z"
      fill="#974346"
    />
    <path
      d="M44.9993 96.7102L44.7812 95.9934L44.8657 95.9653L45.0837 96.6821L44.9993 96.7102Z"
      fill="#974346"
    />
    <path
      d="M44.4909 96.2167L44.4094 96.2385L44.5898 96.9106L44.6713 96.8887L44.4909 96.2167Z"
      fill="#974346"
    />
    <path
      d="M71.6768 108.475C71.6346 108.538 71.5923 108.601 71.5572 108.672L71.297 109.065C70.9383 109.599 70.3967 109.993 69.7777 110.162C69.7496 110.176 69.7144 110.183 69.6722 110.19L67.5341 110.696L66.6057 107.533C67.4638 107.428 68.3429 107.322 69.2291 107.203L71.8807 102.206C71.8807 102.206 71.8807 100.084 72.1761 100.02C72.4715 99.9571 72.7036 101.257 72.7036 101.257C72.7036 101.257 73.843 99.5284 74.2087 99.5284C74.5674 99.5284 74.469 100.02 74.469 100.02C74.469 100.02 74.7995 99.6057 75.0035 99.8025C75.109 99.9079 75.0879 100.273 75.0879 100.273C75.0879 100.273 75.2215 100.147 75.4818 100.259C75.9741 100.47 74.3776 104.139 71.6768 108.475Z"
      fill="#FAB99A"
    />
    <path
      opacity="0.2"
      d="M71.6768 108.475C71.6346 108.538 71.5924 108.601 71.5572 108.672L71.297 109.065C70.9383 109.599 70.3967 109.993 69.7778 110.162C69.7215 110.014 69.6934 109.866 69.6934 109.705C69.6934 108.946 70.3123 108.334 71.0649 108.334C71.2829 108.327 71.4869 108.377 71.6768 108.475Z"
      fill="#F15E3B"
    />
    <path
      d="M72.9287 102.466L72.8865 102.389C73.2311 102.192 73.3014 101.918 73.1115 101.567L73.1889 101.524C73.3999 101.925 73.3155 102.241 72.9287 102.466Z"
      fill="#974346"
    />
    <path
      d="M74.1174 100.716L74.04 100.674L74.455 99.8939L74.5394 99.936L74.1174 100.716Z"
      fill="#974346"
    />
    <path
      d="M74.7082 100.948L74.6309 100.906L75.0247 100.154L75.1021 100.196L74.7082 100.948Z"
      fill="#974346"
    />
    <path
      d="M75.095 101.032L75.0247 100.99L75.3834 100.393L75.4607 100.442L75.095 101.032Z"
      fill="#974346"
    />
    <path
      d="M50.048 109.859L49.9351 109.994L53.5672 113.038L53.6802 112.903L50.048 109.859Z"
      fill="#052140"
    />
    <path
      d="M63.4617 114.765L63.2859 114.758L63.5532 109.691L63.729 109.705L63.4617 114.765Z"
      fill="#052140"
    />
    <path
      d="M58.9042 110.935C58.3274 110.935 57.7437 110.675 57.3076 110.225L57.4342 110.105C57.8914 110.576 58.5103 110.815 59.0941 110.752C59.5934 110.696 60.0084 110.422 60.2827 109.972L60.4304 110.063C60.135 110.555 59.6638 110.864 59.1152 110.928C59.0449 110.935 58.9745 110.935 58.9042 110.935Z"
      fill="#052140"
    />
    <path
      d="M58.9464 109.522C58.243 109.599 57.6171 109.093 57.5327 108.398L57.1951 105.383L59.7341 105.102L60.0717 108.116C60.1491 108.812 59.6427 109.438 58.9464 109.522Z"
      fill="#FAB99A"
    />
    <path
      d="M58.0391 106.514C58.6228 107.182 59.284 107.66 60.0576 107.863L59.8748 106.24L58.0391 106.514Z"
      fill="#052140"
    />
    <path
      d="M61.0845 103.253C61.0845 103.253 61.5698 106.451 60.4585 106.859C59.4176 107.238 58.039 106.507 58.039 106.507L57.1177 103.822L58.3133 102.733L61.0845 103.253Z"
      fill="#FAB99A"
    />
    <path
      d="M57.603 104.813C57.6663 104.202 57.9054 103.675 58.5314 103.323C60.353 104.061 61.4854 103.499 62.3013 102.318C62.3013 102.318 61.2603 102.543 60.6344 102.136C60.0084 101.728 58.3907 100.554 57.3709 102.262C55.9431 102.923 56.2456 104.075 56.8223 104.813H57.603Z"
      fill="#052140"
    />
    <path
      d="M57.8983 104.729C58.1093 105.094 58.0249 105.537 57.7084 105.72C57.3919 105.903 56.9699 105.755 56.7589 105.39C56.5479 105.024 56.6323 104.581 56.9488 104.399C57.2583 104.223 57.6873 104.364 57.8983 104.729Z"
      fill="#FAB99A"
    />
    <path
      d="M57.4341 105.383C57.413 105.101 57.2934 104.905 57.0684 104.792L57.1457 104.638C57.4271 104.778 57.5818 105.024 57.6099 105.369L57.4341 105.383Z"
      fill="#052140"
    />
    <path
      d="M56.1963 78.0864C59.5582 78.0864 62.2872 80.8132 62.2872 84.1725C62.2872 86.5269 60.9438 88.572 58.9815 89.584L59.5864 92.7254L57.4131 90.1462C57.0192 90.2305 56.6042 90.2727 56.1893 90.2727C52.8273 90.2727 50.0984 87.5459 50.0984 84.1866C50.1054 80.8132 52.8344 78.0864 56.1963 78.0864Z"
      fill="white"
    />
    <path
      d="M59.8959 93.3509L57.3569 90.3289C56.9771 90.3992 56.5903 90.4343 56.1964 90.4343C52.743 90.4343 49.9297 87.6232 49.9297 84.1725C49.9297 80.7218 52.743 77.9106 56.1964 77.9106C59.6498 77.9106 62.4631 80.7218 62.4631 84.1725C62.4631 86.4776 61.2112 88.5719 59.1785 89.6753L59.8959 93.3509ZM57.4905 89.9424L59.2911 92.0859L58.7847 89.4856L58.9042 89.4223C60.8806 88.4033 62.1114 86.3863 62.1114 84.1725C62.1114 80.9116 59.4599 78.262 56.1964 78.262C52.9329 78.262 50.2814 80.9116 50.2814 84.1725C50.2814 87.4334 52.9329 90.0829 56.1964 90.0829C56.5973 90.0829 56.9982 90.0408 57.385 89.9635L57.4905 89.9424Z"
      fill="#9CBBE2"
    />
    <path
      d="M53.362 85.1705C53.832 85.1705 54.2131 84.7897 54.2131 84.3201C54.2131 83.8504 53.832 83.4697 53.362 83.4697C52.892 83.4697 52.511 83.8504 52.511 84.3201C52.511 84.7897 52.892 85.1705 53.362 85.1705Z"
      fill="#9CBBE2"
    />
    <path
      d="M56.1965 85.1705C56.6665 85.1705 57.0475 84.7897 57.0475 84.3201C57.0475 83.8504 56.6665 83.4697 56.1965 83.4697C55.7265 83.4697 55.3455 83.8504 55.3455 84.3201C55.3455 84.7897 55.7265 85.1705 56.1965 85.1705Z"
      fill="#9CBBE2"
    />
    <path
      d="M59.0378 85.1705C59.5078 85.1705 59.8888 84.7897 59.8888 84.3201C59.8888 83.8504 59.5078 83.4697 59.0378 83.4697C58.5678 83.4697 58.1868 83.8504 58.1868 84.3201C58.1868 84.7897 58.5678 85.1705 59.0378 85.1705Z"
      fill="#9CBBE2"
    />
    <path
      d="M149.986 102.016L146.321 109.396L143.733 111.23L145.217 113.148C145.892 112.853 146.546 112.565 147.039 112.347C147.658 112.073 148.206 111.673 148.664 111.174L151.385 108.187L149.986 102.016Z"
      fill="#29BC7E"
    />
    <path
      d="M142.756 111.919C142.756 111.919 140.336 111.855 140.413 112.572C140.484 113.254 142.045 113.669 142.045 113.669C142.045 113.669 141.187 114.603 141.483 114.617C141.729 114.631 143.557 113.865 145.217 113.141L143.733 111.223L142.756 111.919Z"
      fill="#FAB99A"
    />
    <path
      d="M157.469 138.329L157.406 143.179H151.667L154.325 140.81L154.628 138.09L157.469 138.329Z"
      fill="#052140"
    />
    <path
      d="M154.444 140.677L154.201 140.931L155.091 141.78L155.334 141.526L154.444 140.677Z"
      fill="white"
    />
    <path
      d="M159.685 119.171L164.186 117.351L166.402 122.643L163.187 121.104L160.564 121.884L159.685 119.171Z"
      fill="#052140"
    />
    <path
      d="M163.461 119.885L163.021 121.034L163.349 121.159L163.789 120.011L163.461 119.885Z"
      fill="white"
    />
    <path
      d="M148.685 113.31L153.193 138.934L158.173 138.765L155.113 110.133L148.685 113.31Z"
      fill="#1B72F2"
    />
    <path
      d="M151.765 112.017L152.208 125.426C152.236 126.206 152.694 126.909 153.397 127.246C154.016 127.542 154.74 127.513 155.338 127.176L161.323 123.754L161.049 118.44L157.645 119.705L157.631 111.441L151.765 112.017Z"
      fill="#1B72F2"
    />
    <path
      d="M164.003 103.555L159.206 100.744C158.7 100.449 158.137 100.259 157.554 100.189L154.459 99.8236L151.892 100.625C150.387 101.096 149.325 102.452 149.233 104.026L148.692 113.296L157.638 111.42L158.348 105.003L163.511 107.421C164.496 107.913 165.691 107.575 166.282 106.641C167.464 104.757 169.384 101.609 170.615 99.1841L167.675 98.144L164.003 103.555Z"
      fill="#29BC7E"
    />
    <path
      d="M171.177 95.7334C171.177 95.7334 171.325 95.3469 171.128 95.2204C170.931 95.1009 170.713 95.3188 170.713 95.3188C170.713 95.3188 170.931 94.7846 170.614 94.6862C170.298 94.5879 169.081 96.3659 169.081 96.3659C169.081 96.3659 168.934 95.2485 168.617 95.2696C168.301 95.2906 168.104 97.5325 168.104 97.5325L167.675 98.1651L170.614 99.2052C171.339 97.7856 171.817 96.6049 171.782 96.0778C171.74 95.4874 171.177 95.7334 171.177 95.7334Z"
      fill="#FAB99A"
    />
    <path
      d="M169.222 97.5045L169.166 97.4342C169.482 97.1952 169.517 96.9141 169.285 96.5908L169.356 96.5416C169.623 96.9071 169.574 97.2304 169.222 97.5045Z"
      fill="#974346"
    />
    <path
      d="M170.333 95.9161L170.263 95.8669L170.769 95.1431L170.84 95.1923L170.333 95.9161Z"
      fill="#974346"
    />
    <path
      d="M171.166 95.6227L170.688 96.194L170.753 96.2481L171.231 95.6768L171.166 95.6227Z"
      fill="#974346"
    />
    <path
      d="M171.422 95.9703L170.99 96.5163L171.056 96.5686L171.488 96.0226L171.422 95.9703Z"
      fill="#974346"
    />
    <path
      d="M156.857 96.2113C156.78 97.3498 155.802 98.4321 154.557 98.3478C153.312 98.2634 151.92 96.9492 151.997 95.8107C152.075 94.6722 153.362 94.0818 154.607 94.124C156.358 94.1802 156.935 95.0728 156.857 96.2113Z"
      fill="#E89623"
    />
    <path
      d="M154.346 101.412C153.692 101.37 153.2 100.808 153.249 100.154L153.432 97.4763L155.788 97.638L155.605 100.316C155.563 100.969 155.001 101.461 154.346 101.412Z"
      fill="#FAB99A"
    />
    <path
      d="M155.05 99.0717C154.515 99.6339 153.939 100.063 153.249 100.161L153.348 98.8819L155.05 99.0717Z"
      fill="#052140"
    />
    <path
      d="M152.504 95.9161C152.504 95.9161 152.039 98.9522 153.08 99.2825C154.121 99.6057 155.408 98.9521 155.408 98.9521L155.662 96.654L155.373 95.1641L152.504 95.9161Z"
      fill="#FAB99A"
    />
    <path
      d="M155.619 97.5747C155.331 97.2514 155.043 96.7102 154.972 96.155C154.227 96.4783 153.319 96.5135 152.279 96.3167L152.588 95.3609L154.86 94.9322L156.034 95.2695L155.619 97.5747Z"
      fill="#E89623"
    />
    <path
      d="M156.302 97.9261C156.091 98.2002 155.725 98.2705 155.486 98.0807C155.254 97.898 155.233 97.5255 155.451 97.2514C155.662 96.9773 156.027 96.9071 156.267 97.0968C156.506 97.2795 156.52 97.652 156.302 97.9261Z"
      fill="#FAB99A"
    />
    <path
      d="M155.739 97.8628L155.563 97.8488C155.598 97.399 155.88 97.2936 156.105 97.2866L156.112 97.4623C155.873 97.4763 155.753 97.5958 155.739 97.8628Z"
      fill="#052140"
    />
    <path
      d="M157.895 95.0815C158.367 94.4209 158.213 93.5037 157.552 93.0328C156.891 92.5619 155.973 92.7156 155.502 93.3762C155.03 94.0368 155.184 94.954 155.845 95.4249C156.506 95.8958 157.424 95.7421 157.895 95.0815Z"
      fill="#E89623"
    />
    <path
      d="M148.783 113.275L148.607 113.261L149.268 103.211L149.444 103.218L148.783 113.275Z"
      fill="#052140"
    />
    <path
      d="M160.697 106.191L156.871 104.258L156.949 104.104L160.775 106.036L160.697 106.191Z"
      fill="#052140"
    />
    <path
      d="M154.136 102.663C154.065 102.663 153.995 102.656 153.925 102.642C153.432 102.557 153.052 102.171 152.898 101.581L153.067 101.531C153.207 102.051 153.531 102.389 153.953 102.466C154.459 102.557 155.022 102.255 155.423 101.679L155.57 101.777C155.191 102.34 154.656 102.663 154.136 102.663Z"
      fill="#052140"
    />
    <path
      d="M154.248 127.513C153.974 127.513 153.742 127.471 153.552 127.401C152.736 127.127 152.349 126.438 152.257 126.108C151.906 124.822 151.737 114.638 151.73 114.203H151.906C151.906 114.308 152.089 124.801 152.433 126.066C152.525 126.41 152.919 127 153.615 127.239C154.48 127.535 155.584 127.19 156.808 126.255L156.914 126.396C155.774 127.26 154.895 127.513 154.248 127.513Z"
      fill="#052140"
    />
    <path
      d="M161.022 118.343L154.944 120.561L155.005 120.726L161.083 118.508L161.022 118.343Z"
      fill="#052140"
    />
    <path
      d="M160.135 72.6398C156.773 72.6398 154.044 75.3666 154.044 78.7259C154.044 81.0803 155.387 83.1254 157.35 84.1374L156.745 87.2788L158.918 84.6996C159.312 84.7839 159.727 84.8261 160.142 84.8261C163.504 84.8261 166.233 82.0993 166.233 78.74C166.226 75.3666 163.497 72.6398 160.135 72.6398Z"
      fill="white"
    />
    <path
      d="M156.435 87.9043L157.146 84.2287C155.12 83.1253 153.861 81.031 153.861 78.7259C153.861 75.2752 156.674 72.4641 160.128 72.4641C163.581 72.4641 166.394 75.2752 166.394 78.7259C166.394 82.1766 163.581 84.9877 160.128 84.9877C159.741 84.9877 159.347 84.9526 158.967 84.8823L156.435 87.9043ZM160.135 72.8154C156.871 72.8154 154.22 75.465 154.22 78.7259C154.22 80.9467 155.451 82.9567 157.427 83.9757L157.547 84.039L157.04 86.6393L158.841 84.4958L158.946 84.5169C159.333 84.5942 159.734 84.6363 160.135 84.6363C163.398 84.6363 166.05 81.9868 166.05 78.7259C166.05 75.465 163.398 72.8154 160.135 72.8154Z"
      fill="#9CBBE2"
    />
    <path
      d="M157.293 79.5552C157.763 79.5552 158.144 79.1745 158.144 78.7048C158.144 78.2352 157.763 77.8544 157.293 77.8544C156.823 77.8544 156.442 78.2352 156.442 78.7048C156.442 79.1745 156.823 79.5552 157.293 79.5552Z"
      fill="#9CBBE2"
    />
    <path
      d="M160.135 79.5552C160.605 79.5552 160.986 79.1745 160.986 78.7048C160.986 78.2352 160.605 77.8544 160.135 77.8544C159.665 77.8544 159.284 78.2352 159.284 78.7048C159.284 79.1745 159.665 79.5552 160.135 79.5552Z"
      fill="#9CBBE2"
    />
    <path
      d="M162.976 79.5552C163.446 79.5552 163.827 79.1745 163.827 78.7048C163.827 78.2352 163.446 77.8544 162.976 77.8544C162.506 77.8544 162.125 78.2352 162.125 78.7048C162.125 79.1745 162.506 79.5552 162.976 79.5552Z"
      fill="#9CBBE2"
    />
    <path d="M200.471 152.083H12.0552V152.434H200.471V152.083Z" fill="#052140" />
  </svg>
)

export default SuccessImage
