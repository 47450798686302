import React, { useEffect } from 'react'

import Spin from '../../../common/ui/Spin'
import WalkthroughViewer from './Viewer/index'

import useWalkthrough from '../../hooks/useWalkthrough'
import { useMutation } from '@apollo/client'
import { CREATE_LEARNING_ACTIVITY } from '../../../App/graphql/queries/learning_activity'
import {
  LearningActivityActionType,
  LearningActivityObjectType,
} from '../../../App/models/LearningActivity'
import { usePageTitle } from '../../../common/hooks/usePageTitle'
import { useEventTracking } from '../../../common/hooks/useEventTracking'

const Show = ({
  uuid,
  usePublicEndpoint = false,
}: {
  uuid: string
  usePublicEndpoint?: boolean
}) => {
  const {
    content: { walkthrough, skillSets, loading },
  } = useWalkthrough(uuid, usePublicEndpoint)

  const [createLearningActivity] = useMutation(CREATE_LEARNING_ACTIVITY)
  const logEvent = useEventTracking()

  useEffect(() => {
    if (walkthrough) {
      if (!usePublicEndpoint) {
        createLearningActivity({
          variables: {
            objectId: walkthrough.id,
            objectType: LearningActivityObjectType.walkthrough,
            action: LearningActivityActionType.viewed,
          },
        })
      }

      logEvent({
        subjectId: walkthrough.id,
        subjectType: 'walkthrough',
        type: 'walkthrough:impression',
      })
    }
  }, [walkthrough?.uuid])

  usePageTitle(walkthrough && `WT | ${walkthrough.name}`)

  if (loading || !walkthrough || (!skillSets && !usePublicEndpoint)) {
    return <Spin className="-ml-1 mr-3 h-20 w-20 mt-20 text-ddu-blue" />
  }

  return (
    <>
      <div className="relative">
        <WalkthroughViewer
          walkthrough={walkthrough}
          isPublicView={usePublicEndpoint}
          skillSets={skillSets}
        />
      </div>
    </>
  )
}

export default Show
