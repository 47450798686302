import React, { ReactElement } from 'react'
import Walkthrough from '../../../App/models/Walkthrough'
import ActionsSection from '../../../common/ui/ActionsSection'

interface Props {
  walkthrough: Walkthrough
  cardMenu: ReactElement
  toggleEmbedModal?: () => void
}

const InternalStickyBarContent: React.FC<Props> = ({ cardMenu, walkthrough, toggleEmbedModal }) => (
  <>
    <div className="sm:flex space-x-2 lg:space-x-4 hidden"></div>
    <div className="flex w-full relative lg:w-auto items-center mr-2 ml-2">
      <ActionsSection
        object={walkthrough}
        objectType="walkthrough"
        cardMenu={cardMenu}
        toggleEmbedModal={toggleEmbedModal}
      />
    </div>
  </>
)

export default InternalStickyBarContent
