import { Dialog, Transition } from '@headlessui/react'
import React, { useState, Fragment, useRef, useEffect } from 'react'
import { ArrowDownOnSquareIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useMutation } from '@apollo/client'
import { CREATE_SKILL_SET } from '../../App/graphql/queries/skill_set'
import client from '../../common/ApolloClient'
import Button from '../../Walkthrough/ui/Button'
import Input from '../../common/components/Form/Input'
import Spin from '../../common/ui/Spin'

type Props = {
  onClose: () => void
  open: boolean
}

const ModalView = ({ onClose, open }: Props) => {
  const [createSkillSet, { loading, error }] = useMutation(CREATE_SKILL_SET, {
    client: client,
  })
  const completeButtonRef: any = useRef()
  const [name, setName] = useState<string>('')

  const handleSubmit = () => {
    createSkillSet({
      variables: { name: name },
    }).then(
      ({
        data: {
          data: { uuid },
        },
      }) => {
        window.open(
          `/app/skill_set/${uuid}?message=The Skill Set was created successfully`,
          '_self'
        )
        client.resetStore()
        onClose()
      }
    )
  }

  const onKeyDown = async (e: { key: string }) => {
    if (e.key === 'Enter' && name) {
      handleSubmit()
    }
  }

  useEffect(() => {
    if (completeButtonRef && completeButtonRef.current) {
      setTimeout(() => completeButtonRef.current.focus(), 10)
    }
  }, [completeButtonRef.current])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-40 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-flowmo-blue-500"
                    onClick={() => onClose()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="text-center sm:mt-0 w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-lg text-center leading-6 font-medium text-gray-900"
                    >
                      New Skill Set
                    </Dialog.Title>
                    <div className="mt-4 focus:border-transparent focus:ring-0 focus:outline-transparent">
                      <Input
                        name="name"
                        placeholder="Enter Name"
                        onChange={(e) => setName(e.target.value)}
                        onKeyDown={onKeyDown}
                        error={error?.message}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <span className="space-x-3">
                    <Button
                      icon={<XMarkIcon className="ml-2 h-4 w-4" />}
                      onClick={onClose}
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                    <Button
                      icon={
                        loading ? (
                          <Spin className="ml-2 h-4 w-4" />
                        ) : (
                          <ArrowDownOnSquareIcon className="ml-2 h-4 w-4" />
                        )
                      }
                      onClick={handleSubmit}
                      disabled={loading || !name || name.trim() == ''}
                    >
                      Save
                    </Button>
                  </span>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ModalView
