import React from 'react'
import Title from '../Title'
import ReviewContainer from '../../Content/Result/ReviewContainer'

interface Props {
  complete: () => void
}

const SupportForm: React.FC<Props> = ({ complete }) => {
  return (
    <>
      <Title>How was this walkthrough?</Title>
      <ReviewContainer completeReview={complete} />
    </>
  )
}

export default SupportForm
