import { gql } from '@apollo/client'

export const CREATE_REVIEW = gql`
  mutation createUserReview(
    $score: Int!
    $objectId: ID
    $objectType: ObjectTypeEnum
    $description: String
    $source: Source
  ) {
    createUserReview(
      score: $score
      objectId: $objectId
      objectType: $objectType
      description: $description
      source: $source
    ) {
      id
      score
    }
  }
`

export const UPDATE_REVIEW = gql`
  mutation updateUserReview($id: ID!, $description: String, $score: Int) {
    updateUserReview(id: $id, description: $description, score: $score) {
      id
      score
      description
    }
  }
`
