import React from 'react'

const FlowMoLogo: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="#1B72F2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4513 9.75802C10.4513 8.23481 11.6861 7 13.2093 7H21.1769C22.7001 7 23.935 8.23481 23.935 9.75802C23.935 11.2812 22.7001 12.516 21.1769 12.516H13.2093C11.6861 12.516 10.4513 11.2812 10.4513 9.75802ZM9.2259 16.5C9.2259 14.9768 10.4607 13.742 11.9839 13.742H16.8871C18.4103 13.742 19.6451 14.9768 19.6451 16.5C19.6451 18.0232 18.4103 19.258 16.8871 19.258H11.9839C10.4607 19.258 9.2259 18.0232 9.2259 16.5ZM10.758 20.4839C9.23481 20.4839 8 21.7187 8 23.242C8 24.7652 9.23481 26 10.758 26C12.2812 26 13.516 24.7652 13.516 23.242C13.516 21.7187 12.2812 20.4839 10.758 20.4839Z"
      fill="white"
    />
  </svg>
)

export default FlowMoLogo
