import React from 'react'
import TextContent from './TextContent'
import ImageContent from './ImageContent'
import { MessageHistoryFieldsFragment } from 'app/javascript/components/graphql'

interface MessageBodyProps {
  message: MessageHistoryFieldsFragment
}

const MessageBody = ({ message }: MessageBodyProps) => {
  return (
    <div>
      <div className="flex flex-col space-x-2">
        {message.message.map((content, index) => {
          return (
            <div key={index}>
              {content.type === 'text' ? (
                <TextContent content={content} />
              ) : content.type === 'image_file' ? (
                <ImageContent content={content} />
              ) : null}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MessageBody
