import React from 'react'
import { ADD_TO_WAITLIST } from '../graphql/queries/waitlist'
import { useMutation } from '@apollo/client'
import { AddToWaitlistMutation, AddToWaitlistMutationVariables } from '../../graphql'

const Waitlist = () => {
  const [email, setEmail] = React.useState('')
  const [placeInLine, setPlaceInLine] = React.useState<number | null>(null)
  const [addToWaitList] = useMutation<AddToWaitlistMutation, AddToWaitlistMutationVariables>(
    ADD_TO_WAITLIST
  )
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!email) {
      window.toastr.error('Please enter a valid email address.')
      return
    }

    const { data } = await addToWaitList({
      variables: {
        email,
      },
    })

    if (data.addToWaitlist.errors.length) {
      window.toastr.error('Something went wrong. Please try again later.')
      return
    }

    if (data.addToWaitlist.placeInLine) {
      setPlaceInLine(data.addToWaitlist.placeInLine)
    }

    setEmail('')
    window.toastr.success('Thanks for joining the waitlist!')
  }
  return (
    <>
      <section className="">
        <div className="overflow-hidden lg:relative">
          <div className="flex flex-col border rounded-xl bg-white mx-auto py-20 px-4 sm:px-6 md:max-w-2xl md:px-4  lg:px-8 relative gap-y-12">
            <div className="text-center">
              <h2 className="font-display text-5xl font-extrabold tracking-tight text-gray-900 sm:w-3/4 sm:text-6xl md:w-2/3 lg:w-auto">
                Supercharge your work
              </h2>
              <p className="mt-4 text-lg tracking-tight text-gray-500">
                Enter your email address and we&apos;ll add you to the waitlist for trying FlowMo.
              </p>
            </div>
            <form className="text-center" onSubmit={handleSubmit}>
              <h3 className="text-base font-medium tracking-tight text-flowmo-blue-700">
                We&apos;re letting new people in everyday!
              </h3>
              <div className="mt-4 sm:relative sm:flex sm:items-center sm:py-0.5 sm:pr-2.5">
                {placeInLine ? (
                  <div className="px-6 pt-10 flex flex-col items-center space-y-4 text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-600 sm:text-2xl">
                      You are #{placeInLine} in line.
                      <br />
                      We&apos;ll reach out to you soon.
                    </h2>
                    <p>
                      If you want to skip the line, we&apos;re looking for design partners! Reach
                      out directly to me at{' '}
                      <a
                        href="mailto:laraqui@getflowmo.com"
                        className="text-blue-500 hover:underline"
                      >
                        laraqui@getflowmo.com
                      </a>
                    </p>
                  </div>
                ) : (
                  <>
                    <div className="relative sm:static sm:flex-auto">
                      <input
                        type="email"
                        id="email-address"
                        aria-label="Email address"
                        placeholder="Email address"
                        className="peer relative z-10 w-full border-0 appearance-none bg-transparent px-4 py-2 text-base text-gray-500 placeholder:text-gray-500/70 focus:outline-none sm:py-3"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <div className="absolute inset-0 rounded-md border border-ddu-blue/20 bg-blue-100 peer-focus:border-blue-300 peer-focus:bg-blue-200 peer-focus:ring-1 peer-focus:ring-blue-300 sm:rounded-xl"></div>
                    </div>
                    <button
                      className="inline-flex justify-center rounded-md py-1 px-4 text-base font-semibold tracking-tight shadow-sm focus:outline-none bg-flowmo-blue-600 text-gray-200 hover:text-gray-300 focus-visible:text-blue-900 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white active:bg-blue-50 active:text-blue-900/80 disabled:opacity-40 disabled:hover:text-blue-600 mt-4 w-full sm:relative sm:z-10 sm:mt-0 sm:w-auto sm:flex-none"
                      type="submit"
                    >
                      Join
                    </button>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default Waitlist
