import React, { useMemo } from 'react'
import { StepTypes } from '../../../../../App/models/Walkthrough'
import ReactPopper from '../../../../components/Popper'
import { RRWEB_TARGET_TYPES } from '../../../../../Walkthrough/constants/RRWebEventTypes'
import useNavigationContext from '../../modules/PlaygroundContext/hooks/useNavigationContext'
import { isVisible } from '../../../../utils/helpers/element'
import ReactHtmlParser from 'react-html-parser'

interface Props {
  element?: HTMLElement | any
  position?: DOMRect
}

const Popper: React.FC<Props> = ({ element, position }) => {
  const { step } = useNavigationContext()

  if (!element) {
    return null
  }

  const description = useMemo(() => {
    if (!element || !step || !isVisible(element)) {
      return null
    }
    let description

    if (step.type === StepTypes.dropdownSelect) {
      if (element.tagName.toLowerCase() === RRWEB_TARGET_TYPES.SELECT) {
        if (step.description && step.description.length > 0) {
          description = <div className="whitespace-nowrap">{ReactHtmlParser(step.description)}</div>
        } else {
          const options = Array.from(element.options)
          const option: any = options.find((option: any) => option.value === step.value)
          const text = option ? option.text || option.label : 'any option'
          description = <div className="whitespace-nowrap">Select {text}</div>
        }
      }
    } else if (step.type === StepTypes.input) {
      if (step.description && step.description.length > 0) {
        description = <div className="whitespace-nowrap">{ReactHtmlParser(step.description)}</div>
      } else {
        description = <div className="whitespace-nowrap">Enter text</div>
      }
    } else if (step.type === StepTypes.click) {
      if (!step.description) {
        return null
      }

      const rect = element.getBoundingClientRect()
      description = ReactHtmlParser(step.description)
      if (rect?.width * rect?.height <= 200 && !description) {
        description = <div className="whitespace-nowrap">Click here</div>
      }
    }

    return description
  }, [element, step])

  if (!description) {
    return <></>
  }

  //60 - top header height
  const virtualReference = {
    getBoundingClientRect() {
      return {
        ...position,
        top: position.top - 60,
        bottom: position.bottom - 60,
      }
    },
  }

  return <ReactPopper element={virtualReference}>{description}</ReactPopper>
}

export default Popper
