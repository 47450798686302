import React from 'react'

const PlayIcon = () => (
  <svg
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
    className="group"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="opacity-10 group-hover:opacity-30 transition-opacity"
      d="M51 25.5C51 39.5833 39.5833 51 25.5 51C11.4167 51 0 39.5833 0 25.5C0 11.4167 11.4167 0 25.5 0C39.5833 0 51 11.4167 51 25.5Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M45 25.5C45 36.2696 36.2696 45 25.5 45C14.7304 45 6 36.2696 6 25.5C6 14.7304 14.7304 6 25.5 6C36.2696 6 45 14.7304 45 25.5Z"
      fill="white"
    />
    <circle cx="25.5" cy="25.5" r="13.5" fill="white" />
    <path
      className="group-hover:fill-ddu-blue transition-colors"
      d="M23.2775 21.5841C23.2022 21.5338 23.1147 21.505 23.0242 21.5006C22.9338 21.4962 22.8439 21.5164 22.7641 21.5591C22.6843 21.6018 22.6176 21.6654 22.5711 21.7431C22.5245 21.8207 22.5 21.9095 22.5 22.0001V29.9999C22.5 30.0905 22.5245 30.1793 22.5711 30.2569C22.6176 30.3346 22.6843 30.3982 22.7641 30.4409C22.8439 30.4836 22.9338 30.5038 23.0242 30.4994C23.1147 30.495 23.2022 30.4662 23.2775 30.4159L29.2774 26.416C29.3459 26.3703 29.402 26.3085 29.4408 26.2359C29.4797 26.1633 29.5 26.0823 29.5 26C29.5 25.9177 29.4797 25.8367 29.4408 25.7641C29.402 25.6915 29.3459 25.6297 29.2774 25.584L23.2775 21.5841ZM23.5 29.0654V22.9346L28.0999 26L23.5 29.0654Z"
      fill="#434E5F"
    />
  </svg>
)

export default PlayIcon
