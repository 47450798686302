import React, { useState } from 'react'
import Title from './Title'
import Description from './Description'
import CollapseButton from './CollapseButton'
import useContentContext from '../../../../../../views/Walkthrough/modules/PlaygroundContext/hooks/useContentContext'

const WalkthroughInformation: React.FC = () => {
  const { walkthrough } = useContentContext()
  const [collapsed, setCollapsed] = useState(true)
  const toggleCollapse = () => setCollapsed(!collapsed)

  return (
    <div className="flex flex-col space-y-1 mb-3">
      <div className="flex space-x-2">
        <Title collapsed={collapsed}>{walkthrough.name}</Title>
        <CollapseButton toggleCollapse={toggleCollapse} collapsed={collapsed} />
      </div>
      {collapsed || <Description>{walkthrough.description}</Description>}
    </div>
  )
}

export default WalkthroughInformation
