import React, { Fragment } from 'react'
import Content from '../../../../components/Walkthrough/ui/Walkthrough/List/Content'
import { CREATED_WALKTHROUGHS_QUERY } from '../../../../components/App/graphql/queries/walkthrough'
import Button from '../../../../components/Walkthrough/ui/Button'
import { PlusIcon } from '@heroicons/react/24/solid'
import { sendRRWebWalkthroughCreationAction } from '../../../../components/App/lib/extension'
import PageHeader from '../../components/PageHeader'
import { Menu, Transition } from '@headlessui/react'
import { clsx } from 'clsx'
import { Link } from 'react-router-dom'
import HeaderActionButton from '../../components/HeaderActionButton'

const MyLibrary = () => {
  return (
    <React.Fragment>
      <PageHeader title="My Library" breadCrumbs={[{ title: 'My Library' }]}>
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button>
              <HeaderActionButton label={'Create'} icon={<PlusIcon className="h-4 w-4 mr-2" />} />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/walkthrough/create"
                      className={clsx(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      Walkthrough
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/skill_set/create"
                      className={clsx(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      Skill Set
                    </Link>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </PageHeader>

      <Content
        {...{
          emptyState: {
            description:
              "You haven't created content yet. Get started by creating a new Walkthrough.",
            actions: (
              <Button color="primary" onClick={() => sendRRWebWalkthroughCreationAction()}>
                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Record Walkthrough
              </Button>
            ),
          },
          query: CREATED_WALKTHROUGHS_QUERY,
        }}
      />
    </React.Fragment>
  )
}

export default MyLibrary
