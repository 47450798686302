import React from 'react'

const FlowMoLabsIcon = () => (
  <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 3C11.3284 3 12 2.32843 12 1.5C12 0.671573 11.3284 0 10.5 0C9.67157 0 9 0.671573 9 1.5C9 2.32843 9.67157 3 10.5 3ZM6.96094 5.23438C7.11719 5.07812 7.29688 5 7.5 5C7.70313 5 7.875 5.07812 8.01562 5.23438C8.17188 5.375 8.25 5.54688 8.25 5.75H12.75C13.1642 5.75 13.5 6.08579 13.5 6.5C13.5 6.91421 13.1642 7.25 12.75 7.25V12.9922L17.3672 18.9453C17.7891 19.4922 18 20.1016 18 20.7734C18 21.3047 17.8672 21.8047 17.6016 22.2734C17.3359 22.7266 16.9688 23.0859 16.5 23.3516C16.0469 23.6172 15.5547 23.75 15.0234 23.75H2.97656C2.44531 23.75 1.94531 23.6172 1.47656 23.3516C1.02344 23.0859 0.664063 22.7266 0.398438 22.2734C0.132812 21.8047 0 21.3047 0 20.7734C0 20.1016 0.210937 19.4922 0.632812 18.9453L5.25 12.9922V7.25C4.83579 7.25 4.5 6.91421 4.5 6.5C4.5 6.08579 4.83579 5.75 5.25 5.75H6.75C6.75 5.54688 6.82031 5.375 6.96094 5.23438ZM11.25 13.5078V7.25H6.75V13.5078L4.61719 16.25H13.3828L11.25 13.5078ZM9.21094 10.4844C9.36719 10.3281 9.54688 10.25 9.75 10.25C9.95312 10.25 10.125 10.3281 10.2656 10.4844C10.4219 10.625 10.5 10.7969 10.5 11C10.5 11.2031 10.4219 11.3828 10.2656 11.5391C10.125 11.6797 9.95312 11.75 9.75 11.75C9.54688 11.75 9.36719 11.6797 9.21094 11.5391C9.07031 11.3828 9 11.2031 9 11C9 10.7969 9.07031 10.625 9.21094 10.4844Z"
      fill="white"
    />
  </svg>
)

export default FlowMoLabsIcon
