import {
  useQueryParams as useQueryParameters,
  NumberParam,
  withDefault,
  StringParam,
  ArrayParam,
  useQueryParam,
} from 'use-query-params'
import { SortOption } from '../../../../common/components/FilterPanel/types'

const useQueryParams = (defaultSort: SortOption) => {
  const [pagination] = usePagination()
  const [sort] = useSort(defaultSort)
  const [contentType = []] = useContentType()
  const [useCases] = useUseCases()
  const [website] = useWebsite()

  return {
    ...pagination,
    ...sort,
    contentType: contentType.length === 1 ? contentType[0] : undefined,
    useCases,
    website,
  }
}

export default useQueryParams

export const usePagination = () => {
  return useQueryParameters({
    perPage: withDefault(NumberParam, 24),
    page: withDefault(NumberParam, 1),
  })
}

export const useSort = (defaultSort: SortOption) => {
  return useQueryParameters({
    sortBy: withDefault(StringParam, defaultSort.sortBy),
    sortDirection: withDefault(StringParam, defaultSort.sortDirection),
  })
}

export const useContentType = () => {
  return useQueryParam('contentType', ArrayParam)
}

export const useUseCases = () => {
  return useQueryParam('useCases', ArrayParam)
}

export const useWebsite = () => {
  return useQueryParam('website', StringParam)
}
