import { useMutation } from '@apollo/client'
import { MOVE_WALKTHROUGH_QUERY } from '../../App/graphql/queries/walkthrough'

export type DestinationType = {
  destinationId?: number
  destinationType: string
}

const useMoveWalkthroughMutation = () => {
  return useMutation(MOVE_WALKTHROUGH_QUERY)
}

export default useMoveWalkthroughMutation
