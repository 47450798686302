import React from 'react'
import Card from '../Card'
import SkillSetCard from '../../../../SkillSet/ui/SkillSet/Card'

interface Props {
  walks: any
  skillSets: any
}

const List: React.FC<Props> = ({ walks, skillSets }) => {
  return (
    <ul className="inter-font grid grid-cols-1 gap-y-16 gap-x-12 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 mb-10">
      {walks.map((content: any, index: number) =>
        content.__typename === 'Walkthrough' ? (
          <Card walkthrough={content} skillSets={skillSets} key={index} />
        ) : (
          <SkillSetCard skillSet={content} key={index} />
        )
      )}
    </ul>
  )
}

export default List
