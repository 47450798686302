import React from 'react'
import PageHeader from '../../components/PageHeader'
import Spin from '../../../common/ui/Spin'
import { GET_ORGANIZATION_AGENTS_QUERY } from '../../graphql/queries/agent'
import { useMutation, useQuery } from '@apollo/client'
import {
  CreateAnalysisMutation,
  CreateAnalysisMutationVariables,
  OrganizationAgentsQuery,
  OrganizationAgentsQueryVariables,
} from 'app/javascript/components/graphql'
import DynamicForm from '../../components/DynamicForm'
import { useHistory } from 'react-router-dom'
import { CREATE_ANALYSIS_MUTATION } from '../../graphql/queries/analysis'

export const analysisFormData = (agents: OrganizationAgentsQuery['organizationAgents']) => [
  {
    name: 'name',
    label: 'Name',
    type: 'string',
    required: true,
    defaultValue: '',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'textarea',
    required: true,
    defaultValue: '',
  },
  {
    name: 'synthesis',
    label: 'Synthesis',
    type: 'textarea',
    required: true,
    defaultValue: '',
  },
  {
    name: 'agentUuid',
    label: 'Agent',
    type: 'select',
    required: true,
    defaultValue: '',
    options: agents?.map((agent) => ({ label: agent.label, value: agent.uuid })),
  },
  {
    name: 'cronSchedule',
    label: 'Schedule',
    type: 'timeFrameSelector',
    required: false,
    defaultValue: '',
  },
  {
    name: 'webhookUrl',
    label: 'Webhook URL',
    type: 'url',
    required: false,
    defaultValue: '',
  },
]

const CreateAnalysis = () => {
  const formRef = React.useRef<{ submitForm: () => Promise<void> }>(null)
  const history = useHistory()
  const [isSaving, setIsSaving] = React.useState(false)

  const [createAnalysis] = useMutation<CreateAnalysisMutation, CreateAnalysisMutationVariables>(
    CREATE_ANALYSIS_MUTATION
  )

  const { data, loading } = useQuery<OrganizationAgentsQuery, OrganizationAgentsQueryVariables>(
    GET_ORGANIZATION_AGENTS_QUERY,
    { fetchPolicy: 'network-only' }
  )

  const handleSave = async ({
    name,
    description,
    synthesis,
    agentUuid,
    webhookUrl,
    cronSchedule,
  }: {
    name: string
    description: string
    task: string
    synthesis: string
    agentUuid: string
    webhookUrl: string
    cronSchedule: string
  }) => {
    setIsSaving(true)

    const response = await createAnalysis({
      variables: { name, description, agentUuid, synthesis, webhookUrl, cronSchedule },
    })

    if (response.data.createAnalysis.errors.length > 0) {
      window.toastr.error('Error adding workflow')
    } else {
      window.toastr.success('Analysis created successfully')
      history.push('/analysis/' + response.data.createAnalysis.analysis.uuid)
    }
    setIsSaving(false)
  }

  if (loading) {
    return <Spin />
  }

  const agents = data?.organizationAgents

  const pages = [{ title: 'Analyses', to: '/analyses' }, { title: 'Create' }]

  const formData = analysisFormData(agents)

  return (
    <React.Fragment>
      <PageHeader title="Create Analysis" breadCrumbs={pages} />
      <div className="max-w-2xl">
        <DynamicForm ref={formRef} formData={formData} handleSubmit={handleSave} />
        <button
          type="submit"
          onClick={() => formRef.current?.submitForm()}
          className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-flowmo-blue-700 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-flowmo-blue-800"
          disabled={isSaving}
        >
          {isSaving ? <Spin /> : 'Create'}
        </button>
      </div>
    </React.Fragment>
  )
}
export default CreateAnalysis
