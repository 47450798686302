import React from 'react'
import Description from './Description'
import useNavigationContext from '../../../../../../../views/Walkthrough/modules/PlaygroundContext/hooks/useNavigationContext'
import { StepTypes } from '../../../../../../../../App/models/Walkthrough'
import Media from './Media'

const Content = () => {
  const { step } = useNavigationContext()
  return (
    <>
      <Description />
      {step.type !== StepTypes.goto && <Media />}
    </>
  )
}

export default Content
