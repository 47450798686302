import React, { PropsWithChildren } from 'react'
import Walkthrough from '../../../../App/models/Walkthrough'
import { Chunk } from '../../../hooks/useRRWebEvents'
import Record from './modules/Record'
import Thumbnail from '../../../../common/components/Player/components/Thumbnail'
import { Player } from '@data-driven/components'
import PlayerEventTracking from '../../../../common/components/PlayerEventTracking'

type AudioProps = PropsWithChildren & {
  walkthrough: Walkthrough
  chunks: Chunk[]
}

const Video: React.FC<AudioProps> = ({ walkthrough, chunks }) => {
  if (!walkthrough.canEdit) {
    return (
      <Player
        audio={walkthrough.audioUrl}
        duration={walkthrough.meta.duration}
        thumbnail={(loading: boolean) => <Thumbnail loading={loading} walkthrough={walkthrough} />}
        chunks={chunks}
        rrwebScreenSize={{
          width: walkthrough?.steps[0]?.event?.data?.width,
          height: walkthrough?.steps[0]?.event?.data?.height,
        }}
        maxHeight={750}
        steps={walkthrough.steps}
      >
        <PlayerEventTracking walkthrough={walkthrough} eventPrefix="walkthrough" />
      </Player>
    )
  }
  return <Record walkthrough={walkthrough} chunks={chunks} />
}

export default Video
