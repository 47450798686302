import React, { useEffect } from 'react'
import { useEmbedContext } from '../../context/EmbedContext'
import { Player } from '@data-driven/components'
import Thumbnail from './components/Thumbnail'
import Completed from './components/Completed'
import useWindowSize from '../../../Labs/hooks/useWindowSize'
import { TOP_BAR_SIZE } from '../../constants'

import '@data-driven/components/dist/index.css'
import PlayerEventTracking from '../../../common/components/PlayerEventTracking'
import { useEventTracking } from '../../../common/hooks/useEventTracking'

const VideoContent = () => {
  const { walkthrough, rootUrl, chunks } = useEmbedContext()
  const windowDimensions = useWindowSize()

  const logEvent = useEventTracking()

  useEffect(() => {
    if (walkthrough) {
      logEvent({
        type: 'walkthrough_embed:impression',
        subjectType: 'walkthrough',
        subjectId: walkthrough.id,
      })
    }
  }, [walkthrough])

  return (
    <Player
      duration={walkthrough.meta.duration}
      chunks={chunks}
      audio={walkthrough.audioEnabled ? rootUrl + 'audio/' + walkthrough.uuid : undefined}
      thumbnail={(loading) => <Thumbnail loading={loading} />}
      completed={() => <Completed />}
      fullscreen={false}
      rrwebScreenSize={{
        width: walkthrough?.steps[0]?.event?.data?.width,
        height: walkthrough?.steps[0]?.event?.data?.height,
      }}
      maxHeight={windowDimensions.height - TOP_BAR_SIZE}
      steps={walkthrough.steps}
    >
      <PlayerEventTracking walkthrough={walkthrough} eventPrefix="walkthrough_embed" />
    </Player>
  )
}

export default VideoContent
