import React from 'react'
import { useChatDispatch, useChatState } from './AgentProvider'
import Prompt from '../../../components/Chat/Prompt'

const AgentWorkWithYou = () => {
  const { agent } = useChatState()
  const { addMessageToQueue } = useChatDispatch()
  return (
    <div>
      <div className="mt-8 text-sm flex flex-col space-y-4">
        {agent && agent.prompts && agent.prompts.length > 0 && (
          <div className="flex flex-col text-base font-medium tracking-tight">
            <div className="text-blue-600">I can answer questions like -</div>
            <div className="">
              <ul className="mt-4 ml-6 list-disc list-inside text-slate-700">
                {agent.prompts.map((prompt: string, index: number) => (
                  <li key={index} className="mt-1 text-sm">
                    <span
                      className="hover:underline cursor-pointer"
                      onClick={() => addMessageToQueue(prompt)}
                    >
                      {prompt}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        <div className="flex flex-col text-base font-medium tracking-tight">
          <div className="text-blue-600">Let&apos;s start -</div>
          <div className="max-w-2xl mt-2">
            <Prompt />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgentWorkWithYou
