import React from 'react'
import { StepTypes } from '../../../../../App/models/Walkthrough'
import { isCheckbox } from '../../../../utils/helpers/element'
import useNavigationContext from '../PlaygroundContext/hooks/useNavigationContext'

interface Props {
  element: HTMLElement
}

const EventListener: React.FC<Props> = ({ element }) => {
  const { nextStep, step } = useNavigationContext()

  const events = {
    [StepTypes.click]: {
      type: 'click',
      listener: (event: Event) => {
        const { target }: { target: any } = event
        event.stopPropagation()
        !isCheckbox(target) && event.preventDefault()
        nextStep()
      },
    },
    [StepTypes.input]: {
      type: 'change',
      listener: (event: Event) => {
        event.stopPropagation()
        event.preventDefault()
        nextStep()
      },
    },
    [StepTypes.dropdownSelect]: {
      type: 'change',
      listener: (event: Event | any) => {
        event.stopPropagation()
        event.preventDefault()
        if (event.target.value === step.value) {
          nextStep()
        }
      },
    },
  }

  const event = React.useMemo(() => events[step.type], [step])

  React.useEffect(() => {
    element && event && element.addEventListener(event.type, event.listener)
    return () => {
      element && event && element.removeEventListener(event.type, event.listener)
    }
  }, [element, step])

  return <></>
}

export default EventListener
