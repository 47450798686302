import React, { useCallback, useEffect, useRef } from 'react'
import { useChatState } from './AgentProvider'
import Prompt from '../../../components/Chat/Prompt'
import Message from '../../../components/Chat/Message'
import MessageStream from '../../../components/Chat/MessageStream'
import RunStep from './RunStep'
import {
  MessageHistoryFieldsFragment,
  StepHistoryFieldsFragment,
} from 'app/javascript/components/graphql'

const Chat = () => {
  const divRef = useRef<HTMLDivElement>(null)
  const { isQuerying, messages, steps } = useChatState()

  const scrollToBottom = useCallback(() => {
    const element = divRef.current
    if (element) {
      element.scrollTop = element.scrollHeight
    }
  }, [divRef.current])

  useEffect(() => {
    scrollToBottom()
  }, [messages, isQuerying, divRef.current])

  const messagesWithType = messages.map((message) => ({
    ...message,
    type: 'message',
  }))
  const stepsWithType = steps.map((step) => ({
    ...step,
    type: 'step',
  }))

  const interleavedItems = []

  let stepIndex = 0
  for (let i = 0; i < messagesWithType.length; i++) {
    const currentMessage = messagesWithType[i]
    const nextMessage = messagesWithType[i + 1]

    interleavedItems.push(currentMessage)

    while (
      stepIndex < stepsWithType.length &&
      (!nextMessage || stepsWithType[stepIndex].createdAtMilli < nextMessage.createdAtMilli)
    ) {
      if (stepsWithType[stepIndex].createdAtMilli > currentMessage.createdAtMilli) {
        interleavedItems.push(stepsWithType[stepIndex])
      }
      stepIndex++
    }
  }

  // Add any remaining steps
  while (stepIndex < stepsWithType.length) {
    interleavedItems.push(stepsWithType[stepIndex])
    stepIndex++
  }

  return (
    <>
      <div ref={divRef} className="flex-grow overflow-y-auto">
        <ul role="list" className="space-y-6 p-8 mb-12 mr-10">
          {interleavedItems.map((item, index) => {
            if (item.type === 'message') {
              return <Message message={item as MessageHistoryFieldsFragment} key={index} />
            } else if (item.type === 'step') {
              return <RunStep step={item as StepHistoryFieldsFragment} key={index} />
            }
            return null
          })}
          {isQuerying && <MessageStream />}
        </ul>
      </div>

      <div className="flex-shrink-0 p-4 px-10 pb-10 flex justify-center">
        <div className="w-full flex justify-center items-center max-w-5xl">
          <Prompt />
        </div>
      </div>
    </>
  )
}

export default Chat
