import React, { useState } from 'react'
import SupportButton from './Button'
import SupportDialog from './Dialog'

const Support: React.FC = () => {
  const [opened, setOpened] = useState(false)
  const toggleOpen = () => setOpened(!opened)
  return (
    <>
      {opened && <SupportDialog onClose={toggleOpen} />}
      <SupportButton onClick={toggleOpen} />
    </>
  )
}

export default Support
