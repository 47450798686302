import React, { useEffect, useMemo, useRef, useState } from 'react'
import rrwebPlayer from '@data-driven/rrweb-player'
import useContentContext from '../../../../../../../../views/Walkthrough/modules/PlaygroundContext/hooks/useContentContext'
import useNavigationContext from '../../../../../../../../views/Walkthrough/modules/PlaygroundContext/hooks/useNavigationContext'

export const useStepEvents = () => {
  const { steps, chunks } = useContentContext()
  const { index, step } = useNavigationContext()
  const events = chunks[step.chunkIndex].events

  const { prevStep, currentStep } = useMemo(
    () => ({
      currentStep: steps[index],
      prevStep: steps[index - 1],
    }),
    [steps, index]
  )

  const previousStepTimestamp =
    events[currentStep.chunkIndex === prevStep.chunkIndex ? prevStep.eventIndex : 0].timestamp -
    events[0].timestamp

  const stepEvents = events
    .filter((event: any, eventIndex: number) => eventIndex <= steps[index].eventIndex + 7)
    .sort((a: any, b: any) => a.timestamp - b.timestamp)

  return { stepEvents, previousStepTimestamp }
}

export const initPlayer = (
  frameRef: any,
  events: any,
  width: any,
  height: any,
  autoPlay: any,
  showController: any
) => {
  return new rrwebPlayer({
    target: frameRef.current,
    props: {
      events,
      autoPlay,
      skipInactive: false,
      UNSAFE_replayCanvas: true,
      mouseTail: {
        strokeStyle: '#4C90F7',
      },
      width,
      height,
      showController,
      triggerFocus: false,
    },
  })
}

export const useRRWebPlayer = (
  events: any,
  onInit: any,
  onGoTo: any,
  autoPlay: any,
  showPlayerController: boolean,
  width: any,
  height: any
) => {
  const [loading, setLoading] = useState(true)
  const frameRef = useRef(null)
  const replayer = useRef(null)
  useEffect(() => {
    if (frameRef && events && events.length > 0) {
      if (replayer.current) {
        replayer.current.$destroy()
      }
      replayer.current = initPlayer(frameRef, events, width, height, autoPlay, showPlayerController)
      onInit && onInit(replayer)
      onGoTo && onGoTo(replayer)

      loading && setLoading(false)
    }
  }, [frameRef, events])

  return { ref: frameRef, loading, rrweb: replayer }
}

const RRWebStepPlayer = () => {
  const { stepEvents: events, previousStepTimestamp } = useStepEvents()

  const onGoToStepEvent = (replayer: any) => {
    const { totalTime } = replayer.current.getMetaData()
    const timeStamp = totalTime - previousStepTimestamp < 2000 ? 0 : previousStepTimestamp

    replayer.current.goto(timeStamp, true)
    replayer.current.addEventListener('finish', () => replayer.current.goto(timeStamp, true))
  }

  const { ref } = useRRWebPlayer(events, null, onGoToStepEvent, true, false, 1024, 600)

  return (
    <div className="rrweb-player">
      <div ref={ref} className="rrweb-frame" />
    </div>
  )
}

export default RRWebStepPlayer
