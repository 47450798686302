import React from 'react'
import FlowMoLogo from './FlowMoLogo'
import VerticalLine from './VerticalLine'
import RRWebPlayerButton from './RRWebPlayerButton'
import DragButton from './DragButton'
import MinimizeButton from './MinimizeButton'
import CloseButton from './CloseButton'

const Header = () => (
  <div className="border-b border-[#E4E7ED] py-1.5 px-2.5 flex items-center justify-between">
    <div className="flex items-center space-x-3.5">
      <FlowMoLogo />
      <VerticalLine />

      <div className="flex space-x-0.5 items-center">
        <RRWebPlayerButton />
      </div>
    </div>
    <div className="flex space-x-0.5 items-center relative">
      <DragButton />
      <MinimizeButton />
      <CloseButton />
    </div>
  </div>
)

export default Header
