import React from 'react'
import ReactHtmlParser from 'react-html-parser'

const Description = ({ children }: { children: string }) => (
  <div className="font-inter break-all font-normal text-[12px] leading-[17px] text-[#777D87]">
    {ReactHtmlParser(children)}
  </div>
)

export default Description
