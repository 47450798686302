import Circles from '../Circles'
import React, { useEffect, useState } from 'react'
import { applyLoopDescriptionAnimation } from '../animation/with-description'
import Beacon from '../Beacon'

interface Props {
  circleRef: any
  beaconRef: any
  styles: any
  description?: any
}
const LoopDescriptionHighlighter = ({ circleRef, beaconRef, styles, description }: Props) => {
  const [animation, setAnimation] = useState<any>()
  const [hovered, setHovered] = useState(false)

  useEffect(() => {
    setAnimation(applyLoopDescriptionAnimation())
  }, [])

  const onMouseOver = () => {
    setHovered(true)
    animation?.pause()
  }

  const onMouseLeave = () => {
    setHovered(false)
    animation?.play()
  }

  return (
    <>
      <Beacon
        elementRef={beaconRef}
        styles={styles}
        description={description}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        className={hovered && 'hovered'}
      />
      <Circles elementRef={circleRef} className="loop-animation with-description" />
    </>
  )
}

export default LoopDescriptionHighlighter
