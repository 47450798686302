import Circles from '../Circles'
import React, { useEffect } from 'react'
import { applyInitialDescriptionAnimation } from '../animation/with-description'
import Beacon from '../Beacon'

interface Props {
  circleRef: any
  beaconRef: any
  styles: any
  description?: any
  onClose: () => void
}

const InitialDescriptionHighlighter = ({
  circleRef,
  beaconRef,
  styles,
  description,
  onClose,
}: Props) => {
  useEffect(() => {
    applyInitialDescriptionAnimation()
  }, [])

  return (
    <>
      <Beacon elementRef={beaconRef} styles={styles} description={description} onClose={onClose} />
      <Circles elementRef={circleRef} className="initial-animation with-description" />
    </>
  )
}

export default InitialDescriptionHighlighter
