import IconButton from '../../../IconButton'
import RRWebPlayerIcon from '../../../icons/RRWebPlayerIcon'
import React, { useState } from 'react'
import Dialog from '../../../Dialog'
import useContentContext from '../../../../views/Walkthrough/modules/PlaygroundContext/hooks/useContentContext'
import SinglePlayer from '../../../../../common/components/Player'

const RRWebPlayerButton = () => {
  const { chunks, walkthrough } = useContentContext()
  const [opened, setOpened] = useState(false)
  const toggleOpened = () => setOpened(!opened)

  return (
    <>
      {opened && (
        <Dialog onClose={toggleOpened}>
          <div className="max-w-[1200px] w-[95vw]">
            <SinglePlayer
              chunks={chunks}
              walkthrough={walkthrough}
              maxHeight={window.innerHeight * 0.8}
            />
          </div>
        </Dialog>
      )}
      <IconButton dataFor="watch-flowmo-tooltip" dataTip="Watch FlowMo" onClick={toggleOpened}>
        <RRWebPlayerIcon />
      </IconButton>
    </>
  )
}

export default RRWebPlayerButton
