import React from 'react'
import { hot } from 'react-hot-loader'
import { GET_GALLERY_CONTENTS_QUERY } from '../../App/graphql/queries/walkthrough'
import Content from '../../Walkthrough/ui/Walkthrough/List/Content'

const Gallery = () => {
  return (
    <>
      <Content
        query={GET_GALLERY_CONTENTS_QUERY}
        emptyState={{
          description: 'Oh no! The gallery is empty, try changing your filter options.',
        }}
        showHeader={true}
      />
    </>
  )
}

export default hot(module)(Gallery)
