import React from 'react'

const CloseIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.172 15.172c-.215.215-.23.548-.035.743.195.195.527.18.742-.035l3.147-3.147 3.146 3.146c.215.215.548.231.743.036.195-.195.18-.528-.035-.743l-3.147-3.146 3.146-3.147c.215-.215.231-.547.036-.742-.195-.196-.528-.18-.743.035l-3.146 3.147-3.147-3.147c-.215-.215-.547-.23-.742-.035-.196.195-.18.527.035.742l3.147 3.147-3.147 3.146Z"
      fill="currentColor"
    />
  </svg>
)

export default CloseIcon
