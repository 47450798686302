import React from 'react'
import ReactDOM from 'react-dom'
import ScrollLock from 'react-scrolllock'

interface Props {
  onClose: () => void
  children?: React.ReactNode
}

const Dialog: React.FC<Props> = ({ onClose, children }) =>
  ReactDOM.createPortal(
    <ScrollLock>
      <div className="fixed bottom-0 right-0 w-screen h-screen z-50 flex justify-center items-center">
        <Background onClose={onClose} />
        <div className="z-50">{children}</div>
      </div>
    </ScrollLock>,
    document.body
  )

const Background: React.FC<Props> = ({ onClose }) => (
  <div
    onClick={onClose}
    className="absolute bottom-0 right-0 bg-gray-500 bg-opacity-75 w-screen h-screen"
  />
)

export default Dialog
