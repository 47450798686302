import React from 'react'
import { StepTypes } from '../../../../../../../../App/models/Walkthrough'
import useNavigationContext from '../../../../../../../views/Walkthrough/modules/PlaygroundContext/hooks/useNavigationContext'

const Titles = {
  [StepTypes.goto]: 'Navigate To',
  [StepTypes.click]: 'Click',
  [StepTypes.input]: 'Input',
  [StepTypes.dropdownSelect]: 'Dropdown',
}

const Title: React.FC = () => {
  const { step } = useNavigationContext()
  return (
    <div className="font-inter font-semibold text-[13px] text-[#434E5F] leading-[140%]">
      {Titles[step.type]}
    </div>
  )
}

export default Title
