import Walkthrough from '../../../App/models/Walkthrough'
import React, { useState } from 'react'
import { EmbedContext } from './index'
import { ModeTypes } from './types'
import LabsContextProvider from '../LabsContext/provider'
import useRRWebEvents from '../../../Walkthrough/hooks/useRRWebEvents'

interface Props {
  rootUrl: string
  walkthrough: Walkthrough
  children?: React.ReactNode
}

const EmbedContextProvider = ({ children, walkthrough, rootUrl }: Props) => {
  const [mode, setMode] = useState(ModeTypes.VIDEO)
  const chunks = useRRWebEvents({
    ...walkthrough,
    rrwebEventsUrl: rootUrl + 'rrweb/' + walkthrough.uuid,
  })

  const value = {
    rootUrl,
    mode,
    setMode,
    walkthrough,
    enabled: walkthrough.embedEnabled,
    chunks,
  }

  if (mode === ModeTypes.VIDEO) {
    return <EmbedContext.Provider value={value}>{children}</EmbedContext.Provider>
  }

  return (
    <EmbedContext.Provider value={value}>
      <LabsContextProvider walkthrough={walkthrough}>{children}</LabsContextProvider>
    </EmbedContext.Provider>
  )
}

export default EmbedContextProvider
