import React from 'react'

export const TerribleIcon = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5 18.334H16.5183"
      stroke="rgb(107 114 128)"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5 18.334H27.5183"
      stroke="rgb(107 114 128)"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4167 27.9591C18.0142 27.3494 18.7273 26.865 19.5143 26.5342C20.3013 26.2035 21.1464 26.0332 22.0001 26.0332C22.8538 26.0332 23.6988 26.2035 24.4858 26.5342C25.2729 26.865 25.986 27.3494 26.5834 27.9591"
      stroke="rgb(107 114 128)"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.2043 32.2783C31.7042 32.7929 31.3661 33.4428 31.2318 34.1477C31.0975 34.8525 31.173 35.5812 31.4488 36.2436C31.7247 36.906 32.1889 37.4728 32.7838 37.874C33.3787 38.2751 34.0782 38.4929 34.7957 38.5003C35.5132 38.5077 36.2171 38.3045 36.8202 37.9158C37.4232 37.527 37.899 36.9699 38.1886 36.3134C38.4781 35.6569 38.5686 34.9299 38.449 34.2224C38.3293 33.5149 38.0047 32.8581 37.5154 32.3333L34.8333 29.334L32.2043 32.2783V32.2783Z"
      stroke="rgb(107 114 128)"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.2525 24.7812C38.414 23.8629 38.4968 22.9324 38.5 22C38.5 18.7366 37.5323 15.5465 35.7193 12.8331C33.9062 10.1197 31.3293 8.00484 28.3143 6.756C25.2993 5.50715 21.9817 5.18039 18.781 5.81705C15.5803 6.45371 12.6403 8.02518 10.3327 10.3327C8.02518 12.6403 6.45371 15.5803 5.81705 18.781C5.18039 21.9817 5.50715 25.2993 6.756 28.3143C8.00484 31.3293 10.1197 33.9062 12.8331 35.7193C15.5465 37.5323 18.7366 38.5 22 38.5C23.265 38.5 24.4933 38.3607 25.6667 38.093"
      stroke="rgb(107 114 128)"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const BadIcon = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 38.5C31.1127 38.5 38.5 31.1127 38.5 22C38.5 12.8873 31.1127 5.5 22 5.5C12.8873 5.5 5.5 12.8873 5.5 22C5.5 31.1127 12.8873 38.5 22 38.5Z"
      stroke="rgb(107 114 128)"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 18.334H16.5183"
      stroke="rgb(107 114 128)"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5 18.334H27.5183"
      stroke="rgb(107 114 128)"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4167 27.9591C18.0142 27.3494 18.7273 26.865 19.5143 26.5342C20.3013 26.2035 21.1464 26.0332 22.0001 26.0332C22.8538 26.0332 23.6988 26.2035 24.4858 26.5342C25.2729 26.865 25.986 27.3494 26.5834 27.9591"
      stroke="rgb(107 114 128)"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const OkayIcon = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 38.5C31.1127 38.5 38.5 31.1127 38.5 22C38.5 12.8873 31.1127 5.5 22 5.5C12.8873 5.5 5.5 12.8873 5.5 22C5.5 31.1127 12.8873 38.5 22 38.5Z"
      stroke="rgb(107 114 128)"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 18.334H16.5183"
      stroke="rgb(107 114 128)"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5 18.334H27.5183"
      stroke="rgb(107 114 128)"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 27.5H27.5"
      stroke="rgb(107 114 128)"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const GoodIcon = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 38.5C31.1127 38.5 38.5 31.1127 38.5 22C38.5 12.8873 31.1127 5.5 22 5.5C12.8873 5.5 5.5 12.8873 5.5 22C5.5 31.1127 12.8873 38.5 22 38.5Z"
      stroke="rgb(107 114 128)"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 18.334H16.5183"
      stroke="rgb(107 114 128)"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5 18.334H27.5183"
      stroke="rgb(107 114 128)"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4167 27.5C18.0142 28.1098 18.7273 28.5942 19.5143 28.9249C20.3013 29.2556 21.1464 29.4259 22.0001 29.4259C22.8538 29.4259 23.6988 29.2556 24.4858 28.9249C25.2729 28.5942 25.986 28.1098 26.5834 27.5"
      stroke="rgb(107 114 128)"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const AmazingIcon = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 38.5C31.1127 38.5 38.5 31.1127 38.5 22C38.5 12.8873 31.1127 5.5 22 5.5C12.8873 5.5 5.5 12.8873 5.5 22C5.5 31.1127 12.8873 38.5 22 38.5Z"
      stroke="rgb(107 114 128)"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 16.5H16.5183"
      stroke="rgb(107 114 128)"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5 16.5H27.5183"
      stroke="rgb(107 114 128)"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.3332 23.834H14.6665M14.6665 23.834C14.6665 25.7789 15.4391 27.6442 16.8144 29.0194C18.1897 30.3947 20.0549 31.1673 21.9998 31.1673C23.9448 31.1673 25.81 30.3947 27.1853 29.0194C28.5606 27.6442 29.3332 25.7789 29.3332 23.834H14.6665Z"
      stroke="rgb(107 114 128)"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
