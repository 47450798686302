import { gql } from '@apollo/client'

export const GET_HOMEPAGE_DEMOS = gql`
  query homepageDemos {
    homepageDemos {
      title
      description
      url
      priority
    }
  }
`
