import React from 'react'
import { useChatState } from './AgentProvider'
import AgentImage from '../../../components/AgentImage'
import { StepHistoryFieldsFragment } from '../../../../../components/graphql'
import StepChip from './StepChip'
import { getRelativeTimeString } from '../../../../../components/common/utils/dateDisplay'

interface RunStepProps {
  step: StepHistoryFieldsFragment
}
const RunStep = ({ step }: RunStepProps) => {
  const { agent } = useChatState()
  return (
    <>
      <div className="flex flex-row space-x-3">
        <div className="">
          <div className="rounded-full h-8 w-8 items-center justify-center flex bg-gray-200 mt-3 text-white shadow-sm ">
            <AgentImage agent={agent} className="h-8 w-8 rounded-full" />
          </div>
        </div>
        <div className="flex-grow p-3">
          <div className="flex justify-between gap-x-4">
            <div className="py-0.5 text-xs leading-5 text-gray-500">
              <div className="flex flex-row space-x-2">
                <span className="font-medium text-gray-900">{agent.firstName}</span>
                <div className="">did the following work</div>
              </div>
            </div>

            {step.createdAt && (
              <div className="">
                <span className="text-xs text-gray-500">
                  {getRelativeTimeString(step.createdAt)}
                </span>
              </div>
            )}
          </div>
          <div className="flex flex-wrap mt-4">
            {step.step.map((oneStep, index) => {
              // eslint-disable-next-line react/jsx-no-undef
              return <StepChip key={index} step={oneStep} />
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default RunStep
