import { useMutation } from '@apollo/client'
import { MOVE_SKILL_SET_QUERY } from '../../App/graphql/queries/skill_set'

export type DestinationType = {
  destinationId?: number
  destinationType: string
}

const useMoveSkillSetMutation = () => {
  return useMutation(MOVE_SKILL_SET_QUERY)
}

export default useMoveSkillSetMutation
