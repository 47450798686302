import React from 'react'
import PageHeader from '../../../components/PageHeader'
import Spin from '../../../../common/ui/Spin'
import { useQuery } from '@apollo/client'
import { GET_AGENT_QUERY } from '../../../graphql/queries/agent'
import { AgentQuery, AgentQueryVariables } from 'app/javascript/components/graphql'
import { AgentProvider } from './AgentProvider'
import AgentApp from './AgentApp'
import { useHistory } from 'react-router-dom'

type OneAgentProps = {
  uuid: string
}
const OneAgent = ({ uuid }: OneAgentProps) => {
  const history = useHistory()
  const { data, loading } = useQuery<AgentQuery, AgentQueryVariables>(GET_AGENT_QUERY, {
    variables: { uuid },
    fetchPolicy: 'network-only',
  })

  const agent = data?.agent
  if (loading) {
    return <Spin />
  }

  if (!agent) {
    window.toastr.error('Agent not found')
    history.push('/agents')
    return <></>
  }

  const pages = [{ title: 'Agents', to: '/agents' }, { title: 'One Agent' }]
  document.title = agent.label + ' - Agent - FlowMo'

  return (
    <React.Fragment>
      <div className="h-full flex flex-col">
        <PageHeader breadCrumbs={pages} />
        <AgentProvider agent={agent}>
          <AgentApp />
        </AgentProvider>
      </div>
    </React.Fragment>
  )
}

export default OneAgent
