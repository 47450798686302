import React, { useEffect, useState } from 'react'
import { useChatState } from '../../pages/app/OneAgent/AgentProvider'
import ThreeDots from '../../../../components/common/ui/ThreeDots'
import Message from './Message'

import { createConsumer } from '@rails/actioncable'
import { ActorEnum, StepHistoryFieldsFragment, StepUnion } from '../../../../components/graphql'
import RunStep from '../../pages/app/OneAgent/RunStep'

const MessageStream = () => {
  const { chatId } = useChatState()
  const [message, setMessage] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const [toolCalls, setToolCalls] = useState<StepHistoryFieldsFragment[]>([])

  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
  const host = window.location.hostname
  const port = window.location.port ? `:${window.location.port}` : ''

  const cableURL = `${protocol}//${host}${port}/cable`

  useEffect(() => {
    const cable = createConsumer(cableURL)

    const subscription = cable.subscriptions.create(
      { channel: 'MessageStreamChannel', chat_id: chatId },
      {
        received(data) {
          if (data.message_type === 'chat_status') {
            setStatus(data.message_body['status'])
          } else if (data.message_type === 'chat_message_received') {
            const messageIncrement = data.message_body['response_message']
            setMessage((prevMessage) => prevMessage + messageIncrement)
          } else if (data.message_type == 'chat_tool_calls') {
            const steps: StepUnion[] = data.message_body.map((toolCall: any) => {
              const { name, description } = toolCall
              return {
                name,
                description,
              }
            })

            const fakeToolCall = {
              id: 0,
              uuid: '',
              createdAt: new Date(),
              updatedAt: new Date(),
              createdAtMilli: new Date().getTime(),
              actor: ActorEnum.System,
              step: steps,
            }

            setToolCalls((prevToolCalls) => [...prevToolCalls, fakeToolCall])
          }
        },
      }
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  return (
    <>
      {toolCalls.map((toolCall, index) => (
        <RunStep key={index} step={toolCall} />
      ))}
      {message && (
        <Message
          message={{
            message: [{ type: 'text', value: message }],
            actor: ActorEnum.System,
            createdAt: new Date(),
            uuid: '',
            createdAtMilli: new Date().getTime(),
            id: null,
            updatedAt: new Date(),
          }}
        />
      )}
      <div className="flex flex-row space-x-2 ml-12 text-gray-300">
        <ThreeDots />
        <div className="">{status}</div>
      </div>
    </>
  )
}

export default MessageStream
