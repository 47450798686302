import React from 'react'

const LeftNavigationArrowIcon = () => (
  <div className="w-[24px] h-[24px] flex justify-center items-center">
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" fill="none" viewBox="0 0 6 10">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.604.146a.5.5 0 010 .708L1.457 5l4.147 4.146a.5.5 0 11-.708.708l-4.5-4.5a.5.5 0 010-.708l4.5-4.5a.5.5 0 01.708 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  </div>
)

export default LeftNavigationArrowIcon
