import Hero from '../components/Hero'
import React from 'react'
import HowItWorks from '../components/HowItWorks'
import WalkthroughExamples from '../components/WalkthroughExamples'
import WaitlistCTA from '../components/WaitlistCTA'
import ProductDemos from '../components/ProductDemos'

const Index = () => {
  document.title = 'FlowMo | Looker Co-Pilots trained on your instance and data'
  return (
    <>
      <Hero />
      <ProductDemos />
      <HowItWorks />
      <WalkthroughExamples />
      <WaitlistCTA />
    </>
  )
}

export default Index
