import React from 'react'
import Header from './Header'
import EmbedContextProvider from './context/EmbedContext/provider'
import Content from './Content'
import * as Sentry from '@sentry/react'
import { ApolloProvider } from '@apollo/client/react'
import { openClient } from '../common/ApolloClient'
import { BrowserRouter as Router } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import {
  GET_WALKTHROUGH_QUERY,
  WalkthroughData,
  WalkthroughVars,
} from '../App/graphql/queries/walkthrough'
import '../common/sentry'
import { usePageTitle } from '../common/hooks/usePageTitle'
import Walkthrough from '../App/models/Walkthrough'

interface Props {
  rootUrl: string
  uuid: string
}

const EmbeddedApp: React.FC<Props> = ({ uuid, rootUrl }) => {
  return (
    <Sentry.ErrorBoundary>
      <ApolloProvider client={openClient}>
        <Router>
          <InnerApp uuid={uuid} rootUrl={rootUrl} />
        </Router>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  )
}

const InnerApp: React.FC<Props> = ({ uuid, rootUrl }) => {
  const { data } = useQuery<WalkthroughData, WalkthroughVars>(GET_WALKTHROUGH_QUERY, {
    variables: { uuid },
  })

  if (!data?.walkthrough) {
    return <></>
  }

  return (
    <div
      className="w-full h-screen"
      style={{
        background:
          'linear-gradient(90deg, #214A86 0%, #305B9A 25.52%, #6B6B6B 63.44%, #6B6B6B 100%)',
      }}
    >
      <Meta walkthrough={data?.walkthrough} />
      <EmbedContextProvider walkthrough={data?.walkthrough} rootUrl={rootUrl}>
        <Header />
        <Content />
      </EmbedContextProvider>
    </div>
  )
}

interface MetaProps {
  walkthrough: Walkthrough
}

const Meta: React.FC<MetaProps> = ({ walkthrough }) => {
  usePageTitle(`${walkthrough.name} | FlowMo`)
  return <React.Fragment />
}

export default EmbeddedApp
