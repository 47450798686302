import React from 'react'
import { DestinationType } from '../../../../Walkthrough/hooks/useMoveWalkthroughMutation'
import CardMenuItem from './index'

type props = {
  onMove: (variables: DestinationType) => void
}

const RemoveFromSkillSetItem: React.FC<props> = ({ onMove }) => {
  const handleRemove = () => {
    onMove({ destinationType: 'remove_from_skill_set' })
  }
  return <CardMenuItem label="Remove from Skill Set" onClick={handleRemove} />
}

export default RemoveFromSkillSetItem
