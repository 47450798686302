import React, { useCallback, useMemo, useState } from 'react'
import { LabsContext } from '.'
import Walkthrough, { StepTypes } from '../../../App/models/Walkthrough'
import { findLastIndex } from '../../../common/utils/findLastIndex'
import { useEventTracking } from '../../../common/hooks/useEventTracking'
import useNormalizedSteps from '../../../Walkthrough/hooks/useNormalizedSteps'

interface Props {
  walkthrough: Walkthrough
  children?: React.ReactNode
}

const LabsContextProvider = ({ children, walkthrough }: Props) => {
  const steps = useNormalizedSteps(walkthrough).filter((step) => step.visible)
  const initialStepIndex = useMemo(
    () => steps.findIndex((step) => step.type !== StepTypes.goto),
    [walkthrough]
  )

  const [stepIndex, setStepIndex] = useState(initialStepIndex)
  const [completed, setCompleted] = useState(false)
  const logEvent = useEventTracking()

  const nextStep = useCallback(() => {
    if (walkthrough) {
      logEvent({
        type: 'walkthrough_embed:try_it_step',
        subjectType: 'walkthrough',
        subjectId: walkthrough.id,
        metadata: { stepIndex },
      })
    }

    const nextStepIndex = steps.findIndex((step, index) => {
      return index > stepIndex && step.type !== StepTypes.goto
    })

    nextStepIndex !== -1 ? setStepIndex(nextStepIndex) : setCompleted(true)
  }, [stepIndex, completed])

  const prevStep = useCallback(() => {
    const prevStepIndex = findLastIndex(
      steps,
      (step, index) => step.type !== StepTypes.goto && index < stepIndex
    )

    prevStepIndex !== -1 && setStepIndex(prevStepIndex)
  }, [stepIndex, completed])

  const reset = useCallback(() => {
    setStepIndex(initialStepIndex)
    setCompleted(false)
  }, [initialStepIndex])

  const value = {
    walkthrough,
    nextStep,
    prevStep,
    stepIndex,
    completed,
    reset,
    steps,
  }

  return <LabsContext.Provider value={value}>{children}</LabsContext.Provider>
}

export default LabsContextProvider
