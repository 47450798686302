import React, { useEffect, useState } from 'react'
import { Player } from '@data-driven/components'
import getBlobDuration from 'get-blob-duration'
import Walkthrough from '../../../../../../App/models/Walkthrough'
import { Chunk } from '../../../../../hooks/useRRWebEvents'
import { Bar, Container } from '../../components/Containers'
import Settings from '../Settings'
import Replay from '../Replay'
import Button from '../../components/Buttons'
import { CreateAudioIcon, EditAudioIcon } from '../../icons'
import Switcher from '../../../../../../common/ui/Switcher'
import Thumbnail from '../../../../../../common/components/Player/components/Thumbnail'
import PlayerEventTracking from '../../../../../../common/components/PlayerEventTracking'

type RecordProps = {
  walkthrough: Walkthrough
  chunks: Chunk[]
}

const Record: React.FC<RecordProps> = ({ walkthrough, chunks }) => {
  const [edit, setEdit] = useState<boolean>(false)
  const [audio, setAudio] = useState<any>(undefined)
  const [wave, setWave] = useState<number[]>([])
  const [duration, setDuration] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (audio) {
      getBlobDuration(audio).then((d) => setDuration(d * 1000))
    }
  }, [audio])

  const close = () => {
    setEdit(false)
    setAudio(undefined)
    setWave([])
    setDuration(undefined)
  }

  return (
    <Container>
      <Player
        showControlPanel={!edit}
        audio={!edit ? walkthrough.audioUrl : audio && URL.createObjectURL(audio)}
        duration={walkthrough.meta.duration}
        chunks={chunks}
        thumbnail={(loading: boolean) =>
          !edit ? <Thumbnail loading={loading} walkthrough={walkthrough} /> : <React.Fragment />
        }
        rrwebScreenSize={{
          width: walkthrough?.steps[0]?.event?.data?.width,
          height: walkthrough?.steps[0]?.event?.data?.height,
        }}
        maxHeight={750}
        steps={walkthrough.steps}
      >
        <React.Fragment>
          {(() => {
            if (!edit) {
              return <PlayerEventTracking walkthrough={walkthrough} eventPrefix="walkthrough" />
            }

            if (audio) {
              return (
                <Replay
                  walkthrough={walkthrough}
                  wave={wave}
                  audioDuration={duration}
                  file={audio}
                  close={close}
                />
              )
            }

            return <Settings close={close} setAudio={setAudio} setWave={setWave} />
          })()}
        </React.Fragment>
      </Player>
      {!edit && (
        <Bar>
          {walkthrough.hasAudio ? (
            <div className="flex items-center justify-between">
              <Button type="primary" onClick={() => setEdit(true)}>
                <EditAudioIcon />
                <div>Edit audio</div>
              </Button>
              <div className="flex items-center justify-between">
                <span className="text-xs text-gray-400 mr-2 ml-2">
                  Your audio is {walkthrough.audioEnabled ? 'enabled' : 'disabled'}
                </span>
                <Switcher name="audioEnabled" />
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-between">
              <Button type="primary" onClick={() => setEdit(true)}>
                <CreateAudioIcon />
                <div>Create audio</div>
              </Button>
              <div className="flex items-center justify-between">
                <span className="text-xs text-gray-400 ml-2">Add audio to this walkthrough</span>
              </div>
            </div>
          )}
        </Bar>
      )}
    </Container>
  )
}

export default Record
