import ReactTooltip from 'react-tooltip'
import React from 'react'

interface Props {
  id: string
}

const Tooltip: React.FC<Props> = ({ id }) => (
  <ReactTooltip
    id={id}
    place="top"
    effect="solid"
    className="!shadow-md !font-inter !text-[12px] !leading-[140%] !opacity-100 !py-[3px] !px-[12px] !z-50"
    textColor="#777D87"
    backgroundColor="#FFFFFF"
  />
)

export default Tooltip
